import { Base, Component, Emit, Prop, Watch } from '@/vue-property-decorator'
import { GlobalConst } from '@/context'
import { Icon, Select } from '@/plugins/ant-design-vue/tsx-support'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { merge } from 'lodash'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import Site from '@/controller/Site'
import style from './index.module.scss'

export interface Props {
  /** 只有一个区号时是否隐藏下拉箭头 <注意宽度会有变化> */
  hideArrowOnlyOne?: boolean
  /** 是否是内联到手机输入框的样式 */
  inline?: boolean
  selectTsxData?: VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
    typeof Select.Tsx,
    unknown
  >
}

export interface Event {
  onChange: string
}
@Component<PhoneSegment>({ name: 'PhoneSegment' })
export default class PhoneSegment extends Base<unknown, Props, Event> {
  @Prop()
  private hideArrowOnlyOne!: Props['hideArrowOnlyOne']
  @Prop()
  private inline!: Props['inline']
  @Prop()
  private selectTsxData!: Props['selectTsxData']

  private loading = false
  private fetchError = false

  private get smsCountrys() {
    return useMainStore().smsCountrys
  }
  private get smsCountry() {
    return useMainStore().smsCountry
  }
  private get smsSegment() {
    return useMainStore().smsSegment
  }

  private get hideArrow() {
    if (this.smsCountrys.length === 1 && this.hideArrowOnlyOne) {
      return true
    }
    return false
  }

  private get selectValue() {
    if (this.smsCountry?.countryName && this.smsSegment) {
      return `${this.smsCountry.countryName}=${this.smsSegment}`
    }
    return undefined
  }

  @Watch('smsSegment', { immediate: true })
  @Emit('change')
  protected onChange() {
    return this.smsSegment
  }

  /** 更新手机区号列表 */
  private async initSmsCountry() {
    this.loading = true
    const error = await Site.initSmsCountry()
    this.loading = false
    this.fetchError = Boolean(error)
  }

  mounted() {
    this.initSmsCountry()
  }

  render() {
    const { t } = useI18n()

    return (
      <span
        class={[style.segment, this.hideArrow ? style.middle : style.large]}
        data-inline={this.inline}
      >
        <span
          v-show={this.fetchError}
          class={style.retry}
          onClick={this.initSmsCountry}
        >
          <icon-sprite
            class={[
              style.retryIcon,
              [
                {
                  [GlobalConst.CssClassName.Animate.Animated]: this.loading
                },
                GlobalConst.CssClassName.Animate.Spin,
                GlobalConst.CssClassName.Animate.Repeat.infinite
              ]
            ]}
            sprite={createSvgSprite('comm_icon_retry')}
          />
        </span>

        <div class={style.selectedIcon}>
          {this.smsCountry && (
            <my-img src={this.smsCountry.icon} width={28} height={21} />
          )}
        </div>
        <Select.Tsx
          show-search
          class={GlobalConst.CssClassName.Lobby.Antd.SelectSearch}
          dropdownMatchSelectWidth={false}
          dropdownClassName={style.dropdown}
          dropdownStyle={{ display: 'flex' }}
          notFoundContent={t('lobby.modal.noData')}
          optionLabelProp={'label'}
          value={this.selectValue}
          onChange={(val: string) => {
            const segment = val.split('=')[1]
            useMainStore().updateSmsSegment(segment)
          }}
          {...merge(this.selectTsxData as unknown)}
          showArrow={this.selectTsxData?.showArrow ?? !this.hideArrow}
          disabled={this.selectTsxData?.disabled ?? this.hideArrow}
        >
          {this.smsCountrys.map((country) => (
            <Select.Option.Tsx
              value={`${country.countryName}=${country.phoneNumberSegment}`}
              label={country.phoneNumberSegment}
              key={`${country.countryName}=${country.phoneNumberSegment}`}
            >
              <div class={style.segmentDropdownItem}>
                <Icon.Tsx
                  component={{
                    functional: true,
                    render() {
                      return (
                        <my-img src={country.icon} width={28} height={21} />
                      )
                    }
                  }}
                />
                <span>
                  {`${country.countryName}（${country.phoneNumberSegment}）`}
                </span>
              </div>
            </Select.Option.Tsx>
          ))}
        </Select.Tsx>
      </span>
    )
  }
}
