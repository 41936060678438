import { Base, Component, Emit, Prop } from '@/vue-property-decorator'
import { Button } from '@/plugins/ant-design-vue/tsx-support'
import { DateFormatType } from '@/context/common/date'
import { DatePicker } from '@/plugins/vant/tsx-support'
import { GlobalConst } from '@/context'
import { Input } from '@/plugins/ant-design-vue/tsx-support'
import { Picker } from 'vant'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import BusinessUtils from '@/utils/business-utils'
import moment from 'moment'
import style from './style.module.scss'
import webPickerStyle from '../date-range-picker/style.module.scss'
type PickerCombine = Picker & { getValues: () => [] }
type Props = {
  startTime?: string
  endTime?: string
  limiDays?: number
  visibleItemCount?: number
  limiSearchDays?: number
  itemHeight?: number | string
  format?: DateFormatType
  settlementCycle?: number
}

type State = {
  /**输入款被选中的开始时间 */
  selectedStartTime: string
  selectedEndTime: string
  startTime: string
  endTime: string
  /** 是否展示日期选择框 */
  isShowDateRange: boolean
  /** 计算后的最大时间 */
  computedEndMaxDate: Date
}

@Component<DateRangePickerH5>({ name: 'DateRangePickerH5' })
export default class DateRangePickerH5 extends Base<unknown, Props> {
  /*** 开始时间 */
  @Prop()
  private startTime!: Props['startTime']

  /*** 结束时间 */
  @Prop()
  private endTime!: Props['endTime']

  /*** 限制的时间天数 */
  @Prop({ required: false, default: 90 })
  private limiDays!: Props['limiDays']

  /** 可见的选项个数 */
  @Prop({ required: false, default: 5 })
  private visibleItemCount!: Props['visibleItemCount']

  /*** 选项高度 */
  @Prop({ required: false, default: BusinessUtils.px2rem(64) })
  private itemHeight!: Props['itemHeight']

  /** 可搜索的时间范围 */
  @Prop({ required: false, default: 30 })
  private limiSearchDays!: Props['limiSearchDays']

  /** 日期格式，默认是ymd */
  @Prop({ required: false, default: 'ymd' })
  private format!: Props['format']

  /**当前时间点所以站点中配置结算周期最多的为默认值 */
  @Prop({ required: false, default: 30 })
  private settlementCycle!: Props['settlementCycle']

  @Emit('pickerConfirm')
  private onPickerConfirm() {
    const { siteInfos } = useMainStore()
    const formatStartTime = moment(this.state.startTime, this.formatByLang)
      .add(this.getTimeZoneDifference, 'hour')
      .utc()
      .utcOffset(siteInfos?.timeZone ?? '')
      .startOf('day')
    const formatEndTime = moment(this.state.endTime, this.formatByLang)
      .add(this.getTimeZoneDifference, 'hour')
      .utc()
      .utcOffset(siteInfos?.timeZone ?? '')
      .endOf('day')

    this.setState({
      isShowDateRange: false
    })
    this.setState({
      selectedStartTime: formatStartTime.format(this.formatByLang),
      selectedEndTime: formatEndTime.format(this.formatByLang)
    })
    return { startTime: formatStartTime, endTime: formatEndTime }
  }

  @Emit('pickerCancel')
  private onPickerCancel() {
    this.setState({ isShowDateRange: false })
  }

  state: State = {
    startTime: '',
    endTime: '',
    selectedStartTime: '',
    selectedEndTime: '',
    isShowDateRange: false,
    computedEndMaxDate: new Date()
  }

  /** 获取开始时间 */
  private get getStartDate() {
    return this.state.startTime
  }

  /*** 获取开始的最小时间*/
  private get getStartMinDate() {
    return moment()
      .subtract((this.limiDays ?? 1) - 1, 'day')
      .toDate()
  }

  /**获取当前站点和电脑本地的时区差 */
  private get getTimeZoneDifference() {
    const localUtcOffset = moment().utcOffset() / 60
    const siteUtcOffset = this.siteutcOffset / 60
    const timeZoneDifference = localUtcOffset - siteUtcOffset
    return timeZoneDifference
  }

  /**站点时区 */
  private get siteutcOffset() {
    return moment(this.startTime).utcOffset()
  }

  /** 获取开始的最大时间*/
  private get getStartMaxDate(): Date {
    const { siteInfos } = useMainStore()
    // 需要展示的是站点时区的时间
    return moment()
      .add(-this.getTimeZoneDifference, 'hour')
      .utc()
      .utcOffset(siteInfos?.timeZone ?? '')
      .toDate()
  }

  /*** 开始时间改变时*/
  private startOnChange(picker: PickerCombine) {
    this.$nextTick(() => {
      const startTime = picker.getValues().join('-')
      // 将开始时间和结束时间重置为一致
      this.state.startTime = moment(startTime).format(this.formatByLang)
      // 选择日期往后面走this.limiSearchDays天
      const afterLimitSearch = moment(startTime).add(
        (this.limiSearchDays as number) - 1,
        'days'
      )
      const isBeforeMaxDate = afterLimitSearch.isBefore(this.getStartMaxDate)

      // 计算的日期为限制的日前和当前的日期谁在前面
      const computedEndMaxDate = isBeforeMaxDate
        ? afterLimitSearch.toDate()
        : this.getStartMaxDate
      this.setState({
        computedEndMaxDate
      })
      this.setEndTime(computedEndMaxDate)
    })
  }

  /**
   * @description 判断上一次的结束时间，处在当前的最小结束时间和最大结束时间内，两边都是包含关系 存在的话，那么结束时间不要改
   * 
   * @param computedEndMaxDate 结束时间的最大时间
   * 
   * @example
   *  比如上一次的结束时间为12-01号
      本次的开始时间的是11-02
      本次的结束时间的区间是11-02到12-01不需要动
   */
  private setEndTime(computedEndMaxDate: Date) {
    const startTime = this.state.startTime
    // 上一次的结束时间
    const previousEndTime = this.state.endTime
    // 上一次的结束时间的日期和最大的结束时间的关系是等于或在之前
    const isSameOrBeforeEnd = moment(
      previousEndTime,
      this.formatByLang
    ).isSameOrBefore(computedEndMaxDate)
    // 上一次的结束时间的日期和最小的结束时间的关系是等于或在之后
    const isSameOrAfterStart = moment(
      previousEndTime,
      this.formatByLang
    ).isSameOrAfter(moment(startTime, this.formatByLang))
    const endTime =
      isSameOrBeforeEnd && isSameOrAfterStart ? previousEndTime : startTime
    this.$nextTick(() => {
      this.state.endTime = endTime
    })
  }

  /**
   * 获取结束时间
   */
  private get getEndDate() {
    return this.state.endTime
  }

  /*** 获取结束的最小时间*/
  private get getEndMinDate() {
    return moment(this.getStartDate, this.formatByLang).toDate()
  }

  /**结算过后的结算周期 */
  private get computedSettlementCycle() {
    return Math.min(
      this.settlementCycle as number,
      this.limiSearchDays as number
    )
  }

  /**
   * 获取结束的最大时间
   */
  private get getEndMaxDate() {
    return this.state.computedEndMaxDate
  }

  /**
   * 结束时间改变后
   * @param picker
   */
  private endOnchange(picker: PickerCombine) {
    this.$nextTick(() => {
      this.state.endTime = moment(picker.getValues().join('-')).format(
        this.formatByLang
      )
    })
  }

  /**
   * 根据当前语言获取当前时间格式
   * zh_CN 为ymd为YYYY-MM-DD
   * 非中文得ymd为DD/MM/YYYY
   * */
  protected get formatByLang() {
    const { language } = useMainStore()
    const format = this.format as DateFormatType
    const fmtByLang =
      GlobalConst.DateFormatMap[language][format] ??
      GlobalConst.DateFormatMap.en_US[format]
    return fmtByLang
  }

  created() {
    const endDefaultTime = moment(moment(new Date())).format(this.formatByLang)
    const startDefaultTime = moment(moment(new Date()))
      .subtract(this.computedSettlementCycle - 1, 'days')
      .format(this.formatByLang)
    /** 开始日期 转换为YYYY-MM-DD或者DD-MM-YYYY的时间格式*/
    const startTime = this.startTime
      ? moment(this.startTime)
          .subtract(this.computedSettlementCycle - 1, 'days')
          .format(this.formatByLang)
      : startDefaultTime
    /** 结束日期 转换为YYYY-MM-DD或者DD-MM-YYYY的时间格式*/
    const endTime = this.endTime
      ? moment(this.endTime).format(this.formatByLang)
      : endDefaultTime
    this.setState({
      startTime,
      endTime,
      selectedStartTime: startTime,
      selectedEndTime: endTime,
      computedEndMaxDate: this.getStartMaxDate
    })
  }

  deactivated() {
    this.setState({
      isShowDateRange: false
    })
  }

  render() {
    const { t } = useI18n()
    return (
      <div class={style.outerDatePickerRange}>
        <div
          class={webPickerStyle.datePickerRange}
          onClick={() =>
            this.setState({
              isShowDateRange: true
            })
          }
        >
          <div class={webPickerStyle.startTime}>
            <div>
              <Input.Tsx
                placeholder={t('lobby.promote.startTime') as string}
                v-model={this.state.selectedStartTime}
                readOnly={true}
              />
            </div>
          </div>
          <div class={webPickerStyle.endTime}>
            <div>
              <Input.Tsx
                placeholder={t('lobby.promote.endTime') as string}
                v-model={this.state.selectedEndTime}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        {this.state.isShowDateRange && (
          <div class={style.wrapperDateRange}>
            <div class={style.innerWrapperDateRange}>
              <div class={style.warpperStartTime}>
                <h5 class={style.subTitle}>{t('lobby.promote.startTime')}</h5>
                <DatePicker
                  item-height={this.itemHeight}
                  visible-item-count={this.visibleItemCount}
                  minDate={this.getStartMinDate}
                  maxDate={this.getStartMaxDate}
                  onchange={this.startOnChange}
                  type="date"
                  showToolbar={false}
                  swipe-duration={100}
                  value={moment(this.getStartDate, this.formatByLang).toDate()}
                />
                <Button.Tsx
                  type="link"
                  class={[style.cancel]}
                  onClick={this.onPickerCancel}
                >
                  {t('lobby.promote.cancel')}
                </Button.Tsx>
              </div>

              <div class={style.line}></div>
              <div class={style.warpperEndTime}>
                <h5 class={style.subTitle}>{t('lobby.promote.endTime')}</h5>
                <DatePicker
                  item-height={this.itemHeight}
                  visible-item-count={this.visibleItemCount}
                  minDate={this.getEndMinDate}
                  maxDate={this.getEndMaxDate}
                  onchange={this.endOnchange}
                  type="date"
                  showToolbar={false}
                  swipe-duration={100}
                  value={moment(this.getEndDate, this.formatByLang).toDate()}
                />
                <Button.Tsx
                  type="link"
                  onClick={this.onPickerConfirm}
                  class={[style.confirm]}
                >
                  {t('lobby.promote.confirm')}
                </Button.Tsx>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}
