import {
  AddfavoritePayload,
  GameCategorieExtLink,
  GameLogout,
  GamePayload,
  GameUrlPayload,
  PlatformExchangeInfo,
  PlatformLogout,
  SelfOperatedGameUrl
} from './type'
import { AxiosRequestHeaders, AxiosResponse } from 'axios'
import { GameItemInter } from '@/service/game/entity/gameItem'
import { GameRepository } from '@/service/game/repository/game'
import { GlobalCrypto, GlobalService } from '@/context'
import { LiveInfo } from '@/views/game/business-components/live/type'
import { createOssDomainRetry } from '@/utils/business-utils/retry'
import { defaultUrlQueryParams } from '@/context/const'
import { getChannelCid } from '@/utils/Tool'
import { getCurrentDevice } from '@/utils/Device'
import { getGameOsType } from '@/views/game/utils'
import { supportShortcut } from '@/layouts/components/saveShortcut/utils'
import { useMainStore } from '@/store/index'
import CryptoJS from 'crypto-js'
const isSupportShortcut = supportShortcut()
const device = getCurrentDevice()
const isH5Pwa = device.isH5Pwa()
/**
 * 获取游戏
 * @deprecated 弃用禁止使用，后台已下架
 */
export const apiGetGameList = (payload: GamePayload) => {
  const { isDemoMode } = useMainStore()
  payload = {
    gameMode: 0,
    anchorSupport: isDemoMode ? true : undefined,
    ...payload
  }
  return GlobalService.get<{
    total: number
    gameList: Array<GameItemInter>
    current: number
  }>({
    url: '/api/lobby/recentreport/game-list',
    customParams: {
      cache: true
    },
    params: { gameIconType: defaultUrlQueryParams.gameIconType, ...payload }
  }).then(async (res) => {
    const ret = res?.data?.data || { gameList: [], total: 0, current: 1 }
    ret.gameList = ret.gameList || []
    await GameRepository.supplementPlatformInfo(ret.gameList)
    return ret
  })
}

/**
 * 获取游戏链接
 */
export const apiGetGameUrl = (
  payload: GameUrlPayload,
  headers?: AxiosRequestHeaders
) => {
  // 访问地址是带有渠道cid, 那么进入游戏的时候需要判断是否是在渠道cid中又需要下载app弹出框
  const cid = getChannelCid()
  if (cid && Number(cid)) {
    payload = { ...payload, cid: String(cid) }
  }

  if (isH5Pwa) {
    payload = {
      ...payload,
      isH5Pwa: 1
    }
  }
  // 当前浏览器环境是否支持h5桌面快捷方式
  if (isSupportShortcut) {
    payload = {
      ...payload,
      isSupportShortcut: 1
    }
  }

  payload = {
    os_type: getGameOsType(),
    ...payload
  }
  return GlobalService.post<SelfOperatedGameUrl>({
    url: '/gameapi/login',
    data: payload,
    headers
  }).then((res) => res.data.data)
}

/**
 * （取消）收藏游戏
 */
export const apiAddfavorite = (
  payload: AddfavoritePayload,
  collection: boolean
) =>
  GlobalService.post({
    url: '/home/addfavorite',
    data: {
      [collection ? 'id' : 'delid']: [
        {
          game_id: payload.gameId,
          cate_id: payload.platformId,
          category_id: payload.gameCategoryId
        }
      ]
    }
  })

/**
 * (三方接口)获取直播比赛数据
 */
export const apiGetLiveList = () => {
  return GlobalService.post<{ list: Array<LiveInfo> }>({
    url: '/active/sport/matchList'
  }).then((res) => {
    return res.data.data?.list
  })
}

/** 获取游戏类型管理自定义外部链接(只有json) */
export const apiGetGameCategorieExtLink = () =>
  GlobalService.get<Array<GameCategorieExtLink>>({
    url: '/api/game/hall/listExtLinkV2',
    customParams: {
      tryStaticMode: true,
      cache: true,
      noErrorMessage: true,
      onlyStaticMode: true,
      staticKeyOmit: ['platformType']
    }
  }).then((res) => res.data.data ?? [])

/**
 * 登出游戏下分(三方和通用也可以下分自营但是会慢点)
 * token为其它端得token，传值的话就把其它端提线
 */
export const apiGameLogout = (payload: PlatformLogout & { token?: string }) =>
  GlobalService.post<GameLogout>({
    url: '/gameapi/logout',
    data: payload
  }).then((res) => res.data.data)

/**
 * 登出游戏下分(自营)
 */
export const apiSelfOpertedGameLogout = (payload: PlatformLogout) =>
  GlobalService.post<GameLogout>({
    url: '/gameout/logoutgame',
    data: payload
  }).then((res) => res.data.data)

export const apiGetAllFavoriteList = () =>
  GlobalService.post<{ favoriteList: Array<number> }>({
    url: '/gameapi/favorite-list-all/v2'
  }).then((res) => res.data.data ?? { favoriteList: [], total: 0 })

/**
 * 获取三方平台汇率数据列表
 */
export const apiGetPlatformExchangeList = async () => {
  const response = await createOssDomainRetry<AxiosResponse>((ossHost) => {
    return GlobalService.get<unknown, unknown>({
      baseURL: ossHost,
      url: '/hall/api/third/game/platformExchange/findListAll.json',
      customParams: {
        maxRetryCount: 0,
        noResponseDataCodeChecked: true,
        noEncrypt: true,
        autoBaseUrl: false,
        setDefaultRequestConfig: (config) => {
          config.headers = {}
          return config
        }
      },
      params: {
        timestamp: Date.now()
      }
    })
  })

  const platformExchangeInfos: {
    data: {
      memberIntoThirdLimit: Record<string, number>
      platformCurrencyExchange: PlatformExchangeInfo
    }
  } = JSON.parse(
    GlobalCrypto.decrypt(response.data, 'thanks,pig4cloud', {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
  )
  return platformExchangeInfos?.data ?? {}
}

/**
 * 获取上次游戏信息(多标签登录使用)
 */
export const apiGetLastGameInfo = () =>
  GlobalService.post<{ lastGameInfo: SelfOperatedGameUrl }>({
    url: '/gameapi/lastGameInfo',
    data: {
      os_type: getGameOsType()
    }
  }).then((res) => res.data.data)
