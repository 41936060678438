/**
 * 该文件会被客户端代码引入，请注意不可有过多依赖
 */

/**
 * 任意颜色转rgb类型取三原色字串
 * #005DFE ===> 0,93,254
 * rgb(9,93,254) ===> 0,93,254
 * @param {string} value
 * @param {string} origialValue
 * @returns {string} 转换后的RGB字符串或原始值。
 */

// 正则表达式，用于匹配RGB格式的颜色值。
const rgbReg = /rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)/i

/**
 * 将RGB格式的颜色字符串转换为数组形式。
 * @param {string} rgbString RGB格式的颜色字符串。
 * @returns {string[]} RGB颜色值的数组。
 */
const rgbToArray = (rgbString) => {
  const [, r, g, b] = rgbString.match(rgbReg) || []
  return [r, g, b]
}

/**
 * 将十六进制颜色值转换为RGB格式的颜色值。
 * @param {string} hex 十六进制颜色值。
 * @returns {string} RGB格式的颜色值。
 */
const toRgb = (hex) => {
  let sColor = hex.toLowerCase()
  if (rgbReg.test(hex)) return sColor
  const reg = /^#([0-9a-f]{3}|[0-9a-f]{6})$/
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }

    const sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
    }
    return `RGB(${sColorChange.join(',')})`
  } else {
    throw new Error('Invalid HEX color format')
  }
}

/**
 * 将颜色值转换为RGB格式的三原色值字符串。
 * 如果转换失败，返回原始值。
 * @param {string} value 需要转换的颜色值。
 * @param {string} origialValue 如果转换失败，使用的原始值。
 * @returns {string} RGB格式的三原色值字符串或原始值。
 */

const toRgbString = (value, origialValue) => {
  try {
    return rgbToArray(toRgb(value)).join(',')
  } catch (error) {
    return origialValue
  }
}

/**
 * 单色值,改为带阴影的色值
 * 为阴影添加前缀,不过当前仅能添加一种颜色值,若想要添加两种色值,还需要进一步扩展
 */
// const singleColorToShadow = (value, origialValue) => {
//   try {
//     return `0 2px 4px 0 ${value || origialValue}`
//   } catch (error) {
//     return `0 2px 4px 0 ${origialValue}`
//   }
// }
/**
 * 左边为美术的key，右边为需要操作的我们项目中的key以及格式化成项目需求的方法
 */
const colorReplaceMap = {
  primary: [
    '--theme-primary-color',
    {
      key: '--theme-primay-color-hex',
      format: toRgbString
    }
  ],

  //主色
  //  通用左侧tab切换按钮的投影
  web_left_bg_shadow: [
    {
      key: '--theme-aside-no-active-box-shadow'
      // format: singleColorToShadow
    }
  ],

  border: [
    '--theme-color-line',
    {
      key: '--theme-color-line-hex', // 骨架屏使用
      format: toRgbString
    }
  ],

  //描边
  lead: [
    '--theme-text-color-darken',
    {
      key: '--theme-text-color-darken-hex', //重要
      format: toRgbString
    }
  ],

  neutral_1: '--theme-text-color', //次级
  neutral_2: '--theme-text-color-lighten', //较弱
  neutral_3: [
    '--theme-text-color-placeholder', //未输入
    '--theme-progress-line-color' // [任务/活动]进度条颜色
  ],
  bg_1: [
    '--theme-bg-color',
    {
      key: '--theme-bg-color-rgb',
      format: toRgbString
    }
  ], //背景
  bg_2: [
    '--theme-main-bg-color',
    {
      key: '--theme-main-bg-color-hex',
      format: toRgbString
    }
  ],

  //底板
  accent_1: '--theme-secondary-color-success', //辅助（绿）
  accent_2: '--theme-secondary-color-error', //辅助（红）
  accent_3: [
    '--theme-secondary-color-finance', //辅助（橙）
    {
      key: '--theme-secondary-color-finance-hex', //辅助（橙）,可用于rgba
      format: toRgbString
    }
  ],

  leftnav_def: '--theme-left-nav-text-color', //左侧栏未选中字
  leftnav_active: '--theme-left-nav-text-active-color', // 左侧栏选中字
  text_primary: [
    '--theme-primary-font-color',
    {
      key: '--theme-primary-font-color-rgb',
      format: toRgbString
    }
  ], //主色通用按钮字体
  text_accent3: '--theme-secondary-color-help-text-color', //辅助（橙）通用按钮字体
  home_bg: '--theme-home-bg', // 首页+游戏列表背景
  search_icon: '--theme-search-icon-color', //搜索icon
  label_accent3: '--theme-label-secondary-color-finance', //（橙）角标
  labeltext_accent3: '--theme-secondary-color-help-text-color', //（橙）角标字体
  kb_bg: '--theme-kb_bg', //数字键盘底色
  filter_bg: '--theme-active-bg-color', //筛选底板
  table_bg: '--theme-table-bg-color', //通用表格背景
  btmnav_def: '--theme-btm-def-color', //底部导航未选中
  web_btmnav_db: '--theme-btm-bg-color', //底部导航背景色
  icon_1: '--theme-mine-icon-color', //蓝色小图标
  filter_active: ['--theme-active-gou-color', '--theme-filter-active-color'], //筛选选中

  icon_tg_q: '--theme-aside-icon-color-0', //左侧栏icon渐变起
  icon_tg_z: '--theme-aside-icon-color-1', //左侧栏icon渐变终
  jackpot_text: '--theme-jackpot-title-color', //彩金池标题
  jdd_vip_bjc: '--theme-vip-progress-bg-color', //VIP保级差进度条

  left_bg_z: '--theme-left-active-bg-color', //左侧选中背景色
  bs_zc_bg: '--theme-side-menu-bg-color', // 版式-侧边栏底
  // bs_zc_an1: '--theme-side-menu-btn-color', //版式-侧边栏按钮
  bs_zc_an1: [
    '--theme-side-menu-btn-color',
    {
      key: '--theme-side-menu-btn-color-hex',
      format: toRgbString
    }
  ],

  web_bs_zc_an2: '--theme-side-menu-btn-hover', //侧边栏按钮悬停背景色
  web_bs_yj_bg: '--theme-side-footer-bg-color', //页脚底色
  ddt_bg: '--theme-game-card-ddt_bg', //兜底图底板背景
  ddt_icon: '--theme-game-card-ddt_icon', //兜底图图形

  bs_topnav_bg: '--theme-top-nav-bg', //版式-顶部导航底

  alt_primary: '--theme-alt-primary', //欧美白底特殊主题色
  alt_neutral_1: '--theme-alt-neutral-1', //欧美白底特殊次级
  alt_neutral_2: '--theme-alt-neutral-2', // 欧美白底特殊较弱
  alt_border: '--theme-alt-border', //欧美白底特殊描边色
  web_topbg_1: '--theme-user-card-top-bg-color', //底色渐变起点

  web_topbg_3: '--theme-user-card-bottom-bg-color', //底色渐变终点（高款）
  web_plat_line: '--theme-game-lobby-border', //平台底板外描边
  web_load_zz: '--theme-load-bg-color', //loading模块加载遮罩
  // 通用左侧tab切换按钮的投影
  // web_left_bg_shadow: '--theme-aside-no-active-box-shadow',
  web_left_bg_q: '--theme-aside-active-bg-color-0',
  web_left_bg_z: '--theme-aside-active-bg-color-1',
  profile_icon_1: '--theme-profile_icon_1',
  profile_icon_2: '--theme-profile_icon_2',
  profile_icon_3: '--theme-profile_icon_3',
  profile_icon_4: '--theme-profile_icon_4',
  web_filter_gou: '--theme-web_filter_gou',

  alt_text_primary: '--theme-alt-text-color', //顶部导航主按钮字体色

  profile_toptext: '--theme-text-person-color' //个人中心顶部内容
}

const ThemeSkinType = {
  /**
   * 亚太风
   * @type {1}
   */
  NORMAL: 1,
  /**
   * 欧美版
   * @type {2}
   */
  EUROPEAN_AMERICAN: 2,
  /**
   * 青蓝版
   * @type {3}
   */
  CYAN_BLUE: 3,
  /**
   * 欧美简约（stake）
   * @type {4}
   */
  STAKE: 4,
  /**
   * 128定制版皇冠金
   * @type {5}
   */
  CROWN_GOLD: 5,
  /**
   * 126定制版银河金
   * @type {6}
   */
  GALAXY_GOLD: 6,
  /**
   * DQ定制版
   * @type {7}
   */
  DQ: 7,
  /**
   * hk定制版
   * @type {9}
   */
  HK: 9,
  /**
   *  296定制版
   *  @type {12}
   */
  THEME_12: 12,
  /**
   * 5159定制版
   * @type {17}
   */
  THEME_17: 17,
  /**
   * 230定制版
   * @type {13}
   */
  THEME_13: 13,
  /**
   * 230-2定制版
   * @type {14}
   */
  THEME_14: 14,
  /**
   * 230-4 定制版
   * @type {36}
   */
  THEME_36: 36,
  /**
   * 5233版式
   * @type {15}
   */
  THEME_15: 15,
  /**
   * 5021定制版
   * @type {16}
   */
  THEME_16: 16,
  /**
   * 5021-2定制版
   * @type {26}
   */
  THEME_26: 26,
  /**
   * 969定制版
   * 500定制版
   * @type {27}
   */
  THEME_27: 27,
  /** 969定制版
   * @type {24}
   */
  THEME_24: 24,
  /**
   * 5198-2定制版
   * @type {18}
   */
  THEME_18: 18,
  /**
   * 5613 定制版
   * @type {19}
   */
  THEME_19: 19,
  /**
   * 961定制版
   * @type {20}
   */
  THEME_20: 20,
  /**
   * 5371定制版
   * @type {21}
   */
  THEME_21: 21,
  /**
   * 5235定制版
   * @type {33}
   */
  THEME_33: 33,
  /**
   * 5060定制版
   * @type {25}
   */
  THEME_25: 25,
  /**
   * 10010 定制版
   * @type {31}
   */
  THEME_31: 31,
  /** 5955定制版
   * @type {34}
   */
  THEME_34: 34,
  /**
   * 230-3定制版
   * @type {35}
   */
  THEME_35: 35,
  /**
   * 678 定制版
   * @type {28}
   */
  THEME_28: 28,
  /**
   * 663 定制版
   * @type {10}
   */
  THEME_10: 10,
  /**
   * 678体育 定制版
   * @type {29}
   */
  THEME_29: 29,
  /**
   * 6008 定制版
   * @type {39}
   */
  THEME_39: 39,
  /*
   * 5955定制版
   * @type {34}
   */
  THEME_34: 34,
  /*
   * 6261定制版
   * @type {37}
   */
  THEME_37: 37,
  /**
   * 6296 定制版
   * @type {41}
   */
  THEME_41: 41
}
/** 皮肤是否有底常量 */ const ThemeSkinBg = { HAS_BG: 1, NO_BG: 0 }
const getSkinName = (siteInfos) => {
  if (siteInfos?.type) {
    return `${siteInfos?.type}-${siteInfos?.backgroundColor}-${siteInfos?.skinTypeValue}`
  }
  // 蓝白皮肤兜底
  return '1-0-8'
}

/**
 * 批量配置侧边栏宽度，新增皆需配置，否则会使用默认 190
 */
const layoutBaseThemeVar = {
  [ThemeSkinType.CYAN_BLUE]: {
    '--theme-header-height': '95px'
  },

  [ThemeSkinType.STAKE]: {
    '--theme-header-height': '60px'
  },

  [ThemeSkinType.THEME_13]: {
    '--theme-header-height': '125px'
  },

  [ThemeSkinType.THEME_14]: {
    '--theme-header-height': '120px'
  },

  [ThemeSkinType.THEME_12]: {
    '--theme-header-height': '202px'
  },

  [ThemeSkinType.THEME_15]: {
    '--theme-header-height': '105px'
  }
}

/**
 * 根据皮肤名称和选项获取CSS变量，可处理像素到rem的转换，并合并服务器端颜色配置。
 * @param {string} skinName 皮肤名称。
 * @param {Object} options 配置选项对象。
 * @param {Object} options.serverColor 服务器端颜色配置，默认为空对象。
 * @param {Object} options.themeVar 主题变量配置，包含基础主题变量和主题变量配置。
 * @returns {Object} 返回处理后的CSS变量对象。
 */
const getCssVar = (skinName, options = {}) => {
  // 解构选项参数
  const { serverColor = {}, themeVar = {} } = options
  const { baseThemeVar, ThemeVarConfig } = themeVar

  /**
   * 将像素单位的字符串转换为rem单位的字符串。
   * @param {string} m 匹配到的字符串，例如 '12px'。
   * @param {string} $1 匹配到的像素值，例如 '12'。
   * @returns {string} 转换后的rem单位字符串。
   */
  const px2rem = (m, $1) => {
    if (!$1) return m
    const options = {
      rootValue: 100,
      unitPrecision: 5
    }

    /**
     * 将数字四舍五入到指定的精度。
     * @param {number} number 需要四舍五入的数字。
     * @param {number} precision 精确到小数点后几位。
     * @returns {number} 四舍五入后的数字。
     */
    const toFixed = (number, precision) => {
      const multiplier = Math.pow(10, precision + 1),
        wholeNumber = Math.floor(number * multiplier)
      return (Math.round(wholeNumber / 10) * 10) / multiplier
    }
    const pixels = parseFloat($1)
    const fixedVal = toFixed(pixels / options.rootValue, options.unitPrecision)
    return fixedVal === 0 ? '0' : fixedVal + 'rem'
  }
  // 将布局名称从皮肤名称中提取出来
  const [layoutName] = skinName.split('-') || []
  const layoutThemeVar = layoutBaseThemeVar[layoutName] || {}

  // 使用JSON序列化和正则替换来转换像素值到rem
  const origialColor = JSON.parse(
    JSON.stringify(
      ThemeVarConfig[skinName]
        ? {
            ...baseThemeVar,
            ...layoutThemeVar,
            ...ThemeVarConfig[skinName]
          }
        : {
            ...baseThemeVar,
            ...layoutThemeVar
          }
    ).replace(/(\d*\.?\d+)px/g, px2rem)
  )

  /**
   * 使用服务器端颜色配置覆盖原始颜色配置。
   *
   * 对于每个服务器端颜色配置项，查找对应的颜色替换映射。如果映射存在，则根据映射规则
   * 对颜色值进行格式化，并更新原始颜色配置中对应的颜色值。如果某个颜色键不存在或者值无效，
   * 则不进行替换。
   */
  return Object.assign(
    origialColor,
    Object.entries(serverColor).reduce((pre, [key, value]) => {
      // 查找颜色替换映射
      const config = colorReplaceMap[key]
      // 如果映射不存在或者值为null，则使用默认映射
      const list = (Array.isArray(config) ? config : [config]) || []
      // 遍历映射列表
      list.forEach((item) => {
        // 如果映射项是字符串，则将其视为键；如果是对象，则解构以获取键和格式化函数
        const { key, format } = Object.assign(
          {
            key: '',
            format: (value) => value
          },

          typeof item === 'string' ? { key: item } : item
        )

        // 获取原始颜色值
        const origialValue = origialColor[key]
        // 如果原始颜色值存在，并且服务器提供了有效值，则尝试应用格式化函数
        if (typeof origialValue !== 'undefined' && value) {
          try {
            // 应用格式化函数并更新累加器
            pre[key] = format(value, origialValue)
          } catch (error) {}
        }
      })
      return pre
    }, {})
  )
}

module.exports = {
  ThemeSkinType,
  getSkinName,
  getCssVar,
  ThemeSkinBg,
  toRgbString,
  dataMap: {
    allCurrency: {
      ca: 'currencyAisle',
      cc: 'currencyCode',
      cd: 'currencyDisplay',
      cn: 'currencyName',
      cs: 'currencySign',
      ct: 'currencyType',
      fn: 'fullName',
      gr: 'gameRate',
      id: 'id',
      mcc: 'marketCurrencyCode'
    }
  }
}
