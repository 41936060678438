import { CategoryIds, CommonSwitchStatus } from '@/api/common/type'
import { ChopOneKnifeMySubInfo } from '@/api/event/cutOnce/type'
import { NewSubTaskData } from './newCutOnce/type'
import { OpenOptions } from '@/views/global-modal/modal/receive-animation'
import { RechargeFundSettingResponse } from '../rechargeFund/type'
import { RedPackTypeEnum } from '@/views/event/store/type'
import { StatusType } from '@/views/promote/components/tabList/external-use-type'
import { TranslateResult } from 'vue-i18n'

/**
 * 活动大类
 */
export enum ActivityTemplate {
  /**
   * 自定义
   */
  Customize = 1,
  /**
   * 幸运转盘
   */
  LuckyWheel = 2,
  /**
   * 签到
   */
  DailyBonus = 3,
  /**
   * 救援金
   */
  Rescue = 5,
  /**
   * 红包
   */
  RedPack = 7,
  /**
   * 充值
   */
  Deposit = 10,
  /**
   * 打码
   */
  Wager = 12,
  /**
   * 推广 活动
   */
  Promote = 15,
  /**
   * 投资
   */
  Invest = 16,
  /**
   * 代理
   */
  Proxy = 17,
  /**
   * 集字
   */
  Collection = 18,
  /**
   * 幸运转盘
   */
  LuckyWheelNew = 20,
  /**
   * 世界杯竞猜活动
   */
  GuessingSports = 21,
  /**
   * 幸运注单活动
   */
  LuckyBet = 14,
  /** 排行榜 */
  Ranking = 24,
  /**
   * 优惠码（彩金，新人彩金）
   */
  DiscountCode = 22,
  /**
   * 抽奖助力
   */
  CutOnce = 23,
  /**
   * 新版砍一刀
   */
  NewCutOnce = 25
}

/**
 * 待领取状态
 */
export enum ReceiveStatusEnum {
  /** 进行中（未完成）*/
  Processing = 0,
  /** 可领取（待领取）*/
  CanReceive = 1,
  /** 已领取 */
  HasReceive = 2,
  /** 己过期 */
  Expired = 3,
  /** 已派发 */
  Distributed = 4,
  /** 已取消（后台取消）*/
  Cancel = 5,
  /** 待审批（审批中）*/
  PendingCheck = 6,
  /** 待申请 */
  PendingApply = 7,
  /** 已拒绝（后台拒绝） */
  Rejected = 8
}

/**
 *  奖金方式0固定，0的时候属性丢失 1比例 2区间随机
 */
export enum EventSignTypeEnum {
  Regular,
  Proportion,
  Interregional
}

/**
 * 是否显示一键领取 0 不显示，1=显示
 */
export enum ReceiveShowOneClickEnum {
  Hide = 0,
  Show = 1
}

/**
 * 周期循环方式(0-单次循环 1-每日循环 2-每周循环 )
 * 代理活动有用
 * 周期循环方式(0单次循环月循环 1每日循环 2每周循环 )
 * 待领取页面 一键领取开关显示 0-开启 1-关闭
 */
export enum ReceiveAwardEnum {
  Hide = 1,
  Show = 0
}

/**
 * 周期循环方式(0单次循环 1每日循环 2每周循环 )
 */
export enum CycleTypeEnum {
  Signal = 0,
  Daily = 1,
  Weekly = 2
}

/**
 * 周期循环方式(1-每日循环 2-每周循环 3-每月循环 )
 * [注意] 集字活动/代理/充值/打码 使用
 */
export enum CycleTypeEnumNew {
  Daily = 1,
  Weekly = 2,
  Monthly = 3
}

/*
 * 充值活动专用 0累计 1单笔 2首存
 */
export enum DepositRuleTypeEnum {
  /**
   * 累计
   */
  Accumulative,
  /**
   * 单笔
   */
  Single,
  /**
   * 首存
   */
  FirstDeposit
}

/**
 * 转盘开关 0=白银,1=黄金,2=砖石
 */
export enum RotaryType {
  SILVER = 0,
  GOLD = 1,
  DIAMOND = 2
}

/**
 * 更多参与限制 【12,2,19,4,10002,10000,10001,25,21】
 */
export enum TaskConditionTypeEnum {
  'bankBinding' = '2', // 完成银行卡绑定
  'birthdaySettings' = '4', // 完成生日设置
  'SMSVerification' = '12', // 完成短信验证
  'emailBinding' = '19', // 完成邮箱绑定
  'BindDigitalCurrency' = '21', // 绑定数字货币
  'BindPaymentMethod' = '25', // 绑定收款方式
  'Verified' = '10000', // 实名认证
  'IPRestrictions' = '10001', // IP限制
  'deviceRestrictions' = '10002' // 设备限制
}

export interface ApiEventItemRaw {
  /** 活动分类的id列表,以','分隔 */
  categories: string
  activeId: number
  activeName: string
  template: ActivityTemplate
  clientOpType: number
  startShowTime: number
  endShowTime: number
  startTime: number
  endTime: number
  isShowTime: number
  /** 活动小红点数 */
  activeCount: number
  /** 活动金额数 */
  activeAmount: number
  cycleType: CycleTypeEnum
  receiveDeviceType: string // OsType 值 逗号相连的字符串
  imgId: string
  timeLimit?: {
    timing: number
    duration: number
    week: number
  }
  translateNames: string //	名称翻译配置
  translateIntroduction: string //介绍翻译配置
  translateRuleText: string //规则翻译配置
  isDefaultLangName: 0 | 1 // 是否是默认语言 1是0否
  isDefaultLangIntroduction: 0 | 1 // 是否是默认语言 1是0否
  isDefaultLangRuleText: 0 | 1 // 是否是默认语言 1是0否
  status: number //0未开始 1进行中 2已结束
  redPackType?: RedPackTypeEnum // 红包类型 1开红包 2抢红包 3送红包
  categoryId?: CategoryIds
  clientShowDeviceType: string // 展示活动界面的设备类型 1:iOS ,2:Android ,3:H5  4: PC
  isClientShow: number // 客户端是否显示 1=关闭 2=开启
}

/**
 * 活动状态
 */
export enum ApiEventItemStatus {
  UnStart = 0, // 未开始
  Normal = 1, // 进行中
  End = 2 // 已结束
}
/**
 * 宣传图风格
 */
export enum PromotionStyleEnum {
  Left = 1,
  Right = 2,
  Center = 3,
  Customer = 4
}

export interface ApiEventItemImgIdParsed {
  active_introduction: string // 活动介绍
  promotional_category: string // 游戏类型
  promotional_color: string // 宣传图颜色
  promotional_icon: string // 宣传图图标
  promotional_picture: string // 宣传图图片地址
  promotional_style: PromotionStyleEnum // 宣传图样式 1,2,3,4(自定义)
}
/** 活动列表的接口返回值
 * 接口URL: /active/category
 */
export type EventCategoriesResData = {
  /** 活动分类 */
  categoryList: ApiEventCategory[]
  /** 活动列表数据 */
  activeList: ApiEventItem[]
}
export interface ApiEventItem extends ApiEventItemRaw {
  imgIdParsed: ApiEventItemImgIdParsed
}

export interface ApiEventCategoryRaw {
  categoryId: CategoryIds
  name: string
  sortNumber: number
  activeCount: number
}

export enum CategoryTypeEnum {
  /**
   * 1系统默认
   */
  DEFAULT = 1,
  /**
   * 2自定义
   */
  CUSTOMIZE = 2
}

export interface ApiEventCategory {
  categoryId: CategoryIds
  categoryType?: CategoryTypeEnum
  /**
   * 1展示图标 0不展示
   */
  showIcon?: number
  /**
   * 选中图标 系统类型目前为空
   */
  checkedIcon?: string
  /**
   * 未选中图标 系统类型目前为空
   */
  unCheckedIcon?: string
  name: string
  sortNumber: number
  /** 分类下活动小红点数 */
  activeCount: number
  /** 分类下活动金额 */
  activeAmount: number
}

export interface ApiEventGetList {
  amount?: number
  progress?: string
  reward?: number // 奖金方式0的时候是数字，1的时候string,分割最小和最大值
  nowAmount?: number
  receiveStatus?: ReceiveStatusEnum // 领取状态 0 为进行中，1为可领取，2为已领取
  receiveId?: number
  subactiveid?: string
  competedTime?: number // 完成时间
  canReceiveTime?: number // 可领取时间
  text?: string
  ratio?: number
  status?: number
  signType?: EventSignTypeEnum // 奖金方式0固定，0的时候属性丢失 1比例 2区间随机
}

/**
 * 表格row格式
 */
export interface InvestTableRow {
  day_reward: string
  day_reward_rate: string
  id: number
  investment_amount: string
  investment_amount_end: string
  investment_amount_start: string
  reward: string
  reward_rate: string
  total_reward: number
  total_reward_rate: number
}

/**
 * 是否已经签到 0未签到，1为已签到 2已派发
 */
export enum SignStatusEnum {
  UnSignIn = 0,
  SignIn = 1,
  Distributed = 2
}

export enum InvestType {
  /**
   * 固定金额
   */
  STABLE = 0,
  /**
   * 比率
   */
  RATE = 1
}

export enum ProxyType {
  /**
   * 固定金额
   */
  STABLE = 0,
  /**
   * 比率
   */
  RATE = 1,
  /**
   * 累计日结
   */
  DAYSETTLEMENT = 2
}

/**
 * 0 按日 1 周 2月
 */
export enum ProxyReturnEnum {
  /**
   * 按日
   */
  Day = 0,
  /**
   * 周
   */
  Week = 1,
  /**
   * 月
   */
  Month = 2
}

/**
 * 活动领取状态
 * 0:未完成 1:可以领取 2:已经领取 3:已过期 4:已派发
 * 5:后台取消 6:待审批（申请） 7:待申请 8:拒绝
 */
export enum ActiveStatusEnum {
  Undone = 0,
  CanReceive = 1,
  AlreadyReceived = 2,
  Expired = 3,
  Distributed = 4,
  CancelBackground = 5,
  PendingApproval = 6,
  PendingApply = 7,
  Reject = 8
}

/*
 * 统计方式 0佣金 1充值
 */
export enum StaticTypeEnum {
  /**
   * 佣金
   */
  Commission,
  /**
   * 充值
   */
  Recharge
}

export interface InvestActiveData {
  activeId: number
  canInvestment: boolean // 是否可以投资 作废 用investmentId 或者 investmentAmount胎体
  canProfit: boolean // 今天是否已经领取 用status状态代替
  currentProfit: number
  hasProfilt: number // 是否可以领取 后面将作废，用status状态代替
  investmentAmount: number
  investmentId: number // 投资规则id 为0时可以投资
  profitCount: number
  ruleId: number
  totalProfit: number
  status: number // 当前的领取状态 => ActiveStatusEnum
  ruleText: {
    /**
     *  0固定金额 1按比例赠送
     */
    investment_type: InvestType
    reward_days: string
    rule_list: Array<InvestTableRow>
  }
  haveIntegral: number
}

export interface TurntableData {
  icon: string
  index: number
  prize: number
}

export interface TurntableList {
  need_integral: 10 // 需要幸运值
  rotary_type: RotaryType // 转盘类型
  list: Array<TurntableData>
}

export enum EventStatusType {
  /** 草稿 */
  DRAFT = 0,
  /** 进行中 */
  PROCESSING = 1,
  /** 待生效 */
  PENDING = 1,
  /** 已结束 */
  OVER,
  /** 已关闭 */
  CLOSED,
  /** 已过期 */
  EXPIRED
}

/**
 * 是否展示时间 1展示 2否
 */
export enum EventShowTimeEnum {
  Show = 1,
  Hide = 2
}

/**
 * 领取方式
 */
export enum EventGiveTypeEnum {
  SelfCollectionDelivery = 2, // 玩家自领（到期派送
  SelfCollectionExpired = 3, // 玩家自领（到期作废）
  AutoDispatch = 4, // 自动派发（立即）
  ManualDistribution = 5 // 人工派发
}

/**
 * 充值活动 领取方式
 * 0:立即领取 | 1:次日领取 | 2:每周领取 | 3:每月领取 | 4:下周 | 5:下月 | 6:每日领取
 */
export enum ReceiveTimeTypeEnum {
  Now = 0,
  NextDay = 1,
  EveryWeek = 2,
  Monthly = 3,
  NextWeek = 4,
  NextMonth = 5,
  EveryDaily = 6
}

export interface ApiEventGet {
  id: number
  receiveId: number // 领取id
  name: string
  imagePath: unknown
  template: ActivityTemplate
  multiple: number
  startTime: number
  endTime: number
  /** 应该展示的时间段的截至时刻 */
  endShowTime: number
  timeLimit?: {
    timing: number
    duration: number
    week: number
  }
  timingList?: {
    timing: number
    duration: number
  }[]
  list?: ApiEventGetList[]
  nowBet?: number
  ruleType?: number
  prize?: number
  content: string
  cycleType: CycleTypeEnumNew
  receiveStatus: ReceiveStatusEnum
  showOneClick: ReceiveShowOneClickEnum
  status: EventStatusType | ReceiveStatusEnum
  isShowTime: EventShowTimeEnum
  isShowDetailTime: EventShowTimeEnum
  activeData?:
    | ApiEventGetGuessingSports
    | ApiEventGetCollectWords
    | InvestActiveData
    | ApiEventPromote
    | ApiEventProxy
    | ApiEventCustomize
    | ApiEventLuckyIntegral
    | ApiEventSign
    | ApiEventLuckyBet
    | ApiEvent
    | ApiEventListedRewards
    | ApiEventCutOnce
    | ApiEventRankingData
    | ApiEventRescue
    | ApiEventCutOnceNew
  receiveDeviceType: string
  // isDefaultLang: 0 | 1 // 是否是默认语言 1是0否
  haveIntegral?: number // 幸运值
  turntableList?: Array<TurntableList>
  translateNames: string //	名称翻译配置语言类型
  translateIntroduction: string //介绍翻译配置语言类型
  translateRuleText: string //规则翻译配置语言类型
  isDefaultLangName: 0 | 1 // 是否是默认语言 1是0否
  isDefaultLangIntroduction: 0 | 1 // 是否是默认语言 1是0否
  isDefaultLangRuleText: 0 | 1 // 是否是默认语言 1是0否
  activeCondition: string // 活动条件
  conditionText: string // 指定条件
  redPackType?: RedPackTypeEnum // 红包类型 1开红包 2抢红包 3送红包
  redContent?: string // 红包配置信息
  giveType: number // 领取方式	2=玩家自领（到期派送），3=玩家自领（到期作废），4自动派发（立即）,5人工派发
  userLevel?: string // 会员层级
  taskCondition: string // 任务显示
}

/** 抽奖助力活动详情 */
export interface ApiEventCutOnce {
  /** 重置周期 0:月，1：周，2：天 4: 一次性 */
  cycle_type: CutOnceCycleTypeEnum
  /** 总赠送奖金 不等于0 的12位+2位小数 */
  award: number
  /** 奖项组合 */
  award_portfolios: AwardPortfolioItem[]
  /** 是否展示超级大奖 0:不展示， 1:展示 */
  is_show_super_award: IsShowSuperAwardEnum
  /** IsShowSuperAward=1 时候 具体展示的  大奖金额数值 */
  super_award_value: number
  /** IsShowSuperAward=1 时候 具体展示的  大奖图标 */
  super_award_icon?: string
  /** 是否展示无奖项 0:不展示， 1:展示 */
  is_show_null_award: number
  /** IsShowNullAWard=1 时候 具体展示的  无奖图标 */
  null_a_ward_icon: string
  /** 是否展示总奖励 0:不展示， 1:展示 */
  is_show_total_award: CutOnceAwardDisplayTypeEnum
  /** 当前转盘样式(默认6中样式) */
  display_type: number
  /** 登录前弹窗方式 constant.PopType */
  not_login_pop_type: number
  /** 登录后弹窗方式 constant.PopType */
  on_login_pop_type: number
  /** 当前抽奖次数 */
  current_lottery_count: number
  /** 砍一刀活动抽奖记录id 玩家没参与过抽奖,此处=0 */
  chop_one_knife_id: number
  /** 只有再 ChopOneKnifeId!=0 时次序号表示当前第N组奖项组合序号 */
  award_portfolio_index: number
  /** 活动周期结束时间， 剩余秒数 */
  period_time: number
  /** 服务器当前时间 */
  time: number
  /**  */
  SubTaskData?: ChopOneKnifeMySubInfo | NewSubTaskData
  /** 当前无奖项图标 */
  null_award_icon?: string
  /**  */
  receiveTimeDay: number
  /**  */
  receiveTimeStart: number
  /**  */
  receiveTimeEnd: number
  /**  */
  receiveTimeType: number
  /**  */
  canReceiveTime: number
}

/** 新砍一刀 */
export interface ApiEventCutOnceNew extends ApiEventCutOnce {
  /** 展示金额最小值 */
  showMinAWard: number
  /** 展示金额最大值 */
  showMaxAWard: number
  /** 下次抽奖条件 有效推广数量 */
  nextTaskPromotionCount: number
  /** 下次抽奖条件 充值金额 */
  nextTaskRechargeCount: number
  /** 是否展示总奖励奖励区间 0:不展示， 1:展示 */
  isShowTotalAWard: 0 | 1
  /** 登录前弹窗方式 constant.PopType */
  beforeLoginPopType: number
  /** 登录后弹窗方式 constant.PopType */
  afterLoginPopType: number
  /** 总获得抽奖次数 */
  total_lottery_count: number
  /** 当前抽奖次数 */
  current_lottery_count: number
  /** 抽奖助力活动抽奖记录id 玩家没参与过抽奖,此处=0 */
  chop_one_knife_id: number
  /** 活动周期结束时间， 剩余秒数 */
  period_time: number
  /** 服务器当前时间，  (客户端说结构体外边的 time 不好获取,在此处增加重复字段，表示服务器当前时间) */
  time: number
  /** 新砍一刀奖励组合 */
  turnTableItems: TurnTableItems[]
}

/** 新砍一刀奖励组合 */
export interface TurnTableItems {
  /** 当前第N组奖项组合序号 */
  index: number
  /** 奖励类型 constant.EAwardType */
  awardType: AwardTypeEnum
  /** 奖励金额 */
  awardValue: number
  /** 图标图片 */
  awardIcon: string
}

export enum AwardTypeEnum {
  /** 固定：可设置一个固定金额，客户端显示具体金额，即转到该奖项时，获得该金额 */
  EAwardTypeConst,
  /** 随机：不可设置金额，系统随机一个值，客户端显示 “?” ，即转到该奖项时，获得一个随机金额 */
  EAwardTypeRand,
  /** 未中奖：不可设置金额，客户端显示未中奖，即转到该奖项时，不新增奖励 (1~1000) */
  EAwardTypeUnGet
}

export interface AwardPortfolioItemType {
  null_award_value?: string // 当前无奖项金额
  nullAward?: boolean
  isSecond?: boolean
  icon?: string
  super_award_icon?: string
  null_award_icon?: string
  index?: number // 当前第N组奖项组合序号
  award_rate?: number // 中将概率(1~9999 此数值会/100 来计算百分比)
  first_award_value?: number // 首次中奖金额 可设置第一次转盘抽奖的金额，需小于总赠送奖金金额
  first_award_icon?: string // 首次中奖图标
  second_award_value?: number // 第二次中奖金额 不可编辑，等于总赠送奖金金额-首次抽奖金额
  second_award_icon?: string // 第二次中奖图标
  second_award_condition_type?: number // 第二次奖金领取条件类型 ConditionType
  second_award_count?: number // 第二次奖金领取条件 (可能是：人数，次数，数值量) 达成数量
  second_current_count?: number // 第二次奖金领取条件 当前达成数量(作为结果返回给客户端展示使用)
  super_award_value?: number | string // 超级大奖金额
}

/** 砍一刀活动 总奖励展示方式 */
export enum CutOnceAwardDisplayTypeEnum {
  Hide = 0, // 隐藏
  Show = 1 // 显示
}

/** 砍一刀活动 是否展示超级大奖 */
export enum IsShowSuperAwardEnum {
  Hide = 0, // 不展示
  Show = 1 // 展示
}

/** 砍一刀活动 重置周期 */
export enum CutOnceCycleTypeEnum {
  /** 月 */
  Month = 0,
  /** 周 */
  Week = 1,
  /** 天 */
  Day = 2,
  /** 一次性 */
  Once = 4
}

export interface AwardPortfolioItem {
  index: number // 当前第N组奖项组合序号
  award_rate: number // 中将概率(1~9999 此数值会/100 来计算百分比)
  first_award_value: number // 首次中奖金额 可设置第一次转盘抽奖的金额，需小于总赠送奖金金额
  first_award_icon: string // 首次中奖图标
  second_award_value: number // 第二次中奖金额 不可编辑，等于总赠送奖金金额-首次抽奖金额
  second_award_icon: string // 第二次中奖图标
  second_award_condition_type: number // 第二次奖金领取条件类型 ConditionType
  second_award_count: number // 第二次奖金领取条件 (可能是：人数，次数，数值量) 达成数量
  second_current_count: number // 第二次奖金领取条件 当前达成数量(作为结果返回给客户端展示使用)
  null_award_value?: string // 空奖金额
  super_award_value?: number // 超级大奖金额
  isSecond?: boolean // 是否是第二次中奖金额
  nullAward?: boolean // 是否是空奖
}
export interface ApiEventIntegral {
  availableIntegral: number // 幸运值
  activeId: number // 活动id
}

export interface ApiDepositEventGet extends ApiEventGet {
  ruleType?: DepositRuleTypeEnum
  receiveTimeType: ReceiveTimeTypeEnum
  receiveTimeDay: number
  receiveDuration: number
}

/**
 * 1=开红包-随机金额
 * 2=开红包-昨日亏损比例
 * 3=开红包-昨日亏损金额
 * 4=开红包-昨日有效投注
 * 5=抢红包-固定金额
 * 6=抢红包-随机金额
 * 7=抢红包-自定义金额
 * 8=抢红包-自定义区间
 */
export enum RedPackContentRuleType {
  /** 开红包-随机金额 */
  OpenRedRandomAmount = 1,
  /** 开红包-昨日亏损比例*/
  OpenRedLossRatio = 2,
  /** 开红包-昨日亏损金额 */
  OpenRedLossAmount = 3,
  /** 开红包-昨日有效投注 */
  OpenRedValidBet = 4,
  /**  抢红包-固定金额 */
  GrabRedFixedAmount = 5,
  /** 抢红包-随机金额 */
  GrabRedRandomAmount = 6,
  /** 抢红包-自定义金额 */
  GrabRedCustomAmount = 7,
  /** 抢红包-自定义区间 */
  GrabRedCustomizedRange = 8,
  /** 送红包-当日累计充值 */
  SendTodayRecharge = 19,
  /** 送红包-当日累计打码 */
  SendTodayDaMa = 20,
  /** 送红包-昨日单笔充值 */
  SendTodayMaxRecharge = 21,
  /** 送红包-昨日单笔打码 */
  SendTodayMaxDaMa = 22,
  /** 送红包-昨日累计充值 */
  SendYesterdayRecharge = 23,
  /** 送红包-昨日累计打码 */
  SendYesterdayMaxDaMa = 24,
  /** 送红包-昨日累计亏损 */
  SendYesterdayMaxRecharge = 25
}

/**
 * 领取条件
 * 1=无条件
 * 2=当日充值
 * 3=当日打码
 * 4=当日单笔充值
 * 5=当日单笔打码
 */
export enum RedPackContentReceiveRule {
  /**
   * 无条件
   */
  Unconditional = 1,
  /**
   * 当日充值
   */
  RechargeOnDay = 2,
  /**
   * 当日打码
   */
  CodeOnDay = 3,
  /**
   * 当日单笔充值
   */
  SingleRechargeOnDday = 4,
  /**
   * 当日单笔打码
   */
  SingleCodeOnDay = 5
}

/**
 * 限抢次数
 * 0=固定 1=当日充值 2=当日打码
 */
export enum LimitTypeEnum {
  /**
   * 固定
   */
  Fixed,
  /**
   * 当日充值
   */
  RechargeOnDay,
  /**
   * 当日打码
   */
  CodeOnDay
}

/**
 * 活动通用状态
 * 0未完成  1可领取 2已领取 3已过期 4已派发
 * 5后台取消 6待审批（申请） 7待申请 8后台拒绝
 */
export enum EventStatusEnum {
  /** 未完成 */
  NotCompleted = 0,
  /** 可领取 */
  CanReceive = 1,
  /** 已领取 */
  Received = 2,
  /** 已过期 */
  Expired = 3,
  /** 已派发 */
  Distributed = 4,
  /** 后台取消 */
  BackgroundCancellation = 5,
  /** 待审批 */
  PendingApproval = 6,
  /** 待申请 */
  PendingApplication = 7,
  /** 后台拒绝 */
  BackgroundRejection = 8
}

export interface RedPackContentLimitListItem {
  /**
   * 限制 充值/打码
   */
  limit_num: number
  /**
   * 领取上限
   */
  limit_value: string
}
export interface SendRuleListItem {
  ruleId: number
  /**'
   * 充值/投注条件
   */
  ruleValue: string
  minAmount: string
  maxAmount: string
  /**
   * 展示的 奖励红包 区间下限
   */
  minShowAmount: string
  /**
   * 展示的 奖励红包 区间上限
   */
  maxShowAmount: string
}

export interface RedPackContent {
  red_pack_type: RedPackTypeEnum
  rule_type: RedPackContentRuleType
  red_pack_amount: string
  show_red_pack_amount: string
  timing: number
  duration: number
  total_red_pack: number // 红包总数
  total_limit_num: number // 限抢总次数（整个活动期间内）-抢红包
  limit_num: number // 限抢次数（单个时间段）-抢红包
  total_amount: number
  is_validate: number
  /**
   * 领取条件 1=无条件 2=当日充值 3=当日打码 4=当日单笔充值 5=当日单笔打码
   */
  receive_rule: RedPackContentReceiveRule
  limit_type: LimitTypeEnum
  limit_list: RedPackContentLimitListItem[]
  sendRuleList: SendRuleListItem[]
  todayCount: number // 当天已抢红包
  totalBet: number // 当前投注
  totalPay: number // 当前充值
  receive_limit: number // 领取条件 限制打码或者充值的数值
  unused_total_num: number // 还有多少次
}

export interface ApiEventReceiveReward {
  userGameGold: number
}

export interface Record {
  nickname: string
  rotaryType: RotaryType
  prize: number
  dateTime: number
}

/** HK 首页推广活动 */
export interface HKPromoteActiveData {
  /** 活动ID */
  id: number
  /** 活动名称 */
  name: string
  /** 待领取ID */
  receiveId: number
  /** 可领取奖励 可能含有,分割 */
  reward: string
  /** 已领取总金额 */
  amount: number
  /** 字符串和 reward一样的格式，diffCount>0 该属性才有效 */
  nexReward: string
  /** 该推广的最高等级的金额 */
  maxRuleReward: string
  /** 可领取最高金额 */
  maxTotalAmount: number
  /** 已邀请总人数 */
  promoteCount: number
  /** 距离下一档人数 */
  diffCount: number
  /** 推广详情规则数据 */
  rule_list?: ApiEventPromoteRule[]
  /** 下级总人数 */
  childrenCount?: number
}

export interface TurntableTask {
  id: number
  taskDesc: string
  luckyIntegral: number
}

export interface TurntableTaskList {
  taskCategory: number
  data: TurntableTask[]
}

export interface CustomActivityListItem {
  id: number //	活动ID
  activeName: string // 活动名称
  startTime: number //活动开始时间
  endTime: number //活动结束时间
  template: number //	活动类型
  imgId: ApiEventItemImgIdParsed
  /** 用户层级字符串,逗号分隔 */
  userLevel: string
}

export interface LotteryResultReceiveData {
  haveIntegral: number // 幸运值
  activeId: number
  rotaryType: RotaryType
  prize: number
  index: number
}

export interface LotteryResult {
  activeRes: {
    receiveData: {
      haveIntegral: number // 幸运值
      activeId: number
      rotaryType: RotaryType
      prize: number
      index: number
    }
  }
}

/**
 * 竞猜活动
 */
/**
 * 参与条件 0=无条件 1=累计充值 2=累计打码
 */
export enum RuleTypeEnum {
  UNCONDITIONAL = 0,
  CUMULATIVERECHARGE = 1,
  CUMULATIVECODING = 2
}

/**
 * 开启每笔充值奖励 0=关闭 1=开启
 */
export enum RechargeEachOrderEnum {
  CLOSED = 0,
  OPEN = 1
}

/**
 * 开奖状态 0=未开奖 1=已开奖
 */
export enum RewardStatusEnum {
  NO = 0,
  YES = 1
}
export interface GuessingSportsTeam {
  id: number // 队伍编号
  icon: string // 队伍icon
  name: string // 队伍名
}
export interface GuessingSportsMatch {
  matchId: number // 比赛id（每轮比赛中唯一）
  teamId1: number // 队伍1编号（对应teamList队伍编号）（注：null表示待定)
  teamId2: number // 队伍2编号（对应teamList队伍编号）（注：null表示待定)
  betRate0: string // 赔率-平局
  betRate1: string // 赔率-队伍1
  betRate2: string // 赔率-队伍2
  startTime: number // 开赛时间（注：null表示未设置)
  winTeamId: number // 获胜队伍编号 （注：平局为-1，null表示未设置）
  betTeamId: number // 投注队伍id（注：null表示未投注）
  betIntegral: string // 投注积分
  winIntegral: string // 输赢积分(注：空字符串未结算)
  matchStatus: 0 | 1 | 2 | 3 // 状态 0=竞猜中 1=待开奖（封盘）2=已开奖 3=待定
}
export interface GuessingSportsRank {
  rankId: number //排名 （如16=16强，8=8强，2=决赛）
  roundId: number //场次 （0=无，1=第1轮，2=第2轮）
  rankName: string //场次名
  defaultShow: string //是否默认展示的标签页
  matchList: GuessingSportsMatch[] // 比赛列表
}
// 获取积分-类型 0=每日赠送 1=充值1:10 2=打码1:1
export enum IntegralType {
  DAY,
  CHARGE,
  CODING
}
export interface ApiEventGetGuessingSports extends ApiEventGet {
  totalAmount: string //瓜分总金额
  myIntegral: string //我的积分
  winIntegral: string //输赢积分
  rewardTime: number //开奖时间
  ruleType: RuleTypeEnum //参与条件 0=无条件 1=累计充值 2=累计打码
  ruleValue: string //参与条件值
  currentValue: string //当前值
  betMin: string //最低下注限额(注：“0”或空字符串表示无限制)
  betMax: string //最高下注限额(注：“0”或空字符串表示无限制)
  rewardStatus: RewardStatusEnum //开奖状态 0=未开奖 1=已开奖
  integralTypes: IntegralType[] //获取积分-类型 0=每日赠送 1=充值1:10 2=打码1:1
  integralValue: string //积分-每日赠送值
  teamList: GuessingSportsTeam[] //队伍列表
  rankList: GuessingSportsRank[] //比赛场次、排名等信息
}
export interface ApiDoMatchBetRequest {
  activeId: number
  rankId: number
  roundId: number
  matchId: number
  betTeamId: number
  betIntegral: string
}
/**
 * 集字活动
//  */
export interface ApiEventGetCollectWords extends ApiEventGet {
  receiveId: number
  receiveStatus: ReceiveStatusEnum
  wordTimes: number
  groupTimes: number
  totalGroupUsers: number
  totalGroupTimes: number
  canReceive: number
  real_carve_money: number
  show_carve_money: number
  each_group_amount: number
  isDaySend: number
  images: Array<ApiEventGetCollectWordsImage>
  rule_list: Array<ApiEventGetCollectWordsRule>
}

// export interface CollectWordsActiveDataType{
//   wordTimes: number
//   groupTimes: number
//   totalGroupUsers: number
//   totalGroupTimes: number
//   canReceive: number
//   real_carve_money: number
//   show_carve_money: number
//   each_group_amount: number
//   receiveStatus: ReceiveStatusEnum
//   images: Array<ApiEventGetCollectWordsImage>
//   rule_list: Array<ApiEventGetCollectWordsRule>
// }
// export interface ApiEventGetCollectWords extends ApiEventGet {
//   activeData: CollectWordsActiveDataType
// }

export interface ApiEventGetCollectWordsRule {
  id: number
  receive_type: number
  amount: number
  real_amount: number
  day_limit: number
  real_limit: number
  text: string
  status: number
}

export interface ApiEventGetCollectWordsImage {
  id: string
  url?: string
  times?: number
  extractTimes: number
}

export interface ApiEventCollectWordsExtract {
  id: string
  images: ApiEventGetCollectWordsImage[]
  times?: number
}

export interface ApiEventCollectWordsExtractAll {
  images: ApiEventGetCollectWordsImage[]
  details: ApiEventGetCollectWordsImage[]
}

export interface ApiEventCollectWordsCompile {
  groupTimes: number
  each_group_amount: number
  totalGroupUsers: number
  totalGroupTimes: number
  currentGroupTimes: number
  images: ApiEventGetCollectWordsImage[]
}

export interface ApiEventReceiveSingle extends ApiEventReceiveReward {
  activeRes: {
    activeId: number
    receiveId: number
    bet: number
    status: number
  }
}

export interface ApiEventInvestReceiveRes {
  userGameGold: number
  activeRes: {
    activeId: number
    receiveData: {
      currentProfit: number
      gameGold: number
      totalProfit: number
    }
    receiveId: number
    status: number
  }
}

export interface ApiEventInvestDoInvestRes {
  gameGold: number
  investmentId: number
  ruleId: number
}

/**
 * 活动状态 1为正常，0 未到活动时间，或者已经过了活动时间
 * 0为未活动时间，1为活动活动时间内，2为已结束活动 (v3.8)
 */
export enum SignStartStatusEnum {
  UnStart = 0,
  Normal = 1,
  End = 2
}

/**
 * 	签到类型方式
 */
export enum SignTypeEnum {
  /**
   * 连续
   */
  Continuous = 0,
  /**
   * 累计
   */
  Grand = 1
}

/**
 * 签到
 */
export interface ApiEventSign {
  receiveStatus: ReceiveStatusEnum
  signList: ApiEventSignItem[]
  haveBet: number
  needBet: number
  havePay: number
  needPay: number
  signType: SignTypeEnum
  signDays: number
  signDay: number
  status: number
  receiveReward: number
  extraPrize: number //  如果大于0 就代表有额外奖励
  logStatus: number
  receiveId: number
  haveIntegral: number
  beforeLoginPopType: number
  afterLoginPopType: number
  signTime?: number // 上次签到时间戳
  signFirstTIme?: number // 首次签到时间戳
  vipRuleList?: SignVipRuleList[] // vip奖励列表
  monthReset: number // 重置时间
  canRecoup: boolean // 是否开启补签
}

/**
 * 签到vip奖励列表
 */
export interface SignVipRuleList {
  ruleList: ApiEventSignItem[]
  vipLevels: string
}
/**
 * 签到奖励类型
 */
export enum SignRewardTypeEnum {
  /** 固定 */
  Fixed = 0,
  /** 随机 */
  Random = 1
}

/**
 * 签到列表项
 */
export interface ApiEventSignItem {
  day: number
  rewardType: SignRewardTypeEnum
  prize: number
  icon: string
  signStatus: SignStatusEnum
  text: string
  extraPrize: number // 额外奖励
  isRecoup: boolean // 补签奖励
  needBet: number // 需要投注
  needPay: number // 需要充值
}

type ActiveRes = {
  bet: number
  receiveData: ApiEventSignReceiveData
}

export interface ApiEventSignReceiveData {
  day: number
  prize: number
  icon: number
  signStatus: SignStatusEnum
  text: string
  rewardType: number
  extraPrize: number // 额外奖励
}

export interface ApiEventSignReceive {
  activeRes: ActiveRes
  day: number
  prize: number
  icon: number
  signStatus: SignStatusEnum
  text: string
  rewardType: number
  extraPrize: number // 额外奖励
  receiveData: ApiEventSignReceiveData
}

/**
 * 领取状态 0 不可领取 1 可以领取 2 已领取
 */
export enum PromoteStatusEnum {
  NotReceive = 0,
  CanReceive = 1,
  HasReceive = 2
}

/**
 * 推广活动规则
 */
export interface ApiEventPromoteRule {
  id: number // 推广规则id
  promote_num: number // 推广人数
  reward: string // 推广金额 随机是用,分割的
  promoteStatus: ReceiveStatusEnum // 领取状态
  promoteAmount: number // 已领取金额
  receiveId: number // 领取ID
  competedTime: number // 完成任务时间戳
  canReceiveTime: number // 领取开始时间
  receiveStatus: number // 0不能领取 1 可以领取
  receiveDuration: number // 领取倒计时秒 可能位负数
  receiveTimeRemark: string // 领取 时间限制
  maxRuleReward: string
}

/**
 * 推广活动数据
 */
export interface ApiEventPromote {
  reward_type: number // 奖励类型(0 固定 1 随机 2 累计日结)
  rule_list: ApiEventPromoteRule[]
  canJoin: boolean
  unreceiveNum: number
  promoteCount: number
  valid_type: number
  pay: number
  bet: number
  limit_type: number
  promoteUrl: string
  displayType: displayTypeEnum // 1领奖方式 0开奖方式 2分享
  childrenCount: number
  firstPay: number
  payDays: number
  payTimes: number
  promoteAmount: number
  promoteCountToday: number
  displayReward: number // 显示奖励 0不显示 1显示
  appLogin: number // 是否要求下级下载APP并登录 1:是 | 0:否
  receiveTimeType: ReceiveTimeTypeEnum
  receiveTimeDay: number
  receiveDuration: number
  haveIntegral: number
}

/**
 * 显示奖励
 * HIDE: 0不显示
 * SHOW: 1显示
 */
export enum displayRewardEnum {
  HIDE = 0,
  SHOW = 1
}

/**
 * 显示彩金
 * 0显示  1禁止显示
 */
export enum DisableShowRewardEnum {
  SHOW,
  HIDE
}

/**
 * 开奖方式 0
 * 领奖方式 1
 * 分享 2
 */
export enum displayTypeEnum {
  LOTTERYMETHOD,
  RECEIVEAWARD,
  SHARE
}

/**
 * 代理活动数据 - 规则
 */
export interface ApiEventProxyRule {
  id: number
  amount: number // 反佣金额
  reward: number // 奖励金额
  reward_rate: number // 返佣比例
}

export interface ApiEventProxyRuleText {
  return_gold_type: ProxyReturnEnum // 0 按日 1 周 2月
  staticType: StaticTypeEnum // 0 按日 1 周 2月
  reward_type: ProxyType // 类型 0 固定 1 比例投资  2 累计日结
  rule_list: Array<ApiEventProxyRule> // 规则对象
}

/**
 * 代理活动数据
 */
export interface ApiEventProxy {
  activeId: number // 活动id
  canProfit: boolean // 今天是否已经领取
  /**
   * 0没有完成 1 可以领取 2 已经领取 3过期 4已派发 5未开始 6后台取消
   */
  receiveStatus: ReceiveStatusEnum
  hasProfilt: boolean // 是否可以领取
  commissionAmount: number // 佣金金额
  currentProfit: number // 此次投资获利
  ruleText: ApiEventProxyRuleText // 规则对象
  haveIntegral: number
  receiveId: number // 领取id
}

export enum systemTypeEnum {
  CUSTOM, //自定义
  BETNUMBER, //注单编号
  PLATFORM, //游戏平台
  CATEGORY, // 游戏类型
  GAMENUMBER, //牌局编号
  RECHARGEORDERID //充值订单
}

/**
 * 自定义活动跳转方式
 */
export enum CustomizeJumpTypeEnum {
  /** 外部链接 */
  OutsideLink = 2,
  /** 活动 */
  Activity = 3,
  /** 任务 */
  Task = 4,
  /** 充值 */
  Recharge = 5,
  /** 返水 */
  Cashback = 6,
  /** 代理 */
  Proxy = 7,
  /** vip */
  Vip = 8
}

/**
 * 自定义活动打开设备类型
 */
export enum CustomizeOpenDeviceTypeEnum {
  /** 电脑 */
  PC = 1,
  /** 手机 */
  Mobile = 2
}

/**
 * 自定义活动打开方式
 */
export enum CustomizeOpenTypeEnum {
  /** 当前窗口 */
  CurrentWindow = 1,
  /** 新窗口 */
  NewWindow = 2
}

/**
 * 自定义活动打开方式展示方式
 */
export enum CustomizeDisplayTypeEnum {
  /** 内置页面 */
  InternalPage = 0,
  /** 跳转外链 */
  JumpOutsideLink = 1
}

/*
 * 自定义活动单项数据
 */
export interface ApiEventCustomizeQuestionsItem {
  id: number
  systemType: systemTypeEnum
  question: string
}

/**
 * 自定义活动数据
 */
export interface ApiEventCustomize extends CustomizeNewStyleParams {
  questions: {
    /** 问题id */
    id: number
    /** 问题文案 */
    systemType: systemTypeEnum
    /** 0自定义问题 >0自定义； 1注单编号 2游戏平台 3游戏类型 4牌局编号 5充值订单 */
    question: string
  }[]
  /** 每日可以申请次数,剩余次数 */
  requestTimes: number
  /** 0/1 是否可以申请 1 可以申请 */
  requestType: 0 | 1
  /** 今天申请的次数 */
  todayTimes: number
  /** 活动时间申请的次数 */
  totalRequestTimes: number
  /** 行为验证0无验证 5 随机验证 6九宫格 */
  actionVerifyType: number
  /** 行为验证专用 */
  geeTestId: string
  /** 游戏平台集合 */
  platforms: string[]
  /** 游戏分类集合 */
  categories: string[]
  /** 展示方式 */
  displayType: CustomizeDisplayTypeEnum
  displayList: {
    /** 设备类型 1电脑 2手机 */
    deviceType: CustomizeOpenDeviceTypeEnum
    /** 打开方式 1当前窗口 2新窗口 */
    openType: CustomizeOpenTypeEnum
    /** 跳转url */
    url: string
    locationType: CustomizeJumpTypeEnum //跳转类型
  }[]
  haveIntegral: number
}

/** 新版自定义活动增加字段 */
export interface CustomizeNewStyleParams {
  /** 活动名称 用于预览的时候 */
  titleTxt?: string
  /** 是否新模板：0-否 1-是 */
  isNewStyle: CommonSwitchStatus
  /** 选择背景风格：0-系统自带 1-自定义风格 2-自定义渐变 */
  bgColorStyle: BgColorStyleEnum
  /** 系统自带-具体颜色 */
  defaultBgColor: number
  /** 选择纹理类型：0-系统自带 1-自定义纹理 */
  textureType: TextureTypeEnum
  /** 纹理 */
  textureVal: string
  /** 自定义-顶部图片 */
  customTopImg: string
  /** 自定义-中间颜色 */
  customMidBgColor: string
  /** 自定义-底部图片 */
  customBottomImg: string
  /** 渐变-顶部颜色 */
  gradientTopColor: string
  /** 渐变-中间颜色 */
  gradientMidBgColor: string
  /** 渐变-底部颜色 */
  gradientBottomColor: string
  /** 活动html */
  htmlContent: string
}

/** 选择背景风格 */
export enum BgColorStyleEnum {
  /** 系统自带 */
  System,
  /** 自定义风格 */
  CustomStyle,
  /** 自定义渐变 */
  CustomGradient
}

/** 选择纹理类型 */
export enum TextureTypeEnum {
  /** 系统自带 */
  System,
  /** 自定义纹理 */
  CustomTexture
}

/**
 * 转盘活动数据
 */
export interface ApiEventLuckyIntegral {
  haveIntegral: number // 幸运值
  isShowDetails: number // 1=展示详情，0=不展示
  isShowAnnouncement: number // 是否显示公告 1=显示 0=不显示
  isShowTask: number // 是否显示任务 1=显示 0=不显示
  turntableList: Array<TurntableList>
  integralRatio: number // 多少打码兑换 1积分
  totalIntegral: number // 总积分
  usedIntegral: number // 已用积分
  validBet: number // 打码数据
  betForIntegral: number // 还需打码多少获取 1积分
  rewardType: number // 奖励类型
  expireIntegral: number // 已过期幸运值
}

/**
 * 转盘-幸运值详情列表
 */
export interface LuckyIntegralList {
  rewardTime: number
  taskDesc: string
  luckyIntegral: number
}
export interface ApiLuckyIntegralList {
  total: number
  taskCategory: number
  totalIntegral: number
  usedIntegral: number
  data: LuckyIntegralList[]
}

// 竞猜记录
export interface MatchBetRecord {
  id: number
  rankName: string
  teamName1: string
  teamName2: string
  betTeamName: string
  betRate: string
  betIntegral: string
  winIntegral: string
  betTime: number
  betStatus: 0 | 1 | 2 //状态 0=未开奖 1=已中奖 2=未中奖
}
export interface ApiMatchBetRecordList {
  total: number
  taskCategory: number
  data: MatchBetRecord[]
}
export interface RedPocketQucikActivity {
  timingList: {
    timing: number
    duration: number
    id: number
  }[]
}
export interface FastEntryItem {
  id: number //活动ID
  reword: number // 奖励
  clientShowImgSize: number // 1 小图 2 大图
  template: ActivityTemplate //活动类型
  startTime: number //活动开始时间
  endTime: number //活动结束时间
  img: string //快捷图片地址
  isUserUpload: number // 是否是用户上传0，1
  isShowReward: number //是否显示金额 0，1
  isShowActiveName: number //是否显示活动名称 0，1
  showType: number //显示方式 0=平铺 1=轮播
  reward: number //奖励金额
  ActiveName: string //活动名称
  /** 结束展示 的时刻  */
  endShowTime: number
  isShowCountDown: number //是否显示倒计时 0，1
  showDeviceType: string //显示设备类型 1:iOS ,2:Android ,3:H5 4: Web
  /** 会员层级,分割， “0”或者空 表示全部层级 */
  userLevel: string
  detail: RedPocketQucikActivity | null //根据活动类型 返回值不同， 目前只有抢红包 可能有值 ，返会显时
}
export type FastEntryList = FastEntryItem[]

/**
 * 首页快捷入口图片大小
 * 1=大图 2=小图
 */
export enum FastEntryItemImgSize {
  big = 1,
  small = 2
}

/**
 * 领取次数限制
 * 1=固定 2=当日充值 3=当日打码
 */
export enum LuckBetReceiveTypeEnum {
  Fixed = 1,
  Charge,
  Bet
}

/**
 * 领取状态
 * 0=未领取 1=已领取
 */
export enum LuckBetReceiveStatusEnum {
  CanReceive,
  HasReceive
}

/**
 * 注单号规则
 * 1=尾号 2=连号 3=任意位置
 */
export enum LuckBetRuleTypeEnum {
  End = 1,
  Continue,
  Random
}

/**
 * 奖励方式
 * 1=固定金额 2=下注金额倍数
 */
export enum LuckBetRewardTypeEnum {
  Fixed = 1,
  Times
}

// 幸运注单中奖记录
export interface LuckyBetRecordItem {
  betTime: number // 时间
  matchKey: string // 匹配key,matchKey长度必须等于orderNo长度,matchKey由0或1组成，0=不高亮 1=要高亮
  orderNo: string // 注单号, 高亮字符'8888'
  rewardAmount: string // 奖励金额
  receiveStatus: ReceiveStatusEnum
  recordId: number
  btnLoading?: boolean
}

// 幸运注单中奖记录
export type LuckyBetRecord = LuckyBetRecordItem[]

export interface ReceiveListItem {
  amount: string // 当日充值或打码金额
  num: string // 每日次数上限
}

export interface RewardListItem {
  ruleValue: string // 注单号规则值
  ruleNum: string // 注单号规则个数(ruleType=3有效)
  amount: string // 奖励金额
  amountTimes: string // 奖励金额倍数(仅rewardType=2有效)
  amountLimit: string // 奖励金额上限(仅rewardType=2有效)
}

export interface CategoryPlatformGame {
  gameCategoryId: number
  platformCategoryGame: { platformId: number }[]
}

/**
 * 幸运注单活动数据
 */
export interface ApiEventLuckyBet {
  receiveType: LuckBetReceiveTypeEnum // 领取次数限制 1=固定 2=当日充值 3=当日打码
  receiveTotalNum: string // 总次数上限
  receiveNum: string // 今日次数上限
  receiveAvailableNum: string // 今日可用次数
  receiveList: ReceiveListItem[] // 每日次数上限列表（仅receiveType=2,3有效）
  minBetLimit: string // 最低有效投注限制
  ruleType: LuckBetRuleTypeEnum // 注单号规则 1=尾号 2=连号 3=任意位置
  rewardType: LuckBetRewardTypeEnum // 奖励方式 1=固定金额 2=下注金额倍数
  rewardList: RewardListItem[] // 奖励设置
  categoryPlatformGame: CategoryPlatformGame[]
  amountLimitSwitch: number // 幸运注单-彩金上限开关 1开启 0关闭 默认1
  haveIntegral: number
}

export interface orderDetail {
  amount: number
  reward: number
  nowAmount: number
  progress: string
  receiveStatus: number
  receiveId: number
  subactiveid: string
  text: string
  rechargeAudit: number
  competedTime: number // 完成时间
  canReceiveTime: number // 可领取时间
  receiveDuration: number // 倒计时
  receiveTimeRemark: string // 领取时间备注
  ruleId: string
}

export interface ReceiveTimeRemark {
  receiveTimeType: ReceiveTimeTypeEnum //领取类型
  receiveTimeDay: number
  receiveTimeStart: number
  receiveTimeEnd: number
  receiveDuration: number // 倒计时
}

export interface ruleListItem {
  amount: number
  receiveId: number // 子活动ID
  ruleId?: string // 子活动ID
  reward: string
  signType: EventSignTypeEnum // 奖金方式0固定，0的时候属性丢失 1比例 2区间随机 ，目前list 列表没有返回改属性
  nowAmount: number //	实际充值金额
  receiveStatus: number //	领取状态 0 为进行中，1为可领取，2为已领取
  ratio: number //	单笔充值%
  subactiveid: string // 充值单号 每笔奖励开启后有值
  text: string // 活动介绍
  limitReward: number //	上限
}

export interface ApiEventListedRewards {
  rechargeEachOrder: number // 默认0，1表示开启每笔充值奖励
  orderDetails: orderDetail[]
  ruleList: ruleListItem[]
  receiveList?: orderDetail[]
  haveIntegral: number
  receiveTimeType: number
  ruleType: number
}

export interface ApiEventRankingData {
  /** 排名依据：1-累计充值，2-单笔充值，3-累计打码*/
  rankingType: RankingType
  /** 1,2,3名图标 */
  rankingIconImg: string[]
  /** 排名区间 */
  rankingRange: ActiveRankingRange[]
  /** 今日/本周/本月排行榜 */
  todayList: RankingItem[]
  /** 昨日/上周/上月排行榜 */
  lastList: RankingItem[]
  /** 我的今日/本周/本月排名 */
  myRankingToday: number
  /** 我的昨日/上周/上月排名 */
  myRankingLast: number
  /** 奖励ID */
  receiveId: number
  /** 奖励金额 */
  reward: number
  /** 奖励状态 */
  receiveStatus: ReceiveStatusEnum
  /** 奖励倒计时 正数是领取时间倒计时，负数是过期时间倒计时 receiveDuration<=0才能领取 */
  receiveDuration?: number
  /** 是否已结算：0-未结算 1-已结算 */
  isSettled: StatusType
  /** 奖励金额：1-固定 2-随机 */
  rewardType: RankingRewardTypeEnum
}

/**
 * 奖励方式
 * 1=固定 2=随机
 */
export enum RankingRewardTypeEnum {
  /** 固定 */
  Fixed = 1,
  /** 随机 */
  Random = 2
}

/** 排名区间 */
export interface ActiveRankingRange {
  /** 区间开始 */
  rangeStart: number
  /** 区间结尾 */
  rangeEnd: number
  /** 固定奖励金额 */
  reward: number
  /** 最小随机展示金额 */
  displayRewardMin: number
  /** 最大随机展示金额 */
  displayRewardMax: number
}

export enum RankingType {
  /** 1-累计充值 */
  UNCONDITIONAL = 1,
  /** 2-单笔充值 */
  CUMULATIVERECHARGE,
  /** 3-累计打码 */
  CUMULATIVECODING
}

/** 排行榜单个数据结构 */
export interface RankingItem {
  /** 排名依据：1-累计充值，2-单笔充值，3-累计打码*/
  rankingType: RankingType
  /** 排名*/
  ranking: number
  /** 用户id*/
  useridx: string
  /** 统计周期：1-日，2-周，3-月*/
  cycleType: number
  /** 累计金额*/
  amount: number
  /** 奖励金额或金额区间*/
  rewardRange: string
}

export enum DiscountType {
  /**
   * 通用型兑换码
   */
  Universal = 1,
  /**
   * 唯一型兑换码 用过即焚
   */
  UniqueType = 2,
  /**
   * 验证已绑定手机
   */
  BoundPhone = 3,
  /**
   * 验证指定手机号领彩金（自动绑定）
   */
  VerifySpecifiedPhone = 4
}

/**
 * 优惠方式 1固定奖金 2首充奖金 3首充比例 4随机金额
 */
export enum AmountTypeEnum {
  /**
   * 固定奖金
   */
  Fixed = 1,
  /**
   * 2首充奖金
   */
  FirstDepositBonus,
  /**
   * 3首充比例
   */
  FirstChargeRatio,
  /**
   * 4随机金额
   */
  Random
}

export interface ContentConfigAmountItem {
  /**
   * 首充金额
   */
  rechargeAmount: number
  /**
   * 奖励金额
   */
  rewardAmount?: number
  /**
   * 奖励比例
   */
  ratio?: number
  /**
   * 奖励上限
   */
  limitReward?: number
}

export interface ContentConfig {
  areaCode: string
  amountList: Array<ContentConfigAmountItem>
  codeType: DiscountCodeTypeEnum // 优惠码类型 1通用优惠码 2唯一优惠码 3验证已绑定手机 4验证指定手机号领彩金（自动绑定）
  amountType: AmountTypeEnum // 优惠方式 1固定奖金 2首充奖金 3首充比例 4随机金额
  amount: string // 优惠方式对应额金额 1固定金额 2首充奖金 3首充比例 上线金额 4随机金额中的期望金额
  rate: string // 首充比例
  amountRange: string // 随机金额的范围,分割
  profitLimit: number // 盈利上限
  withdrawLimit: number // 提现上限
  disableShowReward: DisableShowRewardEnum // 彩金金额显示
  showAmountRange: string // 彩金金额显示范围，分割
  getCodeMethod: number // 获取兑换码方式（0-私下推广发送 1-点击链接获取）
  getCodePCLink: string // 获取兑换码PC端链接
  getCodeAppLink?: string // 获取兑换码手机端链接
}

export interface IsShowDiscountResp {
  activeId: number //活动id
  contentConfig: ContentConfig
}

/**
 * 是否已经绑定了手机 0没有 1 已经绑定 codeType==3/4 才有效, 2 已经绑定 并且在电话号码里面 codeType==4有效
 */
export enum BindPhoneEnum {
  /**
   * 没有绑定
   */
  Unbound,
  /**
   * 已经绑定 codeType==3/4才有效
   */
  Bound,
  /**
   * 已经绑定 并且在电话号码里面 codeType==4有效
   */
  HasPhoneNumber
}

export interface ApiEvent {
  bindPhone?: BindPhoneEnum // 是否绑定手机 （0=>未绑定 1=>已绑定 ）
  amount?: number
  contentConfig: ContentConfig
  receiveStatus?: ReceiveStatusEnum
  haveIntegral: number
}
export interface PopRechargeEventList {
  amount: number // 充值要求
  reward: number // 奖励金额
  text: string // 文本
}

export interface PopRechargeEvent {
  id: number // 活动ID
  name: string // 活动名称
  list: PopRechargeEventList[]
}

// 首充活动列表
export type PopRechargeList = PopRechargeEvent[]
export type PopRechargeListResp = {
  actives: PopRechargeEvent[]
  disablePop: number // 是否永不提示（0否, 1是）
}

/*
 *新人彩金兑换码方式
 */
export enum DiscountCodeMethod {
  /** 私下推广发送 */
  SendPrivatePromotion = 0,
  /** 点击链接获取 */
  LinkToGet = 1
}

/**
 * 1通用优惠码 2唯一优惠码 3已绑定手机优惠码 4未绑定手机优惠码 5首充优惠券 6邀请链接
 */
export enum DiscountCodeTypeEnum {
  /**
   * 通用优惠码
   */
  Universal = 1,
  /**
   * 唯一优惠码
   */
  Unique,
  /**
   * 已绑定手机优惠码
   */
  BoundPhone,
  /**
   * 未绑定手机优惠码 | 验证指定手机号领彩金（自动绑定）
   */
  UnboundPhone,
  /**
   * 首充
   */
  FirstCharge,

  /**
   * 邀请链接
   */
  InvitationLink
}

export interface DiscountCodeData {
  codeType: DiscountCodeTypeEnum // 1通用优惠码 2唯一优惠码 3已绑定手机优惠码 4未绑定手机优惠码
  discountCode: string // 优惠码 codeType==3/4时为 短信验证flag， 当codetype==4 bindPhone=2 de 时候 该值为空
  activeId: number //活动id 必传
}
export interface CheckCodePhoneNumberReq {
  areaCode: string // 区号
  phoneNumber: string // 电话号码
  activeId: number //活动id 必传
}

/**
 *  0成功 1活动ID无效 2活动id不是电话彩金 3区域码不正确 4电话号码不存在 5电话号码已被使用
 */
export enum CheckPhoneEnum {
  /**
   * 成功
   */
  Success,
  /**
   * 活动ID无效
   */
  Invalid,
  /**
   * 活动id不是电话彩金
   */
  NotPhoneWinnings,
  /**
   * 区域码不正确
   */
  IncorrectAreaCode,
  /**
   * 电话号码不存在
   */
  PhoneNotExist,
  /**
   * 5电话号码已被使用
   */
  PhoneAlreadyUse
}

export interface CheckCodePhoneNumberResp {
  status: CheckPhoneEnum // 0成功 1活动ID无效 2活动id不是电话彩金 3区域码不正确 4电话号码不存在 5电话号码已被使用
  message: string
}

/**
 * 0表示成功 ，1活动期间仅可兑换一次 2优惠码有误或已过期 3需完成至少一次充值才可兑换 4优惠码与活动不匹配 5优惠金额过低，请联系客服
 */
export enum DiscountCodeStatusEnum {
  /**
   * 成功
   */
  Success,
  /**
   * 活动期间仅可兑换一次
   */
  OnlyOneTime,
  /**
   * 优惠码有误或已过期
   */
  WrongOrExpired,
  /**
   * 需完成至少一次充值才可兑换
   */
  RechargeAtLeastOnce,
  /**
   * 优惠码与活动不匹配
   */
  CouponCodeNotMatchActivity,
  /**
   * 优惠金额过低，请联系客服
   */
  DiscountAmountTooLowContactCustomerService
}

export interface DiscountCodeResp {
  status: DiscountCodeStatusEnum // 0表示成功 ，1活动期间仅可兑换一次 2优惠码有误或已过期 3需完成至少一次充值才可兑换 4优惠码与活动不匹配 5优惠金额过低，请联系客服
  message: string // 失败的具体信息
  discountCode: string
  activeId: number //活动id ，可以用0
  amount: number //奖励金额
  gameGold: number //	用户余额
  withdrawLimit: number // 提款限制
}

export interface GetByTemplateReq {
  template: string // //支持多个，使用逗号分割
  needFilterLevel?: number //可传可不传 如果needFilterLevel=1 则过滤符合当前用户层级的结果
}

export interface ActivityTemplateActiveData {
  red_pack_type?: number
}

export interface DisableActivePopResq {
  popType: number // 弹框类型 1充值弹框
  popStatus: number // 弹框状态 1禁用
}
export interface PublicIntroductionProps {
  eventData: ApiEventGet
  isShowBottom: boolean
}

/**
 * 推广详情请求参数
 */
export interface ApiPromoteDetailsType {
  activeId: number
  page: number
  size: number
  userName?: string
  isPass?: number
}

/**
 * 推广详情接口返回参数
 */
export interface PromoteDetailsTypeList {
  total: number
  taskCategory: number
  details: PromoteDetailsType[]
}

export interface PromoteDetailsType {
  activeId: number
  parentId: number
  useridx: number
  userName: string
  isPass: number
  registerTime: number
  // remark: promoteDetailsRemark[]
  remark: string
  updateTime: number
}

/**
 *  活动领取单个活动 【入参】
 */
export interface ApiActiveReceiveOnePayload {
  activeId: number // 活动ID
  receiveId: string // 待领取记录表id，多个id可以用,分割；只限于统一活动的多个记录id
}

/**
 *  签到活动领取 【入参】
 */
export interface ApiActiveSingPayload {
  activeId: number // 活动ID
  receiveId?: string // 待领取记录表id，多个id可以用,分割；只限于统一活动的多个记录id
  singDay: number // 前端当前是第几天签到
  isRecoup: boolean // 是否进行得是补签
  IsAgainSign: boolean // 是否为二次确认签到
}

/**
 *  领取单个活动 【返回值】
 */
export interface ApiActiveReceiveOneResponse {
  receiveId: number // 待领取记录表id
  ruleId: string // 领取奖励对应的规则
  reward: number // 奖励额
  amount: number // 奖励条件金额
  receiveData?: ApiEventSignReceiveData | LotteryResultReceiveData // 幸运转盘领取结果返回数据
}

/**
 * 任务单个领取【入参】
 */
export interface ApiActivetaskGetprizePayload {
  taskid: number //	任务ID
  ruleid: number //	任务规则类型
  template: number //	任务模板
  receiveLogId: number //	可领取奖励ID
}

/**
 * 任务单个领取【返回值】
 */
export interface ApiActivetaskGetprizeResponse {
  gameGold: number //	用户领取后的余额
  prize: number //	奖励的金额
}

/**
 * VIP领取奖励【入参】
 */
export interface ApiUserGiftRecv1Payload {
  types: string // 领取类型：新的类型(13001=晋级彩金,13002=周奖金,13003=月奖金)
  vip: number // 领取奖励的vip等级
  logId: number // 对应领取记录ID
}

/**
 * VIP领取奖励【返回值】
 */
export interface ApiUserGiftRecv1Response {
  game_gold: number // 领取后的余额
}

/**
 * 利息宝领取【返回值】
 */
export interface ApiGetInterestResponse {
  balance: string // 存入本金
  receiveType: number // 领取类型，1-次日，2-当日
  interest: string // 领取利息
  settlementCycle: number // 结算周期
  yearRate: string // 年利率
  interestTop: number // 利息封顶
}

/**
 * 奖励类型
 */
export enum RewardTypeEnum {
  /* 比例 */
  PROPORTION = 0,
  /* 固定 */
  FIXED = 1
}

export interface ApiEventRescue {
  canReceiveTime: number // 可领取时间
  competedTime: number // 完成时间
  nowBet: number // 当前亏损
  prize: number // 奖励金额
  receiveDuration: number // 过期时间
  receiveId: number // 领取记录ID
  receiveRuleId: string // 领取规则ID
  receiveStatus: number // 0没有完成 1 可以领取 2 已经领取 3过期 4已派发 5后台取消 6待审批（申请） 7待申请， 8后台拒绝
  receiveTimeRemark: string // 可领取配置
  rewardType: RewardTypeEnum // 救援金也用该字段 1固定，0比例（0隐藏了该属性）
}
export interface VipConfig {
  [currency: string]: {
    banUserLevel: string // 禁止参与的用户层级
  }
}

export interface YueBaoConfig {
  [currency: string]: {
    userLevels: string // 会员层级id,逗号分隔,全部=0
  }
}

export interface ReturnGoldConfigItem {
  currency: string //多个币种，以逗号分割
  userLevels: string // 会员层级id,逗号分隔,全部=-1
  vipLevels: string // 会员vip等级id,逗号分隔,全部=-1
}

export interface RechargeFundConfigItem {
  currency: string //多个币种，以逗号分割
  userLevels: string // 会员层级id,逗号分隔,全部=-1
}
/**
 * 优惠页排序：1-活动,2-任务 3-返水 4-利息宝 5-vip 6-待领取 7领取记录 以逗号分隔
 */
export enum ActivePageSortEnum {
  Active = 1,
  Task = 2,
  Cashback = 3,
  Interest = 4,
  Vip = 5,
  CanReceive = 6,
  Records = 7,
  RechargeFund = 8
}

export interface ApiActiveIsShowV2Response {
  activePageSort: string // 优惠页排序：1-活动,2-任务 3-返水 4-利息宝 5-vip 6-待领取 7领取记录 以逗号分隔
  disableReceiveAwardAll: ReceiveAwardAllSwitchEnum // 一键领取开关
  disableReceiveLogPop: ReceiveLogPopSwitchEnum // 待领取弹框设置
  vipConfig: VipConfig // vip开关配置，币种为key
  returnGoldClosed: ReturnGoldSwitchEnum // 返水开关
  returnGoldConfig: ReturnGoldConfigItem[] // 返水配置
  yueBaoConfig: YueBaoConfig // 利息宝配置,币种为key
  rechargeFundClosed: ReturnGoldSwitchEnum // 公积金开关
  rechargeFundConfig: RechargeFundConfigItem[] // 公积金配置
}

/**
 * 返水开关： 0-开启 1-关闭
 */
export enum ReturnGoldSwitchEnum {
  Open = 0,
  Close = 1
}

/**
 * 一键领取开关: 0-开启 1-关闭
 */
export enum ReceiveAwardAllSwitchEnum {
  Open = 0,
  Close = 1
}

/**
 * 待领取弹框
 */
export enum ReceiveLogPopSwitchEnum {
  /** 不弹框 */
  NoPop = 0,
  /** 每日一次 */
  DailyPop = 1,
  /** 奖励待领取时弹窗 */
  OnlyPop = 4,
  /** 只弹一次 */
  OncePop = 5,
  /** 每次登录 */
  LoginPop = 6
}

/**
 * 用户层级
 */
export enum UserLevelEnum {
  All = '0' // 所有层级
}

/**
 * 返水配置币种
 */
export enum ReturnGoldConfigCurrencyEnum {
  All = '0' // 所有币种
}

/**
 * 奖励动效
 */
export enum RewardAniType {
  TYPE_1 = 1, // 金额小于10
  TYPE_2 = 2, // 金额大于10
  TYPE_3 = 3 // 一键领取
}

/*
 * 签到结果
 */
export enum SingResult {
  /** 成功 */
  success = 0,
  /** 前台状态与后台不符合 */
  statusError = 1,
  /** 前台状态与后台不符合 */
  conditionError = 2
}

/** 周期枚举 */
export enum RankingRequestDayEnum {
  /** 0-今日/本周/本月 */
  Now = 0,
  /** 1-昨日/上周/上月 */
  Last
}

/** 活动排行榜更多详情 请求参数 */
export interface GetRankingListParams {
  /** 活动ID */
  activeId: number
  /** 周期：0-今日/本周/本月，1-昨日/上周/上月 */
  requestDay: RankingRequestDayEnum
  /** 区间起始位置 */
  rangeStart: number
  /** 区间结束位置 */
  rangeEnd: number
}

/** 充值后弹框 */
export interface AfterRechargeActivityItem {
  /** 活动ID */
  id: number
  /** 活动名称 */
  name: string
  /** 活动类型 */
  template?: ActivityTemplate
  /** 活动类别 6:公积金活动 1:签到活动\优惠码活动\充值返利活动…… */
  activeType: AfterRechargeActivityTypeEnum
  /** 活动类型名称 */
  activeSortedName: string
  /** 具体数据 可以是活动的数据，也可以是公积金的数据 */
  detailData?: RechargeFundSettingResponse | ApiEventGet
}

/** 活动类别 6:公积金活动 1:签到活动\优惠码活动\充值返利活动…… */
export enum AfterRechargeActivityTypeEnum {
  CommonActivity = 1,
  RechargeFundActive = 6
}

/**
 * 活动任务-查询完成情况
 */
export interface ApiActiveTaskCompleteList {
  bindType: number // 任务对象类型
  typeText: string // 类型描述信息
  isComplete: ActiveTaskCompleteItem // 是否完成 1完成
}

/**
 * 活动任务-查询完成情况
 */
export enum ActiveTaskCompleteItem {
  complete = 1, // 完成
  undone = 0 // 未完成
}

/**
 * 活动未完成任务
 */
export interface ActiveTaskUndoneData {
  [key: string]: ActiveTaskUndoneItem
}
export interface ActiveTaskUndoneItem {
  tips: TranslateResult
  onOk: () => void
}

/** 活动领取通用配置 */
export interface ActiveReceiveOne {
  params: ApiActiveReceiveOnePayload
  onSuccess?: (data: ApiActiveReceiveOneResponse) => void
  followUp?: OpenOptions
  onError?: () => void
  judgeResBefore?: (data: ApiActiveReceiveOneResponse) => void
}
/** 检查手机号是否具备领取资格 请求参数 */
export interface VerifyDiscountPhoneReq {
  areaCode: string
  phone: string
  activeId: number
}

export interface VerifyDiscountPhoneResp {
  status: CheckPhoneEnum // 0成功
  message: string
}
