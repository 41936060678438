import { AxiosRequestConfig } from 'axios'
import { GlobalService } from '@/context'
import {
  Html2imgRequest,
  PromoteConfigResponse,
  PromoteShareResponse,
  PublicityResponse,
  ReportAgentDataResponse,
  SensitiveDataConfigResponse,
  TutorialResponse,
  WithdrawComminissionResponse
} from './type'
import usePromoteStore from '@/views/promote/store'

/**
 * 我的推广首页配置数据-静态化
 */
export const apiGetPromoteConfigIndex = () =>
  GlobalService.get<PromoteConfigResponse>({
    url: '/promote/config/index',
    customParams: {
      cache: true,
      tryStaticMode: true,
      staticKeyOmit: ['platformType', 'currency'],
      keyOmit: true
    }
  }).then((res) => res.data.data as PromoteConfigResponse)

/**
 * 一键提取佣金
 */
export const apiWithdrawComminission = () =>
  GlobalService.post<WithdrawComminissionResponse>({
    url: '/promote/extract'
  }).then((res) => res.data.data as WithdrawComminissionResponse)

/**
 * 获取宣传图配置
 */
export const apiGetPublicity = (
  payload: { link: string },
  customParams?: AxiosRequestConfig['customParams']
) =>
  GlobalService.post<PublicityResponse>({
    // url: '/promote/getPublicity',
    url: '/promote/getPublicityV2',
    customParams,
    data: payload
  }).then((res) => res.data.data as PublicityResponse)

/**
 * 获取宣传图配置
 */
export const apiPromoteShare = () =>
  GlobalService.post<PromoteShareResponse>({
    url: '/promote/share'
  }).then((res) => res.data.data as PromoteShareResponse)

/**
 * go端产生图片
 */
export const apiHtml2img = (payload: Html2imgRequest) =>
  GlobalService.post<string>({
    url: '/html2img',
    data: payload,
    customParams: {
      noEncrypt: true
    }
  }).then((res) => res.data.data as string)

/**
 * 获取代理模式教程
 */
export const apiGetTutorial = () => {
  const { agent_id } = usePromoteStore().getAgentModeData
  return GlobalService.get<TutorialResponse>({
    url: '/promote/config/tutorial',
    customParams: {
      cache: true,
      tryStaticMode: true,
      staticKeyOmit: ['platformType', 'currency'],
      keyOmit: true
    },
    params: {
      agentId: agent_id
    }
  }).then((res) => res.data.data as TutorialResponse)
}

/**
 * 首页获取代理数据/代理个人业务数据
 */
export const apiGetReportAgentData = (payload: { h5_addr?: string } = {}) =>
  GlobalService.post<ReportAgentDataResponse>({
    url: '/promote/v1/report/agentData',
    data: payload
  }).then((res) => {
    checkResponse(res)
    return res.data.data as ReportAgentDataResponse
  })
/* 获取敏感数据列表
 * /promote/agentSet/sensitiveDataConfig/list
 */
export const apiGetSensitiveDataConfig = () =>
  GlobalService.get<SensitiveDataConfigResponse>({
    url: '/promote/config/sensitiveData',
    customParams: {
      cache: true,
      tryStaticMode: true
    }
  }).then((res) => res.data.data as SensitiveDataConfigResponse)

/*
 * 选择展示推广链接
 */
export const apiSelectpromotelink = (payload: { domain: string }) =>
  GlobalService.post<string>({
    url: '/promote/selectpromotelink',
    data: payload
  }).then((res) => res.data.data as string)

/** 检测是否可能串号上报回调 接口响应data内涵requestId 才可以匹配 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkResponse = (res: any) => {
  const requestId = res?.config?.headers?.['x-request-id']
  if (requestId != res?.data?.data?.requestId) {
    import('@/context/tacker').then(({ trace }) => {
      trace('error', { errorName: 'errorResponse', ...res })
    })
  }
}
