import {
  Base,
  Component,
  Emit,
  Mixins,
  Prop,
  Ref
} from '@/vue-property-decorator'
import { getCurrentDevice } from '@/utils/Device'
import { useMainStore } from '@/store/index'
import { windowOpen } from '@/utils/business-utils'
import AppTypes, { object, string } from '@/vue-types'
import UnitMixins, { Props as UnitMixinsProps } from '@/mixins/unit'
import style from './style.module.scss'
type AdapterMode = 'iframeDoc' | 'iframeSrc' | 'innerHTML' | 'scale'
type UnitTransformConfig = {
  open: boolean
  /**
   * 现有容器中的所需要的基础字体大小，
   * 默认为 本 web 大厅 设计稿中的 18px
   */
  targetBaseFontSize?: number
  /**
   * 后台配置的html中的基础字体大小
   * 默认为 常规后台 配置的 1080 px的 基础字体大小 为36px
   */
  originalBaseFontSize?: number
}

type DesignSize = {
  width: number
  height: number
}
export interface Props extends UnitMixinsProps {
  /**
   * 设置使用所有a标签移动端的时候使用openwindow进行跳转
   * 使用场景 马甲包的时候会用到
   */
  useWindowOpen?: boolean
  /**
   * 是否设置a标签target为_top(如业主设置了target则默认使用设置的)
   */
  isSetATargetTop?: boolean
  /**
   * 适配模式
   */
  mode?: AdapterMode

  unitTransformConfig?: UnitTransformConfig
  /**
   * innerHTML  ，会传入 iframe 的 srcdoc 或者正常标签的innerHTML
   */
  html?: string
  iframeSrc?: string
  /**
   * iframe 创建 style 内容的函数
   */
  iframeStyle?: (baseStyle: string) => string

  /**
   * mode 为 scale 时有效
   * 按设计稿宽高进行缩放的值
   */
  scale?: number
  /**
   * mode 为 scale 时有效
   * 设计稿宽高
   */
  designSize?: {
    width: number
    height: number
  }
  /**
   * 额外设置scale样式
   */
  scaleStyle?: { [key: string]: unknown }
  /**
   * iframe 滚动条
   */
  scrolling?: string
}

export type Events = {
  onLoad?: () => void
}
/**
 * cocos 默认字体 38px （1020px设计稿下）
 * web 1200 对应
 * 转rem后 会变成 0.38rem
 *
 * lobby大厅 默认 18 px  即，0.38rem 需要代表  18px
 * 则 1rem = 19 / 0.38 = 50px
 */

const baseFontSize = {
  LobbyWeb: 19,
  LobbyH5: 38
}

export const createBaseStyle = ({
  targetBaseFontSize = baseFontSize.LobbyWeb
} = {}) => {
  const { currentTheme } = useMainStore()
  return `
    html,body{
      margin:0;
      padding:0;
    }

    html{
      -webkit-text-size-adjust:none;
    }
    body{
      font-size: ${targetBaseFontSize}px;
      color:${currentTheme['--theme-text-color']};
      font-family: "Microsoft Yahei";word-break: break-word;
    }
    p{margin-top: 0px;margin-bottom: 0px;text-align: left;}
    body ol{ padding: 6%;}
`
}

@Component<Adapter>({
  name: 'Adapter'
})
export default class Adapter extends Mixins<
  Base<unknown, Props, Events>,
  UnitMixins
>(Base, UnitMixins) {
  @Ref()
  private readonly iframeRef!: HTMLIFrameElement
  @Prop({ default: false })
  private readonly useWindowOpen!: Props['useWindowOpen']
  @Prop()
  private readonly isSetATargetTop!: Props['isSetATargetTop']
  @Prop(string<AdapterMode>().def('iframeSrc'))
  private readonly mode!: NonNullable<Props['mode']>
  @Prop(AppTypes.string.def('auto'))
  private readonly scrolling!: Props['scrolling']

  @Prop(AppTypes.string)
  private readonly html!: Props['html']
  @Prop(AppTypes.string)
  private readonly iframeSrc!: Props['iframeSrc']

  @Prop(
    object<UnitTransformConfig>().def({
      open: true,
      targetBaseFontSize: baseFontSize.LobbyWeb,
      originalBaseFontSize: baseFontSize.LobbyH5
    })
  )
  private readonly unitTransformConfig!: NonNullable<
    Props['unitTransformConfig']
  >

  @Prop(
    AppTypes.func.def((baseStyle) => {
      return baseStyle
    })
  )
  private readonly iframeStyle!: NonNullable<Props['iframeStyle']>

  @Prop(AppTypes.number.def(1))
  private readonly scale!: NonNullable<Props['scale']>

  @Prop(AppTypes.object.def(() => ({})))
  private readonly scaleStyle!: NonNullable<Props['scaleStyle']>

  @Prop(
    object<DesignSize>().def({
      width: 1080,
      height: 1920
    })
  )
  private readonly designSize!: NonNullable<Props['designSize']>

  private iframeHeight = '0px'

  private transformUnit(html: string) {
    if (!this.unitTransformConfig.open) return html
    const $div = document.createElement('div')
    $div.innerHTML = html
    const originalBaseFontSize =
      this.unitTransformConfig.originalBaseFontSize ?? baseFontSize.LobbyH5
    const targetBaseFontSize =
      this.unitTransformConfig.targetBaseFontSize ?? baseFontSize.LobbyWeb
    const step = (node: Node) => {
      const htmlNode = node as HTMLElement
      if (htmlNode.getAttribute) {
        const styleValue = htmlNode.getAttribute('style')
        if (styleValue) {
          htmlNode.setAttribute(
            'style',
            styleValue.replace(/(\d+)px/g, (match) => {
              const pxValue =
                Number(parseFloat(match)) /
                (originalBaseFontSize / targetBaseFontSize)
              return `${pxValue / this.baseUnit}${this.unit}`
            })
          )
        }
      }

      for (const child of node.childNodes) {
        step(child)
      }
    }
    step($div)
    return $div.innerHTML
  }

  @Emit('load')
  private onLoad() {
    if (this.isSetATargetTop) {
      this.iframeRef.contentDocument?.body
        .querySelectorAll('a')
        .forEach((a) => {
          if (!a.target || a.target === '_self') {
            a.target = '_top'
          }
          // 使用 windowOpen 打开
          if (this.useWindowOpen) {
            a.addEventListener('click', (event) => {
              // 阻止默认的跳转行为
              event.preventDefault()
              // 使用我们的跳转逻辑
              const url = a.getAttribute('href')

              if (url) {
                windowOpen(url)
              }
            })
          }
        })

      // 兼容UC浏览器下,内容排版异常的问题,该修复方式有一定的风险,但暂时没有更好的方法,所以,先测试
      if (/UCBrowser/.test(navigator.userAgent)) {
        const contentRoot = this.iframeRef.contentDocument?.body.querySelector(
          '.person_looksubcont'
        )
        if (contentRoot) {
          const list = contentRoot.querySelectorAll('div,p') as NodeListOf<
            HTMLDivElement | HTMLParagraphElement
          >
          list.forEach((dom) => {
            dom.style.boxSizing = 'border-box'
            dom.style.width = '100%'
            dom.style.float = 'right'
            dom.style.clear = 'both'
          })
          const clearDiv = document.createElement('div')
          clearDiv.style.clear = 'both'
          contentRoot.appendChild(clearDiv)
        }
        // const footDiv =
        //   this.iframeRef.contentDocument?.body.querySelector('#person_foot')
        // if (footDiv) {
        //   footDiv.remove()
        // }
      }

      if (getCurrentDevice().ios()) {
        const metaTag = document.createElement('meta')
        // 设置 meta 设置不检测手机号
        metaTag.setAttribute('name', 'format-detection')
        metaTag.setAttribute('content', 'telephone=no')
        this.iframeRef.contentDocument?.head.appendChild(metaTag)
      }
    }
    //高度的计算,放在最后,因为前面还要改css样式
    this.iframeHeight =
      Math.ceil(
        (this.iframeRef.contentDocument?.body.getBoundingClientRect().height ||
          0) / this.baseUnit
      ) + this.unit
  }

  private get iframeMode() {
    return ['iframeDoc', 'iframeSrc'].includes(this.mode)
  }

  private get innerHtmlContent() {
    const styleContent = this.iframeStyle(createBaseStyle())
    const iframeStyle =
      this.mode === 'iframeDoc' && styleContent
        ? `<style>
    ${this.iframeStyle(createBaseStyle())}
    }</style>`
        : ''
    const htmlContent = this.transformUnit(this.html || '')
    return `${String(iframeStyle + (htmlContent || ''))}`
  }

  renderByIframe() {
    if (!this.iframeMode) return
    return (
      <iframe
        ref={'iframeRef'}
        key={this.mode === 'iframeSrc' ? this.iframeSrc : undefined}
        style={{
          border: 'none',
          width: '100%',
          height: this.iframeHeight
        }}
        src={this.mode === 'iframeSrc' ? this.iframeSrc : undefined}
        srcdoc={this.mode === 'iframeDoc' ? this.innerHtmlContent : undefined}
        frameborder="0"
        scrolling={this.scrolling}
        onLoad={this.onLoad}
      />
    )
  }

  render() {
    const targetBaseFontSize =
      this.unitTransformConfig.targetBaseFontSize ?? baseFontSize.LobbyWeb
    return (
      <div
        class={style.main}
        style={
          this.mode === 'scale'
            ? {
                width: `${
                  (this.designSize.width * this.scale) / this.baseUnit
                }${this.unit}`,
                height: `${
                  (this.designSize.height * this.scale) / this.baseUnit
                }${this.unit}`
              }
            : {}
        }
      >
        <div
          class={style.scale}
          style={{
            ...(this.mode === 'scale'
              ? {
                  width: `${this.designSize.width / this.baseUnit}${this.unit}`,
                  height: `${this.designSize.height / this.baseUnit}${
                    this.unit
                  }`,
                  transform: `scale(${this.scale})`
                }
              : {}),
            ...this.scaleStyle
          }}
        >
          {this.$slots.default || [
            this.iframeMode && this.renderByIframe(),
            this.mode === 'innerHTML' && (
              <inner-html
                class={style.innerHTMLContainer}
                style={{
                  fontSize: `${targetBaseFontSize / this.baseUnit}${this.unit}`
                }}
                type={'div'}
                text={this.innerHtmlContent}
              />
            )
          ]}
        </div>
      </div>
    )
  }
}
