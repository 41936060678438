import { Base, Component, Prop } from '@/vue-property-decorator'
import { RegCurrency, RewardBindData, RewardBindType } from '@/api/common/type'
import { brazilianCurrencyFormatter } from '@/utils/business-utils/finance'
import { number } from 'vue-types'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import AppTypes from '@/vue-types'
import TooltipsComp from '../index'
type Props = {
  rewardId: RewardBindType
  /**
   * 控制是否取默认的国际化翻译key，false时用默认的语句，true时用传入的id拼接key
   */
  selfKey?: boolean
  top?: string
  right?: string
  left?: string
}
@Component<RewardTips>({ name: 'RewardTips' })
export default class RewardTips extends Base<unknown, Props> {
  @Prop(number<RewardBindType>())
  rewardId!: Props['rewardId']

  @Prop(AppTypes.looseBool.def(false))
  selfKey!: Props['selfKey']
  /**
   * 相对父元素顶部定位
   */
  @Prop()
  top!: Props['top']

  /**
   * 相对父元素右侧定位
   */
  @Prop()
  right!: Props['right']

  /**
   * 相对父元素左侧定位
   */
  @Prop()
  left!: Props['left']

  /***
   * 语言 注册时根据选中的国家来显示送的金额
   */
  @Prop()
  lang!: Props['left']
  private get currentRewardBindData(): RewardBindData | undefined {
    const { rewardBindData } = useMainStore()
    // 若为手机和验证码
    if (this.rewardId === RewardBindType.BindPhoneAndSMSValid) {
      const phoneAndSmsList = [
        RewardBindType.BindPhone,
        RewardBindType.SMSValid
      ]
      /**
       * 如果绑定手机未开启，则没有气泡
       */
      if (
        !rewardBindData.find(
          (it) => it.bindType === RewardBindType.BindPhone && it.isPop
        )
      ) {
        return undefined
      }

      let reward = 0
      rewardBindData.forEach((it) => {
        if (phoneAndSmsList.includes(it.bindType) && it.isPop) {
          reward += Number(it.reward)
        }
      })
      return {
        isPop: 1,
        bindType: RewardBindType.BindPhoneAndSMSValid,
        reward,
        bindTypeText: ''
      }
    }
    return rewardBindData.find((it) => it.bindType === this.rewardId)
  }

  render() {
    const { t } = useI18n()
    let reward: number | string
    //获取注册奖励气泡时，从currency找对应语言的奖励金额，注册表单里切换币种时这里也切换对应币种的奖励
    if (this.lang && this.currentRewardBindData?.currency) {
      try {
        const currency: Array<RegCurrency> = JSON.parse(
          this.currentRewardBindData.currency
        )
        const rewardItem = currency.find((item) => item.currency == this.lang)
        reward = rewardItem?.amount || 0
      } catch (e) {
        reward = this.currentRewardBindData?.reward || 0
      }
    } else {
      reward = this.currentRewardBindData?.reward || 0
    }

    const text = () =>
      t(
        this.selfKey
          ? `lobby.modal.task.reward.${this.currentRewardBindData?.bindType}`
          : 'lobby.modal.task.reward.default',
        {
          reward: brazilianCurrencyFormatter(reward ?? 0)
        }
      )

    return this.currentRewardBindData && this.currentRewardBindData.isPop ? (
      <TooltipsComp
        charge={reward}
        top={this.top}
        right={this.right}
        left={this.left}
        // 和美术确认,每个皮肤都是金色,写死
        text={<span style={{ color: '#ffff00' }}>{text()}</span>}
      />
    ) : (
      <template></template>
    )
  }
}
