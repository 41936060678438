import { ApiNetstatPlayerAdInfoPayload } from './type'
import { GlobalService } from '@/context'
export const apiNetstatPlayerAdInfo = (
  payload: ApiNetstatPlayerAdInfoPayload
) =>
  GlobalService.post({
    url: '/v1/netstat/player/adInfo',
    customParams: {
      silentOnError: true,
      noErrorMessage: true,
      noEncrypt: true,
      noResponseDataCodeChecked: true
    },
    data: payload
  })
