import { Base, Component, Prop, Ref } from '@/vue-property-decorator'
import { VNode } from 'vue'
import { merge } from 'lodash'
import AppTypes from '@/vue-types'
import BusinessUtils from '@/utils/business-utils'
import VueI18n from 'vue-i18n'
import style from './style.module.scss'
export type Props = {
  charge: number | string
  isGoldStyle?: boolean
  top?: string
  right?: string
  left?: string
  text?: string | VNode | VueTsxSupport.JSX.Element | VueI18n.TranslateResult
  customStyle?: StyleValue
  autoRevert?: boolean
}
@Component<TooltipsComp>({ name: 'TooltipsComp' })
export default class TooltipsComp extends Base<unknown, Props> {
  /**
   * 优惠价格
   */
  @Prop({ required: true })
  charge!: Props['charge']

  /**
   * 优惠文本
   */
  @Prop({ required: true })
  text!: Props['text']

  @Prop(AppTypes.bool.def(false))
  isGoldStyle!: boolean
  /**
   * 相对父元素顶部定位
   */
  @Prop(AppTypes.string.def(BusinessUtils.px2rem('-22px')))
  top!: Props['top']

  /**
   * 相对父元素右侧定位
   */
  @Prop(AppTypes.string.def(BusinessUtils.px2rem('0px')))
  right!: Props['right']

  /**
   * 相对父元素左侧定位
   */
  @Prop(AppTypes.string.def('unset'))
  left!: Props['left']

  /**
   * style
   */
  @Prop(AppTypes.style.def({}))
  customStyle!: Props['customStyle']

  /**
   * 自动翻转
   */
  @Prop(AppTypes.bool.def(false))
  autoRevert!: Props['autoRevert']

  @Ref()
  tooltipsRef?: HTMLDivElement

  /**
   * 是否要反转
   */
  isRevert = false

  mounted() {
    if (this.autoRevert) {
      const rect = this.tooltipsRef?.getBoundingClientRect()
      if ((rect?.right ?? 0) >= window.screen.width) {
        this.isRevert = true
      }
    }
  }

  render() {
    if (this.charge === 0 || this.charge === '0' || !this.charge) {
      // 此处必须返回空内容,如果返回正常的元素会占位,会对其它布局产生影响
      return <template></template>
    }
    return (
      <div
        class={[style.layer, 'tool-tips']}
        ref={'tooltipsRef'}
        style={merge(
          {
            '--cu-top': this.top,
            '--cu-right': this.isRevert ? this.left : this.right,
            '--cu-left': this.isRevert ? this.right : this.left
          },
          this.customStyle
        )}
      >
        <p
          class={{
            'tool-tips-box': true,
            [style.box]: true,
            [style.boxRevert]: this.isRevert,
            [style.goldText]: this.isGoldStyle
          }}
        >
          {this.text ?? this.charge}
        </p>
        <p
          class={{
            'tool-tips-tail': true,
            [style.tail]: true,
            [style.tailRevert]: this.isRevert
          }}
        ></p>
      </div>
    )
  }
}
