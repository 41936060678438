import { Spin as AntSpin } from 'ant-design-vue'
import { AxiosError } from 'axios'
import { Base, Component, Prop } from '@/vue-property-decorator'
import { ExtractClassProps } from '@/plugins/ant-design-vue/type'
import { Spin } from '@/plugins/ant-design-vue/tsx-support'
import { VNode } from 'vue'
import { ofType } from '@/vue-tsx-support'
import { useMainStore } from '@/store/index'
import AppTypes from '@/vue-types'
import BusinessUtils from '@/utils/business-utils'
import PageRetry, { DisplayType } from '../net-retry/index'
import style from './style.module.scss'

type Props = {
  hiddenIcon?: boolean
  customIndicator?: () => VNode
  /**是否需要loading状态下的背景蒙版 */
  needLoadingBackgroundMask?: boolean
  spinning?: boolean
  errorPageHeight?: string
  error?: boolean
  axiosError?: AxiosError | Error | null
  /** 需要展示刷新重试的错误类型，不传时所有错误类型都展示，多种错误类型可逗号分隔 */
  errorType?: 'netError' | 'dataError' | 'timeoutError'
}

type Events = {
  onRetry: () => void
}

@Component<CommonLoading>({
  name: 'CommonLoading',
  components: (() => ({
    L1: () => import(`./components/L1`),
    L2: () => import(`./components/L2`),
    L3: () => import(`./components/L3`),
    L4: () => import(`./components/L4`),
    L5: () => import(`./components/L5`),
    L6: () => import(`./components/L6`),
    L7: () => import(`./components/L7`),
    L8: () => import(`./components/L8`),
    L9: () => import(`./components/L9`),
    L10: () => import(`./components/L10`),
    L11: () => import(`./components/L11`),
    L12: () => import(`./components/L12`),
    L13: () => import(`./components/L13`),
    L14: () => import(`./components/L14`),
    L15: () => import(`./components/L15`),
    L16: () => import(`./components/L16`),
    L17: () => import(`./components/L17`),
    L18: () => import(`./components/L18`),
    L19: () => import(`./components/L19`),
    L20: () => import(`./components/L20`),
    L21: () => import(`./components/L21`),
    L22: () => import(`./components/L22`),
    L23: () => import(`./components/L23`),
    L24: () => import(`./components/L24`),
    L25: () => import(`./components/L25`),
    L26: () => import(`./components/L26`),
    L27: () => import(`./components/L27`)
  }))()
})
class CommonLoading extends Base<unknown, Props, Events> {
  @Prop(AppTypes.bool.def(false))
  private hiddenIcon!: Props['hiddenIcon']

  @Prop({ type: Object })
  private customIndicator!: Props['customIndicator']

  /**是否需要loading状态下的背景蒙版 */
  @Prop({ required: false, default: false })
  needLoadingBackgroundMask?: boolean

  @Prop(AppTypes.bool.def(false))
  private error!: Props['error']

  /**
   * 占位高度
   */
  @Prop({ default: BusinessUtils.px2rem('500px') })
  errorPageHeight!: Props['errorPageHeight']

  /**
   * 错误json
   */
  @Prop()
  axiosError?: Props['axiosError']

  /**
   * 错误类型
   */
  @Prop()
  errorType?: Props['errorType']

  private get defaultIndicator() {
    const { homeLayoutInfos } = useMainStore()
    return (
      <my-img
        src={
          homeLayoutInfos?.commonConfig?.loadingImage ||
          '/lobby_asset/common/common/animated/loading_1_default.gif'
        }
        width={100}
        height={100}
      />
    )
  }

  private get configIndicator() {
    const { homeLayoutInfos } = useMainStore()
    if ((homeLayoutInfos?.commonConfig?.loadingType ?? '0') !== '0') {
      // 不是自定义loading
      return this.$createElement(
        `L${homeLayoutInfos?.commonConfig.loadingType}`
      )
    } else {
      return null
    }
  }

  private get loadingWrapClasses() {
    return `${
      this.hiddenIcon || this.error || this.axiosError ? style.hiddenIcon : ''
    }`
  }

  private renderContent() {
    if (this.error || this.axiosError) {
      return (
        <PageRetry
          displayType={DisplayType.ListData}
          loading={!!this.$attrs.spinning}
          isTimeoutError={this.error}
          onRetry={() => this.$emit('retry')}
          height={this.errorPageHeight}
          axiosError={this.axiosError}
          errorType={this.errorType}
        />
      )
    }

    return this.$slots?.default
  }

  render() {
    return (
      <Spin.Tsx
        indicator={
          this.customIndicator || this.configIndicator || this.defaultIndicator
        }
        class={[
          style.loadingWrap,
          this.loadingWrapClasses,
          this.needLoadingBackgroundMask ? style.needLoadingBackgroundMask : '',
          'custom-loading__wrap'
        ]}
        spinning={false}
        {...{
          props: {
            spinning: false,
            ...this.$attrs
          }
        }}
      >
        {this.renderContent()}
      </Spin.Tsx>
    )
  }
}
export default ofType<ExtractClassProps<AntSpin>>().convert(CommonLoading)
