import { AuditModeType, UserInfos } from '@/api/common/type'
import { Base, Component, Prop, Ref, Watch } from '@/vue-property-decorator'
import { Bind, Debounce } from '@/utils/Decorator'
import { Currency } from '@/config/currencies.config'
import { FinanceWithdrawTabsEnum } from '@/views/finance/withdraw/const'
import { GlobalConst } from '@/context'
import { Icon } from '@/plugins/ant-design-vue/tsx-support'
import { PngSprite } from '@/components/icon-sprite'
import { RouteName } from '@/router/config'
import { apiFinanceGetPayMaxCharge } from '@/api/finance'
import {
  assetsPath,
  createPngSprite,
  createSvgSprite
} from '@/utils/business-utils/assets'
import { currencyFormat } from '@/utils/business-utils/finance'
import { navigateToWithdrawPage, openPayModal } from '@/controller/navigation'
import { random } from '@/utils/string'
import { useGameStore } from '@/views/game/store'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import AppTypes from '@/vue-types'
import BusinessUtils from '@/utils/business-utils'
import Skin from '@/controller/Skin'
import TooltipsComp from '../tooltips'
import style from './style.module.scss'
import to from 'await-to-js'

type Props = {
  hiddenSymbol?: boolean
  fontSize?: number | `${string}px`
  mode?: 'flex'
  rowAlign?: 'center' | 'start' | 'end' | 'space-between' | 'space-around'
  columnAlign?: 'center' | 'start' | 'end' | 'space-between' | 'space-around'
  noUnderline?: boolean
  /** 前缀图标 */
  prefixArrow?: VueTsxSupport.JSX.Element | (() => VueTsxSupport.JSX.Element)
  /** 刷新icon名 */
  freshSvgName?: string
  /** 刷新icon png名 */
  freshPngName?: string | PngSprite
  /** 刷新icon png 缩放宽度  */
  freshPngScale?: number
  /** popover 内容 距离 currencyInfoBox 容器的 y轴和x值 */
  offset?: [number, number]
  reverseArrowIcon?: boolean
  /** 隐藏 popover */
  hiddenPopover?: boolean
  /** 是否是右对齐，默认否，左对齐 */
  alignRight?: boolean
  /** 触发popover展示的icon */
  popoverSvgIcon?: string
  /** 触发器是否隐藏border 默认有border */
  hiddenBorder?: boolean
}
export interface Events {
  onIconClick: string
}

@Component<CurrencyInfos>({
  name: 'CurrencyInfos'
})
export default class CurrencyInfos extends Base<unknown, Props, Events> {
  @Ref()
  private readonly popoverContentRef!: HTMLDivElement

  @Ref()
  private readonly countToRef!: HTMLDivElement

  @Prop(AppTypes.bool.def(false))
  hiddenSymbol!: Props['hiddenSymbol']

  @Prop(AppTypes.string.def('flex'))
  mode!: Props['mode']

  @Prop(AppTypes.string.def('center'))
  rowAlign!: Props['rowAlign']

  @Prop(AppTypes.string.def('center'))
  columnAlign!: Props['columnAlign']

  @Prop(AppTypes.string.def(`18px`))
  fontSize!: Props['fontSize']

  @Prop(AppTypes.bool.def(false))
  noUnderline?: Props['noUnderline']

  @Prop({ default: null })
  readonly prefixArrow?: Props['prefixArrow']

  @Prop(AppTypes.string.def('comm_icon_sx'))
  freshSvgName!: Props['freshSvgName']

  @Prop(AppTypes.string.def(''))
  freshPngName!: Props['freshPngName']

  @Prop({ required: false, default: 24 })
  private freshPngScale!: Props['freshPngScale']

  @Prop(AppTypes.array.def([54, -350]))
  offset!: Props['offset']

  @Prop(AppTypes.bool.def(false))
  reverseArrowIcon!: Props['reverseArrowIcon']

  @Prop(AppTypes.bool.def(false))
  hiddenPopover?: Props['hiddenPopover']

  @Prop(AppTypes.bool.def(false))
  alignRight!: Props['alignRight']

  @Prop(AppTypes.string.def('comm_icon_sort'))
  popoverSvgIcon!: Props['popoverSvgIcon']

  @Prop(AppTypes.bool.def(false))
  hiddenBorder!: Props['hiddenBorder']

  private showBalance = true

  // id 不能写死，因为可能会存在多个Popover共存的情况
  private containerId = 'currency-popover-container-' + random()

  private contentVisible = false

  private distanceToLeft = 0
  private distanceToRight = 0
  private distanceToTop = 0
  private payMaxCharge = 0

  @Bind()
  @Debounce()
  private async updateCurrency() {
    this.$emit('iconClick')
    if (this.$route.name === RouteName.MINE) {
      useMainStore().updatePrize()
    } else {
      useMainStore().logoutAndUpdatePrize(true)
    }
  }

  @Bind()
  private goCenterWallet() {
    this.$router.push({
      name: RouteName.CENTER_WALLET
    })
  }

  @Watch('contentVisible')
  protected onPopoverChange() {
    if (!this.contentVisible) {
      return
    }
    this.getDistanceToTopLeft()
    this.initializeData()
  }

  /** 获取充值气泡数据 */
  private async initializeData() {
    const [err, data] = await to(apiFinanceGetPayMaxCharge())
    if (!err) {
      this.payMaxCharge = data?.charge_rate || 0
    }
  }

  encryptString() {
    return '******'
  }

  private currencySwitchIcon(scale: number, icon: PngSprite) {
    if (this.prefixArrow) {
      return typeof this.prefixArrow === 'function'
        ? this.prefixArrow()
        : this.prefixArrow
    } else {
      const { isHK } = Skin.currentTheme()
      const classes = [
        style.currencySwitch,
        isHK ? style.hkCurrency : 'currency-switch'
      ]
      const imgSrc = isHK
        ? assetsPath(
            `/lobby_asset/{layout}-{bg}-common/common/_sprite/img_hb_wallet.png`
          )
        : '/lobby_asset/common/common/_sprite/img_hb_frame.png'

      return (
        <my-img
          class={classes}
          src={imgSrc}
          options={{ type: 'div' }}
          useSkeleton
        >
          {!isHK && <icon-sprite scale={scale} sprite={icon} useSkeleton />}
        </my-img>
      )
    }
  }

  goToAuditList() {
    this.$router.push({
      name: RouteName.WITHDRAW,
      query: {
        current: `${FinanceWithdrawTabsEnum.HEAVEN_RECORD}`
      }
    })
  }

  getDistanceToTopLeft() {
    const element = document.getElementsByClassName(
      'iconSort'
    )[0] as HTMLElement

    if (element && this.offset && this.offset.length >= 2) {
      const rect = element.getBoundingClientRect()

      if (this.alignRight) {
        this.distanceToRight = 0
      } else {
        this.distanceToLeft = element.offsetLeft + rect.width / 2
      }

      this.distanceToTop = this.offset?.[0] - 11
    }
  }

  /** 是否是模式1 提现必须稽核 */
  get curAuditModeIsModeOne() {
    const { curAuditMode } = useMainStore()
    return curAuditMode === AuditModeType.MUST_AUDIT
  }

  toggleContentVisibility() {
    this.contentVisible = !this.contentVisible
  }

  popoverRender() {
    const { t } = useI18n()
    const { userInfos, isFetchingPrize, language } = useMainStore()

    let injectStyle = {}
    // 右对齐，采用top 和 right
    // 左对齐 采用 top 和left
    if (this.alignRight) {
      injectStyle = {
        '--content-top': BusinessUtils.px2rem(Number(this.offset?.[0])),
        '--content-right': BusinessUtils.px2rem(Number(this.offset?.[1]))
      }
    } else {
      injectStyle = {
        '--content-top': BusinessUtils.px2rem(Number(this.offset?.[0])),
        '--content-left': BusinessUtils.px2rem(Number(this.offset?.[1]))
      }
    }

    return (
      <div
        id={this.containerId}
        class={style.popoverContentBox}
        style={injectStyle}
        ref="popoverContentRef"
      >
        <div class={[style.popoverContent, 'currency-popbox']}>
          <section class={style.top}>
            <div class={style.flexSB}>
              <p>
                {t('lobby.balance.current')}
                <span class={style.balance}>
                  {this.showBalance
                    ? currencyFormat(userInfos?.game_gold ?? 0, { symbol: '' })
                    : this.encryptString()}
                </span>
                <icon-sprite
                  class={[
                    style.refreshIcon,
                    style.icon,
                    [
                      {
                        [GlobalConst.CssClassName.Animate.Animated]:
                          isFetchingPrize
                      },
                      GlobalConst.CssClassName.Animate.Spin,
                      GlobalConst.CssClassName.Animate.Repeat.infinite
                    ]
                  ]}
                  useSkeleton
                  sprite={createSvgSprite('comm_icon_sx')}
                  nativeOn={{
                    click: () => {
                      // 显示金额 + 触发刷新
                      this.showBalance = true
                      this.updateCurrency()
                    }
                  }}
                />
              </p>
              <icon-sprite
                class={[
                  style.showOrHideIcon,
                  style.icon,
                  !this.showBalance ? style.hidden : ''
                ]}
                useSkeleton
                nativeOn={{
                  click: () => {
                    this.showBalance = !this.showBalance
                    setTimeout(() => {
                      this.getDistanceToTopLeft()
                    }, 300)
                  }
                }}
                sprite={createSvgSprite(
                  this.showBalance ? 'comm_icon_show' : 'comm_icon_hide'
                )}
              />
            </div>
            <div>
              {t('lobby.balance.text1')}
              <span
                class={[style.icon, style.findBalance]}
                onClick={this.goCenterWallet}
              >
                {t('lobby.balance.find')}
              </span>
            </div>
          </section>

          <section class={style.middle}>
            <div>
              <p>
                {t('lobby.balance.award')}
                <span class={style.balance}>
                  {this.showBalance
                    ? currencyFormat(userInfos?.bonus ?? 0, { symbol: '' })
                    : this.encryptString()}
                </span>
                <icon-sprite
                  class={[style.audioIcon, style.icon]}
                  useSkeleton
                  sprite={createSvgSprite('comm_icon_jl')}
                  nativeOn={{
                    click: () => this.goToAuditList()
                  }}
                />
              </p>
            </div>
            <div>
              {t('lobby.balance.text2')}
              <span class={style.bold}>
                {currencyFormat(userInfos?.bonusRequireBet ?? 0, {
                  symbol: ''
                })}
              </span>
              {t('lobby.balance.text3')}
              <span class={style.icon} onClick={() => this.goToAuditList()}>
                {t('lobby.balance.checkRule')}
              </span>
            </div>
          </section>

          <section class={style.footer}>
            <div
              class={style.withDrawBtn}
              onClick={() => navigateToWithdrawPage()}
            >
              {t('lobby.header.withdraw')}
            </div>
            <div
              class={style.payBtn}
              onClick={() => {
                openPayModal()
              }}
            >
              <TooltipsComp
                charge={1}
                top={BusinessUtils.px2rem(-34)}
                right={BusinessUtils.px2rem(0)}
                hidden={!this.payMaxCharge}
                text={
                  <span class={style.badgeText}>
                    {language === 'zh_CN'
                      ? t('lobby.modal.pay.give2', {
                          num: this.payMaxCharge
                        })
                      : `${this.payMaxCharge}%`}
                  </span>
                }
              />

              {t('lobby.header.recharge')}
            </div>
          </section>
        </div>
      </div>
    )
  }

  render() {
    const { t } = useI18n()
    const { isGamePlaying, isGameScoring } = useGameStore()
    const { userInfos, isFetchingPrize } = useMainStore()
    const { isStake } = Skin.currentTheme()
    const currencyIconMap = Currency.registerIconMap()
    const { game_gold = 0, bonus = '0' } = userInfos as UserInfos
    const icon =
      currencyIconMap[
        (userInfos?.currency ||
          'CNY') as unknown as keyof typeof currencyIconMap
      ].icon
    const scale = (isStake ? 26 : 30) / icon.width

    const refreshPngIcon = this.freshPngName
      ? createPngSprite(`sprite_main_${this.freshPngName}`)
      : createSvgSprite('comm_icon_sx')

    let refreshPngScale: number
    if (this.freshPngName) {
      refreshPngScale =
        (this.freshPngScale ?? 24) / (refreshPngIcon as PngSprite)?.width
    }

    let injectArrowStyle = {}
    // 右对齐，采用top 和 right
    // 左对齐 采用 top 和left
    if (this.alignRight) {
      injectArrowStyle = {
        '--arrow-top': BusinessUtils.px2rem(Number(this.distanceToTop)),
        '--arrow-right': `${this.distanceToRight}px`
      }
    } else {
      injectArrowStyle = {
        '--arrow-top': BusinessUtils.px2rem(Number(this.distanceToTop)),
        '--arrow-left': `${this.distanceToLeft}px`
      }
    }

    return (
      <div
        class={[
          style.currencyInfoBox,
          this.hiddenSymbol ? style.noSymbolmain : '',
          this.hiddenBorder ? '' : style.border,
          'currencyInfoBox',
          'main'
        ]}
        style={{
          '--row-align': this.rowAlign,
          '--mode': this.mode,
          '--column-align': this.columnAlign
        }}
      >
        {!this.hiddenSymbol && this.currencySwitchIcon(scale, icon)}
        <section
          class={[
            style['currency-count'],
            'currency-count',
            this.reverseArrowIcon ? style.reversedIcon : ''
          ]}
          style={{ 'font-size': BusinessUtils.px2rem(this.fontSize ?? '18px') }}
        >
          {isGamePlaying ? (
            <div class={[style.gaming, 'gaming']}>
              {t('lobby.header.moneyInGame')}
            </div>
          ) : (
            <div class={['countTextBox']}>
              {isGameScoring || (isFetchingPrize && game_gold === 0) ? (
                <span class={style['gaming']}>{t('lobby.game.loadinged')}</span>
              ) : (
                <span
                  class={[
                    style['count-to'],
                    this.noUnderline ? style.noUnderline : '',
                    'count-to'
                  ]}
                  ref="countToRef"
                  onClick={() => this.toggleContentVisibility()}
                >
                  {this.showBalance ? (
                    <count-to
                      value={(game_gold + Number(bonus)).toFixed(2)} // 不管模式几，直接两个相加
                    />
                  ) : (
                    this.encryptString()
                  )}
                </span>
              )}
              {this.curAuditModeIsModeOne || this.hiddenPopover ? (
                <Icon.Tsx
                  onClick={this.updateCurrency}
                  class={[
                    style['refresh-icon'],
                    [
                      {
                        [GlobalConst.CssClassName.Animate.Animated]:
                          isFetchingPrize
                      },
                      GlobalConst.CssClassName.Animate.Spin,
                      GlobalConst.CssClassName.Animate.Repeat.infinite
                    ],
                    'refresh-icon'
                  ]}
                  component={{
                    functional: true,
                    render: () => {
                      return (
                        <icon-sprite
                          scale={refreshPngScale}
                          sprite={
                            this.freshPngName
                              ? createPngSprite(
                                  `sprite_main_${this.freshPngName}`
                                )
                              : createSvgSprite(this.freshSvgName!)
                          }
                        />
                      )
                    }
                  }}
                />
              ) : (
                <Icon.Tsx
                  onClick={() => this.toggleContentVisibility()}
                  class={[
                    style.iconSort,
                    this.contentVisible ? style.iconActive : '',
                    this.contentVisible ? 'iconActive' : '',
                    'iconSort'
                  ]}
                  component={{
                    functional: true,
                    render: () => {
                      return (
                        <icon-sprite
                          sprite={createSvgSprite(this.popoverSvgIcon!)}
                        />
                      )
                    }
                  }}
                  {...{
                    directives: [
                      {
                        name: 'click-outside',
                        value: {
                          capture: true,
                          handler: (e: Event) => {
                            const isContainers = (
                              element: Element | null,
                              target: HTMLElement
                            ) => {
                              return (
                                element &&
                                (element.contains(target) || element === target)
                              )
                            }

                            const _target = e.target as HTMLElement

                            if (
                              isContainers(this.popoverContentRef, _target) ||
                              isContainers(this.countToRef, _target)
                            ) {
                              return
                            }
                            this.contentVisible = false
                          }
                        }
                      }
                    ]
                  }}
                />
              )}
            </div>
          )}
        </section>
        {this.contentVisible && (
          <fragment>
            <div
              class={[style.triangle, 'currency-triangle']}
              style={injectArrowStyle}
            />
            {this.popoverRender()}
          </fragment>
        )}
      </div>
    )
  }
}
