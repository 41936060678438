import {
  OsType,
  SecondAuthType,
  SecondVerifyType,
  ThirdWayRequesterType
} from '@/api/common/type'

export enum SecurityVerifyModifyType {
  NONE = 0,
  /**
   * 登入密码
   */
  LOGIN_PASS = 1,
  /**
   * 密保操作(返回密保问题)
   */
  QUESTIONS = 2,
  /**
   * 开启谷歌验证(返回用户是否有Key或谷歌QRCode)
   */
  GA = 3,
  /**
   * 手机
   */
  PHONE = 4,
  /**
   * 邮箱
   */
  EMAIL = 5,
  /**
   * 提现
   */
  WITHDRAW_PASS = 6,

  /**
   * 第三方帐号绑定(待补充)
   */
  THIRD_BIND = 8
}

export enum SecurityActionVerifyType {
  /**
   * 登入密码
   */
  PASSWORD = 6,
  /**
   * 提现密码
   */
  WITHDRAW = 5,
  /**
   * 密保
   */
  QUESTIONS = 4
}
/**
 *  第一步校验的入参
 */
export type SecurityActionVerifyReq<T extends SecondAuthType = SecondAuthType> =
  {
    type: T
    info_type: SecurityVerifyModifyType // 下一步绑定的类型
    pass_question_id?: number | null
    pass_question_answer?: string
    withdraw_pass?: string
    login_pass?: string
    googleCode?: string
    google_code?: string
    phone?: string
    phoneCode?: string
    phone_code?: string
    email?: string
    emailCode?: string
    email_code?: string
  }

export type SecurityActionVerifyParams<
  T extends SecondAuthType = SecondAuthType
> = {
  info_type: SecurityVerifyModifyType // 下一步绑定的类型
  firstVerify: {
    type: T
    pass_question_id?: number | null
    pass_question_answer?: string
    withdraw_pass?: string
    login_pass?: string
    googleCode?: string
    phone?: string
    phoneCode?: string
    email?: string
    emailCode?: string
  }
}
export type SecurityActionVerifyRes<T extends SecondAuthType = SecondAuthType> =
  {
    type: string
    info: T extends SecurityVerifyModifyType.LOGIN_PASS
      ? Record<string, never>
      : T extends SecurityVerifyModifyType.QUESTIONS
      ? {
          current: number
          question: Array<{ id: number; question: string }>
        }
      : T extends SecurityVerifyModifyType.GA
      ? {
          hasKey: boolean
          image: string
          key: string
        }
      : T extends SecurityVerifyModifyType.THIRD_BIND
      ? Record<string, never>
      : never
  }

export type SecurityVerifyModifyReq<T extends SecurityVerifyModifyType> = {
  login_pass?: string
  type: T
}
export type SecurityVerifyModifyRes<T extends SecurityVerifyModifyType> = {
  type: string
  info: T extends SecurityVerifyModifyType.LOGIN_PASS
    ? Record<string, never>
    : T extends SecurityVerifyModifyType.QUESTIONS
    ? {
        current: number
        question: Array<{ id: number; question: string }>
      }
    : T extends SecurityVerifyModifyType.GA
    ? {
        hasKey: boolean
        image: string
        key: string
      }
    : T extends SecurityVerifyModifyType.THIRD_BIND
    ? Record<string, never>
    : never
}

export type SecurityModifyPhone<T extends SecondAuthType = SecondAuthType> = {
  phone: string
  phone_code?: string
  second_verify?: SecondVerifyType<T>
  /** 0修改手机 1验证手机 */
  action?: 0 | 1
  phoneCode?: string
}

export type SecurityBindCpf<T extends SecondAuthType = SecondAuthType> = {
  cpf_code: string
  second_verify?: SecondVerifyType<T>
}

export type SecurityModifyEmail<T extends SecondAuthType = SecondAuthType> = {
  email: string
  code: string
  second_verify?: SecondVerifyType<T>
  /** 0修改邮箱 1验证邮箱 */
  action?: 0 | 1
}

export type SecurityModifyLoginPwd<T extends SecondAuthType = SecondAuthType> =
  {
    oldpass: string
    newpass: string
    second_verify?: SecondVerifyType<T>
  }

export type SecurityModifyWithdrawPwd<
  T extends SecondAuthType = SecondAuthType
> = {
  withdraw_pass: string
  oldWithdrawPasswd?: string
  second_verify?: SecondVerifyType<T>
}

export type SecurityModifyQuestion<T extends SecondAuthType = SecondAuthType> =
  {
    answer: string
    id: number
    second_verify?: SecondVerifyType<T>
  }

export type SecurityBindGA<T extends SecondAuthType = SecondAuthType> = {
  code: string
  second_verify?: SecondVerifyType<T>
}

export type SecuritySwitchGA<T extends SecondAuthType = SecondAuthType> = {
  switch: 0 | 1 // 0 为 关闭，1为 开启
  second_verify?: SecondVerifyType<T>
}

export type SecurityThirdWayBind<T extends SecondAuthType = SecondAuthType> = {
  access_token: string
  //操作系统类型： 1 IOS 2 Android 3 h5 4 Web
  os_type?: OsType
  requester_type: ThirdWayRequesterType
  second_verify?: SecondVerifyType<T>
}

export type ThirdBindResponseData = {
  email: string
  thirdEmail: string
  thirdType: number
}

export type SecurityPhoneCode = {
  phone: string
  code?: string
  username: string
}
export type SecurityEmailCode = {
  email: string
  code: string
  username: string
}
export type SecurityGoogleAuthCode = {
  code: string
}

export enum ResetVerifymodifyType {
  /**
   * 谷歌验证码
   */
  GA = 1,
  /**
   * 邮箱
   */
  EMAIL = 2,
  /**
   * 手机
   */
  PHONE = 3,
  /**
   * 密保操作
   */
  QUESTIONS = 4,
  /**
   * 提现
   */
  WITHDRAW_PASS = 5
}

export type ResetVerifyType = {
  type: ResetVerifymodifyType
  google_code: string
  email: string
  email_code: string
  phone: string
  phone_code: string
  pass_question_id: number | null
  pass_question_answer: string
  withdraw_pass: string
}
export type loginVerifyType = {
  type: ResetVerifymodifyType
  googleCode: string
  email: string
  emailCode: string
  phone: string
  phoneCode: string
  passQuestionId: number | null
  passQuestionAnswer: string
  withdrawPass: string
  loginPass: string
}
export type loginVerifyPayload = {
  username: string
  secondVerify: loginVerifyType
}
export type ResetPassFirstVerifyPayload = {
  username: string
  firstVerify: ResetVerifyType
}

export type ResetPassSecondVerifyPayload = {
  username: string
  password: string
  second_verify: ResetVerifyType
}

/**
 * 当前用户配置项
 */
export type ValidateConfig = {
  email: string
  phoneNumber: string
  hasQuestion: boolean
  hasWithdrawPwd: boolean
  hasGoogleAuth: boolean
  hasEmail: boolean
  hasPhoneNumber: boolean
}

export enum ValidType {
  WITH_LOGIN = SecondAuthType.LOGIN_PWD,
  WITH_WITHDRAWER = SecondAuthType.WITHDRAW_PWD,
  WITH_QUESTION = SecondAuthType.QUESTION,
  WITH_PHONE = SecondAuthType.PHONE, // 手机3
  WITH_EMAIL = SecondAuthType.EMAIL, // 邮箱2
  WITH_GA = SecondAuthType.GA // 谷歌验证器1
}

/**
 * 验证场景
 */
export enum VerifyScene {
  /**
   * 安全中心
   */
  SECURITY_CENTER = 'security_center',
  /**
   * 忘记密码
   */
  FORGOT_PASSWORD = 'forgot_password'
}
