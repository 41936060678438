import {
  GetEventsParams,
  GetStreamingParams,
  GetSystemParlayDetailsType,
  IParlayTicketsrPayload,
  IParlayTicketsrRes,
  IPlaceBetPayload,
  IPlaceBetRes,
  IPlaceParlayBetPayload,
  IPlaceParlayBetRes,
  ISingleTicketPayload,
  ISingleTicketRes,
  League,
  Market,
  Sport,
  SportsAuth,
  SportsEvents,
  SportsStreaming,
  // queryTime,
  sabMaintenanceStatus
} from './type'
import { GlobalService } from '@/context'
import {
  IBetDetailsPayload,
  IBetDetailsRes,
  ICashoutPricePayload,
  ICashoutPriceRes,
  IGameDetails,
  ISellBackPayload,
  ISellBackRes
} from '@/views/selfoperated-games/sports/views/bet-record/type'
import {
  IGetOutrightsRes,
  IOutrightPayload,
  IOutrightRes,
  IOutrightTicketPayload,
  IOutrightTicketRes
} from '@/views/selfoperated-games/sports/types'
import { SabaLoginErrorCode } from '@/views/selfoperated-games/sports/const'
import { useMainStore } from '@/store/index'
import SprotService from '@/context/sportsService'

/**
 * Odata Query
 * https://github.com/Saba-sports/OddsDirectAPI_CN/wiki/Odata-Query
 */
/**
 * 概述：从go业务拿token，刷新token，拿token 直接调用三方服务的接口
 * https://docs.cg.xxx/web/#/40/11019
 */
export const apiGetSportsToken = () => {
  const { setSabaMaintenance } = useMainStore()
  return GlobalService.post<SportsAuth>({
    url: '/saba/login',
    customParams: {
      noErrorMessage: true,
      cache: true
    }
  })
    .then((res) => {
      setSabaMaintenance(false)
      if (res.data.data) {
        const { token, tokenType, sabaUrl } = res.data.data
        SprotService.setBaseUrlAndTokenApiDomian(
          `${tokenType} ${token}`,
          sabaUrl
        )
      }
    })
    .catch((error) => {
      const data = error.data
      // const message = data?.message ?? data?.msg
      if ([SabaLoginErrorCode, sabMaintenanceStatus].includes(data.errorCode)) {
        setSabaMaintenance(true)
      } else {
        // GlobalManager.Modal.message({
        //   type: 'error',
        //   content: message
        // })
      }
    })
}
/** 刷新token 接口，过期时间内更新token */
export const apiFreshToken = () =>
  GlobalService.post<SportsAuth>({
    url: '/saba/freshToken'
  }).then((res) => {
    if (res.data.data) {
      const { token, tokenType } = res.data.data
      SprotService.setTokenApiDomin(`${tokenType} ${token}`)
    }
  })

/**
 * 获取赛事相关信息包含部分盘口信息
 * https://github.com/Saba-sports/OddsDirectAPI_CN/wiki/GetEvents
 * https://apistaging.wx7777.com/sports/v1/GetEvents?from=2023-06-30&query=$orderby=sporttype desc
 */
export const apiGetEvents = (payload: GetEventsParams) =>
  SprotService.get<SportsEvents>({
    url: '/sports/v1/GetEvents',
    params: payload
  })

/**
 * 获取冠军盘口数据
 * @param payload ?query=xx&from=2023-07-02T12:00:00&util=2023-08-02T12:00:00
 * @returns
 */
export const apiGetOutrights = (payload: GetEventsParams) =>
  SprotService.get<IGetOutrightsRes>({
    url: '/sports/v1/GetOutrights',
    params: payload
  })

/**
 * 冠军盘发起投注前置查询
 * @param payload IOutrightTicketPayload 投注参数
 * @returns respond: IOutrightTicketRes
 */
export const apiGetOutrightTicket = (payload: IOutrightTicketPayload) =>
  SprotService.get<IOutrightTicketRes>({
    url: '/betting/v1/GetOutrightTicket',
    params: {
      ...payload
    }
  })

/**
 * 冠军盘发起投注
 * @param payload IOutrightPayload 投注参数
 * @returns respond: IPlaceBetRes
 */
export const apiPostPlaceOutrightBet = (payload: IOutrightPayload) =>
  SprotService.post<{ respond: IOutrightRes }>({
    url: '/betting/v1/PlaceOutrightBet',
    params: {
      ...payload
    },
    data: {},
    customParams: {
      silentOnError: true
    }
  })

/**
 * 获取头部每个运动项目的赛事数量及串关赛事数量（足球，篮球...）
 * GetSports
 */
export const apiGetGetSports = (payload: GetEventsParams) =>
  SprotService.get<{ sports: Array<Sport> }>({
    url: '/sports/v1/GetSports',
    params: payload
  })

/**
 * 获取联赛类型
 */
export const apiGetLeagues = (payload: GetEventsParams) =>
  SprotService.get<{ leagues: Array<League> }>({
    url: '/sports/v1/GetLeagues',
    params: payload
  })

/**
 * 发起投注前检查注单信息
 * @param payload ISingleTicketPayload 投注参数
 * @returns respond ISingleTicketRes
 */
export const apiGetSingleTicket = (payload: ISingleTicketPayload) =>
  SprotService.get<ISingleTicketRes>({
    url: '/betting/v1/GetSingleTicket',
    params: {
      ...payload
    },
    customParams: {
      silentOnError: true
    }
  })

/**
 * 发起投注
 * @param payload IPlaceBetPayload 投注参数
 * @returns respond: IPlaceBetRes
 */
export const apiPostPlaceBet = (payload: IPlaceBetPayload) =>
  SprotService.post<{ respond: IPlaceBetRes }>({
    url: '/betting/v1/PlaceBet',
    params: {
      ...payload
    },
    customParams: {
      silentOnError: true
    },
    data: {}
  })

/**
 * 发起串关下注前调用，取得串关注单的相关信息
  同场赛事不支援串关，亦即从同个赛事取得market_id不支持。
  最低串关数量可由GetMarkets的combo取得
  * @param payload IParlayTicketsrPayload
  * @returns IParlayTicketsrRes
  */
export const apiPostGetParlayTickets = (payload: IParlayTicketsrPayload) =>
  SprotService.post<{ respond: IParlayTicketsrRes }>({
    url: '/betting/v1/GetParlayTickets',
    data: {
      ...payload
    },
    customParams: { silentOnError: true }
  })

/**
 * 发起串关下注
 * @param payload : IPlaceParlayBetPayload
 * @returns IPlaceParlayBetRes
 */
export const apiPostPlaceParlayBet = (payload: IPlaceParlayBetPayload) =>
  SprotService.post<{ respond: IPlaceParlayBetRes }>({
    url: '/betting/v1/PlaceParlayBet',
    data: payload,
    customParams: { silentOnError: true }
  })

/**
 * 查询注单记录
 * @param payload IBetDetailsPayload 注单记录参数
 * @returns respond IBetDetailsRes
 */
export const apiGetBetDetails = (payload: IBetDetailsPayload) =>
  SprotService.get<IBetDetailsRes[]>({
    url: '/betting/v1/GetBetDetails',
    params: {
      ...payload
    }
  })

/**
 * 获取体育视频链接
 */
export const apiGetStreaming = (payload: GetStreamingParams) =>
  SprotService.get<SportsStreaming>({
    url: '/sports/v1/GetStreaming',
    params: payload,
    customParams: {
      silentOnError: true
    }
  })

/**
 * 获取实时兑现价格相关信息
 * @param payload transIds 使用 "," 区隔注单号码，ex:1111,2222
 * @returns
 */
export const apiGetCashoutPrice = (payload: ICashoutPricePayload) =>
  SprotService.get<ICashoutPriceRes>({
    url: '/cashout/v1/GetCashoutPrice',
    params: payload
  })

export const apiGetGetSystemParlayDetails = (payload: {
  parlayTicketNo: string
}) =>
  SprotService.get<GetSystemParlayDetailsType>({
    url: '/betting/v1/GetSystemParlayDetails',
    params: payload
  })

/**
 * 来兑现注单
 * @param payload ?transId=123456&cashoutPrice=10
 * @returns
 */
export const apiPostSellBack = (payload: ISellBackPayload) => {
  const { transId, cashoutPrice } = payload
  return SprotService.post<ISellBackRes>({
    url: `/cashout/v1/SellBack?transId=${transId}&cashoutPrice=${cashoutPrice}`,
    data: payload,
    customParams: {
      silentOnError: true
    }
  })
}

/**获取兑现票据的状态 */
export const apiCheckSellingStatus = (payload: { transId: string }) =>
  SprotService.get<ISellBackRes>({
    url: '/cashout/v1/CheckSellingStatus',
    params: payload
  })

/**
 * 获取比赛结果
 * @param payload ?eventids=12345,6789
 * @returns
 */
export const apiGetGameDetails = (eventids: string) =>
  SprotService.get<IGameDetails>({
    url: `/betting/v1/GetGameDetails?eventids=${eventids}`,
    params: {}
  })

export const apiGetMarkets = (payload: GetEventsParams) =>
  SprotService.get<{ markets: Market[] }>({
    url: '/sports/v1/GetMarkets',
    params: payload
  })
