import { Bind, Throttle } from '@/utils/Decorator'
import { Component, Mixins, Watch } from '@/vue-property-decorator'
import { ConfigProvider } from 'ant-design-vue'
import {
  GlobalConst,
  GlobalEvents,
  GlobalManager,
  GlobalService
} from '@/context'
import { GlobalEventActionsTypeEnum } from './context/events'
import { Language, useI18n } from '@/i18n'
import { MetaInfo } from 'vue-meta'
import { PortalTarget } from 'portal-vue'
import { RouteName } from './router/config'
import { apiTaskSaveAddress, getSaveAddress } from './api/task'
import { delay } from '@/utils/Tool'
import { loadScripts } from '@/utils/Loader'
import { useMainStore } from '@/store/index'
import type { GlobalEventActions } from './context/events'
// import GlobalFestival from '@/views/global-festival'
import GlobalModal from '@/views/global-modal'
import GlobalStyles from '@/views/global-styles'
import Modal from '@/controller/Modal'
// import OrientationReminder from '@/views/orientation-reminder'
import { ServiceButtonDisplayPage } from './views/service/const'
import { allShowTabBaroutes } from './router'
import { apiGameLogout } from './api/game'
import { apiGetSysInfo, apiLogout, apiTraceReport } from './api/common'
import { createMaskToDrag, setDragToMove } from './utils/element'
import {
  formatMaintainRequestInfo,
  maintainCheckOnStartTime
} from './utils/business-utils'
import { getCurrentDevice } from '@/utils/Device'
import { onFCP, onFID, onLCP, onTTFB } from 'web-vitals'
import { pick } from 'lodash'
import { traceLog } from './context/log'
import { upgrade } from './context/tacker'
import { useEventStore } from './views/event/store'
import { useRedPocketStore } from './views/redPocket/store'
import { useReportStore } from './views/center/report/store'
import { useServiceStore } from './views/service/store'
import { windowConfig } from './utils/window'
import Defer from '@/mixins/defer'
import Netest from './utils/Netest'
import Site from './controller/Site'
import moment from 'moment'
import style from '@/layouts/styles/common.module.scss'
const device = getCurrentDevice()

@Component<App>({
  name: 'App'
})
export default class App extends Mixins(Defer()) {
  get isInitializationCompleted() {
    return useMainStore().isInitializationCompleted
  }
  get isLogin() {
    return useMainStore().hasLogined
  }

  get language() {
    return useMainStore().language
  }

  get currency() {
    return useMainStore().userInfos?.currency
  }

  get gameGold() {
    return useMainStore().userInfos?.game_gold
  }

  /**是h5页面，并且不是在App里面打开 */
  get isH5AndNotApp() {
    const { isWeb } = useMainStore()
    const isH5AndNotApp =
      !isWeb && !windowConfig.speedPackageConfig && !windowConfig.isWgPackage
    return isH5AndNotApp
  }

  dragTime = 0

  @Watch('isInitializationCompleted', { immediate: true })
  @Watch('isLogin', { immediate: true })
  protected async getRewardsFromSaveWPA() {
    const isInStandaloneMode = device.isInStandaloneMode()
    // 需要页面初始化完成
    if (!(this.isInitializationCompleted && this.isLogin)) {
      return
    }
    // 必须是在h5页面下
    if (!this.isH5AndNotApp) {
      return
    }
    // 确保在PWA的方式打开
    if (isInStandaloneMode && !localStorage.getItem('noTaskSaveAddressTip')) {
      const saveUrlUuid = await getSaveAddress()
      const data = await apiTaskSaveAddress({
        address: saveUrlUuid,
        actionType: 2
      })
      if (data) {
        //成功领取了奖励 存个状态 下次启动不再调领取接口
        localStorage.setItem('noTaskSaveAddressTip', 'true')
      }
    }
  }

  @Watch('language')
  @Watch('currency')
  @Watch('isLogin')
  protected onConfigDependenciesChange() {
    /**
     * 避免首次language从初始值变成确定值时触发,初次在主流程后调用即可
     */
    if (useMainStore().isInitializationCompleted) {
      this.$nextTick(() => {
        Site.getConfigDependencies()
      })
    }
  }

  @Watch('gameGold')
  protected onGameGoleChange() {
    Site.triggerForcedBinding()
  }

  @Watch('isLogin', { immediate: true })
  private removeRedPocketCloseList(now: boolean, old: boolean) {
    if (now && old === false) {
      useRedPocketStore().loginAfterResetRedPocketCloseList()
    }
  }

  /**
   * 动态注入 title 和 icon。
   */
  metaInfo(): MetaInfo {
    const { siteConfig, siteInfos, brandLogoInfos } = useMainStore()
    return {
      title: siteInfos?.title || siteInfos?.siteName,
      meta: [
        {
          name: 'theme-color',
          content:
            siteConfig?.INJECT_DATA?.apiGetSiteInfo.data.data.skinConfigInfo
              .bs_topnav_bg ?? '#FFFFFF'
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'white'
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes'
        },
        {
          name: 'apple-touch-fullscreen',
          content: 'yes'
        },
        {
          name: 'google',
          content: 'notranslate'
        },
        {
          name: 'apple-mobile-web-app-title',
          content: siteInfos?.title || siteInfos?.siteName || ''
        },
        {
          name: 'title',
          property: 'og:title',
          content: siteInfos?.title || siteInfos?.siteName || ''
        },
        {
          name: 'description',
          property: 'og:description',
          content: siteInfos?.description || ''
        },
        {
          name: 'keywords',
          property: 'og:keywords',
          content: siteInfos?.keyword || ''
        },
        {
          name: 'image',
          property: 'og:image',
          content: brandLogoInfos?.hallLogo || ''
        },
        {
          name: 'url',
          property: 'og:url',
          content: location.origin
        },
        {
          name: 'type',
          property: 'og:type',
          content: 'game'
        }
      ]
    }
  }
  @Bind()
  private toHome(action: GlobalEventActions) {
    if (action.type === 'TO_HOME') {
      this.$router.push({
        name: RouteName.ROOT
      })
    }
  }
  listenMessage() {
    const done = () => {
      try {
        ;[window, new BroadcastChannel('message') as Window].forEach(
          (context) => {
            context.addEventListener('message', (event) => {
              if (this.isKeptAlive) {
                return
              }
              try {
                const action =
                  typeof event.data === 'object'
                    ? event.data
                    : JSON.parse(event.data)
                if (
                  !event.origin.includes(window.location.origin) &&
                  // 白名单事件
                  ![
                    GlobalEventActionsTypeEnum.EVENT_CUSTOMIZE_MOCK_PREVIEW
                  ].includes(action.type)
                ) {
                  return
                }
                if (action && action.eventType === 'onActions') {
                  GlobalEvents.dispatch({
                    type: action.type,
                    payload: action.payload
                  })
                }
              } catch (error) {}
            })
          }
        )
      } catch (error) {}
    }
    if (window.BroadcastChannel) {
      done()
    } else {
      loadScripts({
        id: 'broadcastchannel-polyfill',
        src: '/libs/broadcastchannel-polyfill/index.js'
      }).finally(() => {
        done()
      })
    }
  }

  mounted() {
    const timed = {} as Record<string, number>
    document.body.classList.add('ready')
    // 修复反馈#26275
    window.addEventListener(
      'popstate',
      () => {
        if (this.isKeptAlive) {
          return
        }
        const app = document.querySelector('#app') as HTMLElement
        app.style.zIndex = ''
      },
      false
    )
    GlobalEvents.subscribe(this.toHome)
    this.listenMessage()
    this.bindGuide2Reload()
    /**
     * 监听页面宽度，触发响应式布局的断点
     */
    new GlobalManager.Media().watchResize()
    /**
     * 页面离开时，所有轮询进行暂停
     */
    GlobalEvents.subscribe((actions) => {
      if (actions.type === 'PAGE_VISIBILITY_CHANGE') {
        if (actions.payload.visibility) {
          GlobalManager.Polling.recover()
        } else {
          GlobalManager.Polling.pause()
        }
      }
      if (actions.type === 'ANTD_POPUP_INVISIBLE') {
        this.hackAntdComp()
      }
      if (actions.type === 'TOKEN_EXPIRED') {
        GlobalManager.WS.ws?.disconnect()
        GlobalManager.Polling.destroy()
        this.createTokenExpiredModal(actions.payload.message)
      }
      if (actions.type === 'RESPONSE_ERROR') {
        this.createErrorMessage(actions.payload.message)
      }
      if (actions.type === 'SITE_MAINTAIN') {
        const {
          res,
          requestErrorCountConsequent,
          isSiteFreeze,
          useMockMaintainIframe,
          isSiteFreezeAndMaintain,
          onError,
          onCallback,
          ipCheck
        } = actions?.payload || {}

        GlobalManager.Page.to503(
          formatMaintainRequestInfo({
            res,
            isSiteFreeze,
            isSiteFreezeAndMaintain,
            requestErrorCountConsequent,
            useMockMaintainIframe,
            onError,
            onCallback,
            ipCheck
          })
        )
      }
      if (actions.type === 'VERSION_CHECK') {
        this.guide2NewVersion(actions.payload)
      }
      if (actions.type === 'OTHER_CLIENT_LOGIN') {
        this.createOtherClientLoginModal(actions.payload)
      }
      if (actions.type === 'TRACE_REPORT') {
        // 同一事件10秒内只允许上报一次，防止触发安全规则
        const ev = (actions.payload?.event || 'none') as string
        if (!timed[ev] || timed[ev] + 1000 * 5 < Date.now()) {
          timed[ev] = Date.now()
          apiTraceReport(actions.payload)
        }
      }

      if (
        actions.type === 'LOGIN_SUCCESS' ||
        actions.type === 'LOGOUT_SUCCESS'
      ) {
        if (windowConfig.speedPackageConfig) {
          window.jsBridge.postMessage(
            actions.type,
            JSON.stringify(actions.payload)
          )
        }
      }

      if (actions.type === 'LOGOUT_BEFORE') {
        /**
         * 退出登陆前清理一些store（关联持久化数据）
         */
        ;[useReportStore()].forEach((store) => {
          store.hooksActions(actions.type)
        })
      }

      if (actions.type === 'LANGUAGE_CHANGE') {
        if (windowConfig.speedPackageConfig) {
          window.jsBridge.postMessage(
            'sp_language',
            GlobalConst.ServiceLanguageMap[actions.payload as Language]
          )
        }
      }

      if (actions.type === 'REFRESH_URL') {
        // 尝试上报用户网络环境
        if (!timed[actions.type]) {
          const { ossDomain } = useMainStore()
          Netest([
            `${(actions.payload.base || '').replace(/\/$/, '')}/hall/netstat`,
            `${(ossDomain[0] || '').replace(
              /\/$/,
              ''
            )}/siteadmin/skin/lobby_asset/common/web/home/btn_sc_off_2.png`,
            'https://unpkg.com/axios@1.6.8/dist/axios.min.js',
            '/op.json?t=' + Date.now()
          ]).then((env) => {
            apiTraceReport({
              event: 'ClientEnv',
              ...actions.payload,
              env
            }).catch(() => {
              //如果上报失败30秒后延迟再尝试上报一次
              setTimeout(() => {
                apiTraceReport({ event: 'ClientEnv', ...actions.payload, env })
              }, 30000)
            })
          })
        }
        // 同一事件10秒内只允许上报一次，防止触发安全规则
        if (
          !timed[actions.type] ||
          timed[actions.type] + 1000 * 60 * 5 < Date.now()
        ) {
          timed[actions.type] = Date.now()
          Site.initSiteURLInfos(false)
        }
      }
      /**
       * 埋点-我方全站点监测埋点
       */
      if (actions.type === 'MONITOR_EVENT') {
        const { ipCheck, siteCode } = useMainStore()
        const init = (() => {
          const result = traceLog.find((it) => it.event === 'init')?.data
          return pick(result, ['created', 'version'])
        })()
        const params = {
          ...actions.payload,
          siteCode,
          ip: ipCheck.ip,
          wgVersion: init.version,
          enterTime: init.created
        }
        window?.MonitoringAnalysis?.gtag('event', actions.eventName, params)
        window?.ReporterMananger?.doReport({
          eventName: actions.eventName,
          payload: params
        })
      }

      if (actions.type === 'REPORT_MANAGER') {
        const { ipCheck, siteCode } = useMainStore()
        const init = (() => {
          const result = traceLog.find((it) => it.event === 'init')?.data
          return pick(result, ['created', 'version'])
        })()
        const params = {
          ...actions.payload,
          siteCode,
          ip: ipCheck.ip,
          wgVersion: init.version,
          enterTime: init.created
        }
        window?.ReporterMananger?.doReport({
          eventName: actions.eventName,
          payload: params
        })
      }
      /**
       * 响应需要配置更新的事件，进行配置更新后派发更新成功的事件
       * 供业务内部响应进行视图更新
       */
      if (actions.type === 'CONFIG_CHANGE_ERR') {
        const { updateSystemInfos } = useMainStore()
        apiGetSysInfo({
          customParams: {
            useCache: false
          }
        }).then((response) => {
          const systemInfos = response!.data?.data
          if (systemInfos) {
            updateSystemInfos(systemInfos)
            GlobalEvents.dispatch({
              type: 'CONFIG_CHANGE_SUCCESS'
            })
          }
        })
      }
    })

    const { maintainTimeInfo } = useMainStore()
    if (maintainTimeInfo) {
      maintainCheckOnStartTime(maintainTimeInfo)
    }
    this.getKefuDrag()

    this.sendPerformanceLog()
    // 每次刷新或者进入大厅标记活动没有请求列表数据
    const { setFirstLoaded } = useEventStore()
    setFirstLoaded(false)

    // ws监听后台重置会员账号
    useMainStore().listenChangeWithdrawPassword()

    // 启动红包
    useRedPocketStore().initRedPocket()
  }

  beforeDestroy() {
    // 关闭红包
    useRedPocketStore().destroyRedPocket()
  }

  // 业主要求修改为30分钟误操作
  // 十分钟无操作行为后引导用户刷新页面
  bindGuide2Reload() {
    /**
     * 记录用上次操作的时间，初始为程序装载时的时间
     */
    let lastUserActionTime = Date.now()

    window.document.body.addEventListener(
      'click',
      () => {
        if (this.isKeptAlive) {
          return
        }
        const now = Date.now()
        const mins = 30
        if (now - lastUserActionTime > 1000 * 60 * mins && !this.guideModal) {
          this.createGuideModal()
        }
        lastUserActionTime = now
      },
      {
        capture: true
      }
    )
  }

  private createGuideModal() {
    const { t } = useI18n()
    this.guideModal = Modal.create({
      title: () => t('lobby.error.friendlyReminder'),
      content: () => this.$t('lobby.error.guide2Reload'),
      cancelText: this.$t('lobby.common.tips.cancel') as string,
      okText: this.$t('lobby.common.tips.confirm') as string,
      onOk: () => {
        window.location.reload()
      },
      onCancel: () => {
        upgrade()
        this.guideModal = null
      }
    })
  }

  private createOtherClientLoginModal(payload: {
    token: string
    deviceModel: string
  }) {
    const zIndex = 99998
    const lock = {
      request: false
    }
    const setAntMessageElementZIndex = (zIndex: string) => {
      const antMessageElement = document.querySelector(
        'html .ant-message'
      ) as HTMLElement
      if (antMessageElement) {
        antMessageElement.style.zIndex = zIndex
      }
    }
    /**
     * 避免多个错误码显示多个弹窗实例
     */
    if (this.otherClientLoginModal) {
      return
    }

    this.otherClientLoginModal = Modal.create({
      title: this.$t('lobby.modal.title.error'),
      titleType: 'error',
      class: style['other-client-login-modal'],
      closable: true,
      zIndex,
      content: () => {
        return (
          <inner-html
            text={this.$t('lobby.error.otherClientLogin.content', {
              deviceModel: payload.deviceModel
            })}
          />
        )
      },
      afterClose: () => {
        this.otherClientLoginModal = null
      },
      cancelText: this.$t(
        'lobby.error.otherClientLogin.deleteDevice'
      ) as string,
      onCancel: async () => {
        try {
          await new Promise((resolve, reject) => {
            if (lock.request) return

            lock.request = true
            const fail = () => {
              Modal.message({
                type: 'error',
                onAutoClose: () => {
                  setAntMessageElementZIndex('')
                },
                content: this.$t(
                  'lobby.error.otherClientLogin.deleteDeviceError'
                ) as string
              })
              setAntMessageElementZIndex(String(zIndex))
              reject()
            }

            Promise.all([
              apiLogout({ token: payload.token }),
              apiGameLogout({
                platformId: undefined,
                token: payload.token
              }).catch(() => {
                /**
                 * 允许apiGameLogout调用失败
                 */
              })
            ])
              .then(() => {
                resolve(true)
                this.otherClientLoginModal = null
                this.$router.push({ name: RouteName.SECURITY })
              })
              .catch(fail)
          })
        } finally {
          lock.request = false
        }
      },
      okText: this.$t('lobby.error.otherClientLogin.logoutGame') as string,
      onOk: async () => {
        try {
          await new Promise((resolve, reject) => {
            if (lock.request) return
            lock.request = true

            const fail = () => {
              Modal.message({
                type: 'error',
                onAutoClose: () => {
                  setAntMessageElementZIndex('')
                },
                content: this.$t(
                  'lobby.error.otherClientLogin.logoutGameError'
                ) as string
              })
              setAntMessageElementZIndex(String(zIndex))

              reject()
            }

            apiGameLogout({ platformId: undefined, token: payload.token })
              .then(() => {
                resolve(true)
                this.otherClientLoginModal = null
              })
              .catch(fail)
          })
        } finally {
          lock.request = false
        }
      }
    })
  }

  private Performance = {}
  private logDelta({
    name,
    delta
  }: {
    name: 'FCP' | 'FID' | 'LCP' | 'TTFB'
    delta: number
  }) {
    this.Performance = {
      ...this.Performance,
      [name]: parseFloat(delta.toFixed(2))
    }
  }

  private sendPerformanceLog() {
    onFCP(this.logDelta)
    onFID(this.logDelta)
    onLCP(this.logDelta)
    onTTFB(this.logDelta)
    setTimeout(() => {
      GlobalEvents.dispatch({
        type: 'MONITOR_EVENT',
        eventName: 'Performance',
        payload: this.Performance
      })
    }, 20 * 1000)
  }

  guide2NewVersion({
    version,
    disabled,
    force
  }: {
    version: number
    disabled?: boolean
    force?: boolean
  }) {
    if (disabled) return
    if (version || force) {
      const currentVersion = moment(
        (document.querySelector('html') as HTMLHtmlElement).getAttribute(
          'data-version'
        ) as string
      ).format('YYYY-MM-DD HH:mm')

      const serviceVersion = moment(version).format('YYYY-MM-DD HH:mm')
      if (currentVersion !== serviceVersion || force) {
        const xVersion = GlobalService.getVersion()
        // const currentVersionReloadCount =
        //   useMainStore().versionReloadRecord?.[xVersion] || 0
        if (this.versionErrorModal) return
        const { t } = useI18n()
        this.versionErrorModal = Modal.create({
          content: () => t('lobby.error.upgradedMsg'),
          closable: !force,
          cancelText: this.$t('lobby.common.tips.cancel') as string,
          okText: this.$t('lobby.error.upgradedBtn') as string,
          zIndex: 99999,
          onOk: async () => {
            /**
             * 记录当前版本号刷新的次数
             */
            if (xVersion) {
              useMainStore().updateVersionReloadRecord(xVersion)
            }
            const url = new URL(window.location.href)
            const params = url.searchParams
            //html 有cdn 5分钟才回源的影响 可能导致op.json服务端版本号更新了，html在5分钟内可能拿旧版本，造成循环提示
            params.set('fixed.version', String(Date.now()))
            url.search = params.toString()
            window.location.href = url.toString()
          },
          afterClose: () => {
            this.versionErrorModal = null
          },
          cancelButtonProps: {
            style: force
              ? {
                  display: 'none'
                }
              : {}
          }
        })
      }
    }
  }
  /**
   * 获取是否配置客服代码
   * 获取到客服悬浮按钮添加拖拽事件
   * 轮询60次未拿到悬浮按钮dom终止轮询
   */
  getKefuDrag(timeout = 60) {
    const ukf = document.querySelector('[id="ukefu-point"]') as HTMLElement
    const lichat = document.querySelector(
      '[id="chat-widget-container"]'
    ) as HTMLElement
    this.dragTime++
    if (this.dragTime > timeout) {
      return
    }
    if (ukf || lichat) {
      ukf && setDragToMove(ukf)
      lichat && createMaskToDrag(lichat)
    } else {
      setTimeout(() => {
        this.getKefuDrag()
      }, 1000)
    }
  }
  // 遍历所有ant组件将一些组件关闭
  @Bind()
  @Throttle(2000)
  private hackAntdComp(compNames = ['Select', 'Picker', 'ADropdown']) {
    this.getChilds(
      (context) => compNames.includes(context.$options.name ?? 'unknown'),
      this
    ).forEach((comp) => {
      // eslint-disable-next-line
      const context = comp as unknown as any
      switch (context.$options.name) {
        case 'Select':
          if (typeof context.setOpenState === 'function') {
            context.setOpenState(false)
          }
          break
        case 'Picker':
          if (context.sOpen) {
            context.sOpen = false
          }
          break
        default:
          typeof context.blur === 'function' && context.blur()
          break
      }
    })
  }

  private get routerName() {
    return this.$route.name
  }

  /** 悬浮客服的展示页面0首页，1表示全部*/
  private get serviceDisplayPage() {
    return useMainStore()?.serviceInformation?.customer?.onlineCustomers?.[0]
      ?.h5DisplayPage
  }

  /**是否配置了h5的在线客服悬浮框 */
  private get hasCustomerCodeApp() {
    return useMainStore()?.serviceInformation?.customer?.onlineCustomers?.[0]
      ?.customerCodeApp
  }

  /**当前路由底部是否带有showTabBar */
  private get isFirstRoutes() {
    return allShowTabBaroutes.includes(this.routerName as RouteName)
  }

  /**是否是游戏嵌套页面 */
  private get isEmbedded() {
    return this.routerName === RouteName.GAME_EMBEDDED
  }

  /**隐藏悬浮按钮 */
  private hiddenThirdServieButton() {
    const { thirdServiceMarkList } = useServiceStore()
    for (const mark of thirdServiceMarkList) {
      const serviceDom = document.querySelector(mark)
      // 如果没有查找到元素，那么继续迭代
      if (!serviceDom) {
        continue
      }
      ;(serviceDom as HTMLElement).style.zIndex = '-1'
      ;(serviceDom as HTMLElement).style.opacity = '0'
      break
    }
  }

  /**展示悬浮按钮 */
  private displayThirdServieButton() {
    // 悬浮客服展示的页面是首页，同步当前的路由是首页的场景
    if (
      (this.isFirstRoutes &&
        this.serviceDisplayPage === ServiceButtonDisplayPage.Home) ||
      this.serviceDisplayPage === ServiceButtonDisplayPage.All
    ) {
      const { thirdServiceMarkList } = useServiceStore()
      for (const mark of thirdServiceMarkList) {
        const serviceDom = document.querySelector(mark)
        // 如果没有查找到元素，那么继续迭代
        if (!serviceDom) {
          continue
        }
        ;(serviceDom as HTMLElement).style.zIndex = '10000'
        ;(serviceDom as HTMLElement).style.opacity = '1'
        break
      }
    }
  }
  /**
   *
   */
  @Watch('routerName', { immediate: true })
  async handleThirdServiceVisible() {
    const { hasInitThirdService, setHasInitThirdService } = useServiceStore()
    /**如果没有初始化三方客服，那么需要等待第一次完成后再执行 */
    if (!hasInitThirdService) {
      await delay(10000)
      setHasInitThirdService(true)
    }
    if (!this.hasCustomerCodeApp) {
      return
    }

    if (this.isEmbedded) {
      this.hiddenThirdServieButton()
      return
    }

    // 悬浮客服展示的页面是一级页面，同时当前的路由是非一级页面的场景
    if (
      !this.isFirstRoutes &&
      this.serviceDisplayPage === ServiceButtonDisplayPage.Home
    ) {
      this.hiddenThirdServieButton()
      return
    }

    this.displayThirdServieButton()
  }

  /** 表单验证功能下方错误提示 切换语言之后要做刷新验证去刷新文字是选择的语言 */
  @Watch('language')
  protected formModelReset() {
    if (this.guideModal) {
      this.guideModal.destroy()
      this.guideModal = null
      this.createGuideModal()
    }

    this.getChilds(
      (context) =>
        ['AFormItem', 'AFormModelItem'].includes(
          context.$options.name ?? 'unknown'
        ),
      this
    ).forEach((comp) => {
      // eslint-disable-next-line
      const context = comp as unknown as any
      if (context.validateState) {
        let context_trigger
        if (context.rules) {
          context_trigger = context.rules[0]?.trigger
            ? context.rules[0]?.trigger[0]
            : undefined
        }
        context.validate(context_trigger)
      }
    })
  }
  protected guideModal: ReturnType<typeof Modal['create']> | null = null
  protected tokenExpiredModal: ReturnType<typeof Modal['create']> | null = null
  protected versionErrorModal: ReturnType<typeof Modal['create']> | null = null
  protected otherClientLoginModal: ReturnType<typeof Modal['create']> | null =
    null
  protected errorMessage: ReturnType<typeof Modal['message']> | null = null
  private createTokenExpiredModal(content: string) {
    const { updateUserInfos, antLocale, userInfos } = useMainStore()
    const { t } = useI18n()
    if (this.tokenExpiredModal) {
      return
    } else {
      apiTraceReport({
        event: 'TokenExpired',
        username: userInfos?.username
      })
      GlobalManager.WS.ws?.disconnect()
      updateUserInfos(null)
      this.tokenExpiredModal = Modal.create({
        title: t('lobby.common.tips.title'),
        class: 'tokenExpiredModal',
        titlePosition: 'center',
        content: () => content,
        contentPosition: 'center',
        /**
         * 传入okText和cancelText为了
         * 避免Vue实例还未挂载时，ConfigProvider 组件还未生效，传入的 国际化语言
         * 还未调用，此时通过create创建的弹层若使用ant-design内置文案，均为英文
         */
        ...{
          ...antLocale?.Modal
        },
        afterClose: () => {
          this.tokenExpiredModal = null
        },
        cancelButtonProps: {
          style: {
            display: 'none'
          }
        },
        onOk: () => {
          GlobalManager.Page.toRoot()
        }
      })
    }
  }
  private createErrorMessage(content: string) {
    const currentMessage = document.querySelector(
      '.ant-message-error > span'
    ) as HTMLElement
    /**
     * 相同的错误提示页面只提示一次
     */
    if (currentMessage?.innerText !== content) {
      if (this.errorMessage) {
        this.errorMessage.destroy()
      }
      this.errorMessage = Modal.message({
        type: 'error',
        content,
        onClose: () => {
          this.errorMessage = null
        }
      })
    }
  }

  // 最顶层绑定点击事件，以判断是否是机器人
  private handleClick() {
    useMainStore().robot = 0
  }

  render() {
    const appStore = useMainStore()
    return (
      <div id="app" key={appStore.rootKey} onClick={this.handleClick}>
        {/* 业主要求，假的seo */}
        <h1 style={{ display: 'none' }}>{appStore.siteInfos?.title}</h1>
        <ConfigProvider locale={appStore.antLocale}>
          <div>
            {this.defer(1) && <router-view />}
            {this.defer(2) && <GlobalModal />}
            {/* <GlobalFestival /> */}
            {appStore.isInitializationCompleted && <GlobalStyles />}
            <PortalTarget
              multiple={true}
              name={GlobalConst.PortalName.GlobalApp}
            />
            {/* 提示手机旋转的弹框 */}
            {/* <OrientationReminder /> */}
          </div>
        </ConfigProvider>
        {/* 渲染google三方登录的btn 不能删  */}
        <div id="google-btn-help"></div>
      </div>
    )
  }
}
