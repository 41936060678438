import { Base, Component, Prop, Watch } from '@/vue-property-decorator'
import { CommonLoading } from '..'
import { EmptyImageReport } from '../empty-image'
import { VNode } from 'vue'
import { VantEmpty, VantList } from '@/plugins/vant/tsx-support'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import NetRetry, { DisplayType } from '../net-retry'
import style from './style.module.scss'

export const DEFAULT_PAGE_SIZE = 10
export const DEFAULT_TOTAL = 0
// eslint-disable-next-line
export type UncertainRecordType = Record<string, any>

export type State = {
  loading: boolean
  finished: boolean
  source: Awaited<ReturnType<Props['query']>>['data']
  infinitySource: Awaited<ReturnType<Props['query']>>['data']
  pageNo: number
  pageSize: number
  total: number
  errorMessage?: Error
  isCacheSource: boolean
}
export type Props = {
  defaultPageSize?: number
  condition?: UncertainRecordType
  query: (
    pageNo: number,
    pageSize: number,
    // eslint-disable-next-line
    condition?: UncertainRecordType | any
  ) => Promise<{
    // eslint-disable-next-line
    data: any[]
    total?: number
    more?: boolean
    errorMessage?: Error
    isCache?: boolean
  }>
  preQueryHook?: () => Promise<unknown>
  postQueryHook?: () => Promise<unknown>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listRenderItem: (item: any) => VNode
  footerRender?: () => VNode
  backgroundColorReverse?: boolean
  immediateCheck?: boolean
}
@Component<DataList>({ name: 'DataList' })
export class DataList extends Base<State, Props> {
  /**
   * 默认页面大小
   */
  @Prop({ default: 20 })
  defaultPageSize?: Props['defaultPageSize']

  /**
   * 查询条件 如 时间 排序等
   */
  @Prop({ default: () => ({}) })
  condition?: Props['condition']
  /**
   * 查询前置钩子
   */
  @Prop({ required: false })
  preQueryHook?: Props['preQueryHook']

  /**
   * 查询后置狗子
   */
  @Prop({ required: false })
  postQueryHook?: Props['postQueryHook']

  /**
   * 数据查询函数 返回值要适配column 中的dataIndex 字段
   */
  @Prop({ required: true })
  query!: Props['query']

  /**列表内容回调方法 */
  @Prop({ required: true })
  listRenderItem!: Props['listRenderItem']

  /**列表内容回调方法 */
  @Prop({ required: false })
  footerRender?: Props['footerRender']

  /** 列表背景颜色顺序是否反转 */
  @Prop({ default: false })
  backgroundColorReverse?: Props['backgroundColorReverse']

  /** 是否在初始化时立即执行滚动检查 */
  @Prop({ default: true })
  immediateCheck?: Props['immediateCheck']

  state: State = {
    loading: false,
    finished: false,
    /** 数据源 */
    source: [],
    /** 无限加载串连数据源 */
    infinitySource: [],
    /** 分页页码 */
    pageNo: 0,
    /** 分页大小 */
    pageSize: 20,
    /** 数据总数 */
    total: 0,
    /**
     * 数据源是否是持久化缓存，是的话，下次真实数据回来要完整覆盖掉数据源而不是concat追加
     */
    isCacheSource: false
  }
  created(): void {
    this.setState({ pageSize: this.defaultPageSize })
  }

  mounted(): void {
    if (!this.immediateCheck) {
      this.onLoad()
    }
  }

  /**
   * 父级调用修改状态
   */
  public changeState(param: Partial<State>) {
    this.setState(param)
  }

  @Watch('condition', { deep: true })
  @Watch('state.pageSize')
  resetPage() {
    this.setState({
      pageNo: 0,
      infinitySource: []
    })
  }
  @Watch('condition', { deep: true })
  async onLoad() {
    if (!useMainStore().hasLogined) return
    const { loading } = this.state
    if (loading) return
    const { pageNo, errorMessage } = this.state
    const nextPageNo = errorMessage ? pageNo : pageNo + 1
    this.setState({
      finished: false,
      loading: true,
      pageNo: nextPageNo || 1 // 默认最少第一页 避免报错
    })
    try {
      const { pageNo, pageSize } = this.state
      await this.preQueryHook?.call(this)
      const { data, total, errorMessage, isCache } = await this.query(
        pageNo,
        pageSize,
        this.condition ?? {}
      )

      //isCache query返回来的数据是否是持久化数据，是的话isCacheSource记录下来。下次真数据来了要清理掉infinitySource
      if (isCache) {
        this.setState({
          isCacheSource: true,
          pageNo: 0
        })
      }
      if (!isCache && this.state.isCacheSource) {
        this.setState({
          isCacheSource: false,
          infinitySource: []
        })
      }
      this.setState({
        errorMessage
      })
      if (errorMessage) {
        this.setState({
          loading: false
        })
        return
      }
      const infinitySource = this.state.infinitySource.concat(data ?? [])
      const sourceTotal = total ?? DEFAULT_TOTAL
      this.setState({
        finished: infinitySource.length >= sourceTotal,
        source: data,
        infinitySource,
        total: sourceTotal
      })
      await this.postQueryHook?.call(this)
      this.$nextTick(() => {
        this.setState({
          loading: false
        })
      })
    } catch {
      this.setState({ loading: false, finished: true })
    }
  }

  render() {
    const { loading, finished, infinitySource, errorMessage } = this.state
    const { t } = useI18n()
    return (
      <div class={[style.dataList, 'vantDataList']}>
        {!loading && !errorMessage && infinitySource.length == 0 && (
          <VantEmpty
            description={t('lobby.event.luckyWheel.tabs.noData') as string}
          >
            <EmptyImageReport slot="image" />
          </VantEmpty>
        )}
        {(this.immediateCheck || !!infinitySource.length || !!errorMessage) && (
          <VantList
            class={{
              empty: infinitySource.length === 0,
              reverse: this.backgroundColorReverse ?? false
            }}
            v-model={loading}
            onLoad={this.onLoad}
            finished={finished}
            loading-text={t('lobby.game.loadinged') as string}
            offset={20}
            error={!loading && !!errorMessage}
            immediate-check={this.immediateCheck}
          >
            {infinitySource.map((item) => {
              return this.listRenderItem(item)
            })}
            <div slot="loading" class={style.loadingSlot}>
              <CommonLoading spinning={true}></CommonLoading>
              <span>{t('lobby.game.loadinged')}...</span>
            </div>
            <NetRetry
              slot="error"
              onRetry={this.onLoad}
              displayType={
                infinitySource.length === 0
                  ? DisplayType.ListData
                  : DisplayType.vantList
              }
              axiosError={errorMessage}
              loading={loading}
            />
          </VantList>
        )}

        {infinitySource.length > 0 && this.footerRender && this.footerRender()}
      </div>
    )
  }
}
