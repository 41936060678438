import { Currency } from '@/config/currencies.config'
import {
  CurrencyEnum,
  RegisterValidateRequired,
  SiteInfos
} from '@/api/common/type'
import {
  FormModel,
  FormRuleItem,
  Icon,
  Input,
  Select
} from '@/plugins/ant-design-vue/tsx-support'
import { GlobalManager } from '@/context'
import { PngSprite, SvgSprite } from '@/components/icon-sprite'
import { createSvgSprite } from '@/utils/business-utils/assets'
import { get, merge, set } from 'lodash'
import { numberOnlyFormatter } from '@/utils/FormatterUtils'
import { useI18n } from '@/i18n'
import { useMainStore } from '@/store/index'
import VueUtils from '@/utils/VueUtils'
// const icon_dl_hb = createSvgSprite('input_icon_hb')
// const icon_dl_mm = createSvgSprite('input_icon_mm')
// const icon_dl_sj = createSvgSprite('input_icon_sj')
// const icon_dl_yj = createSvgSprite('input_icon_yj')
// const icon_dl_zh = createSvgSprite('input_icon_zh')
// const icon_dl_zsxm = createSvgSprite('input_icon_zsxm')
// const icon_wd_cpf = createSvgSprite('icon_wd_cpf')
import AutoShrinkText from '@/components/auto-shrink-text'
import PhoneSegment from './components/phone-segment'
import style from './style.module.scss'
import type { TranslateResult } from 'vue-i18n'

type FormModelItemTsxDataType =
  VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
    typeof FormModel.Item.Tsx,
    unknown
  >
type BaseOptions<T = Record<string, unknown>> = {
  showLabel?: boolean
  label?: string | TranslateResult
  hiddenPrefix?: boolean
  hiddenIcon?: boolean
  prop: Paths<T, 2>
  model: T
  beforeChange?: (v: string) => string
  formModelItemTsxData?: FormModelItemTsxDataType
  getRules?: (
    defaultRules: Record<'required' | 'pattern', FormRuleItem>
  ) => Arrayed<FormRuleItem>
  isReadConfig?: boolean
  /**加了之后，margin-bottom的值为0 */
  isexplainAuto?: boolean
  isRequired?: boolean
}

interface UsernameOptions<T> extends BaseOptions<T> {
  inputTsxData?: VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
    typeof Input.Tsx,
    unknown
  >
  prefix?: BaseRenderContent
  suffix?: BaseRenderContent
  editText?: boolean
  onEditText?: () => void
  disabled?: boolean
}

interface UserRealNameOptions<T> extends BaseOptions<T> {
  inputTsxData?: VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
    typeof Input.Tsx,
    unknown
  >
  onChange?: (event: Event) => void
  placeholder?: string
  disabled?: boolean
}

interface UserpassOptions<T> extends BaseOptions<T> {
  inputTsxData?: VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
    typeof Input.Password.Tsx,
    unknown
  >
  showEditBtn?: boolean
  /** 是否明文展示用户密码 */
  visible?: boolean
  onEditText?: () => void
  onOpenEye?: () => void
}
interface UsercpfOptions<T> extends BaseOptions<T> {
  inputTsxData?: VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
    typeof Input.Tsx,
    unknown
  >
  isRequired?: boolean
  onChange?: (event: Event) => void
}
interface UseremailOptions<T> extends BaseOptions<T> {
  selectTsxData?: VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
    typeof Select.Tsx,
    unknown
  >
  onSelectChange?: (value: string) => void
  /** 是否开启邮箱输入自动转成小写 @default true */
  atuoLowerCase?: boolean
  /** 是否开启展示下拉选择框 @default true */
  showDropdown?: boolean
  customIcon?: string
}
interface UserPhoneSelectOptions<T> extends BaseOptions<T> {
  inputTsxData?: VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
    typeof Input.Tsx,
    unknown
  >
  selectTsxData?: VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
    typeof Select.Tsx,
    unknown
  >
  onSelectChange: (value: string) => void
}

export interface UserCurrencySelectOptions<T> extends BaseOptions<T> {
  selectTsxData?: VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
    typeof Select.Tsx,
    unknown
  >
  current: string
  isRequired?: boolean
  disabled?: boolean
  hiddenLabelIcon?: boolean
  currencyInfos: SiteInfos['currencyInfos']
  onSelectChange: (value: string) => void
}
interface BaseSelect<T> extends BaseOptions<T> {
  showPrefix?: SvgSprite | PngSprite
  selectTsxData?: VueTsxSupport.JSX.ExtractComponentsTsxAttributes<
    typeof Select.Tsx,
    unknown
  >
  listData: Array<{ value: string | number; label: string | TranslateResult }>
  current: string | number
  onSelectChange: (value: string | number) => void
  render?: (
    model?: T
  ) => BaseRenderContent | Arrayed<Arrayed<BaseRenderContent>>
}

interface CustomOptions<T> extends BaseOptions<T> {
  render: (model?: T) => BaseRenderContent | Arrayed<Arrayed<BaseRenderContent>>
}

export default class FuncRender extends VueUtils {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static createOnInputChange(
    obj: Record<string, unknown>,
    path: string,
    beforeChange = (v: string) => {
      return v
    }
  ) {
    return (event: Event) => {
      let v = get(event, 'target.value')
      if (beforeChange) {
        v = beforeChange(v)
      }

      set(obj, path, v)
    }
  }
  /**
   * 公共表单节点
   */
  public static createFormModelItem() {
    const { t } = useI18n()
    const { isWeb, language } = useMainStore()
    /**
     * 自定义渲染表单包裹
     */
    const custom: <T>(
      options: CustomOptions<T>
    ) => VueTsxSupport.JSX.Element = (options) => {
      return (
        <FormModel.Item.Tsx
          key={options.prop as string}
          class={[
            `base-form-item-${options.prop}`,
            style['base-form-item'],
            options.isexplainAuto ? style['base-form-item-auto'] : ''
          ]}
          {...merge<FormModelItemTsxDataType, FormModelItemTsxDataType>(
            {
              props: {
                prop: options.prop as string,
                colon: false
              },
              attrs: {
                htmlFor: 'none',
                'data-validate-field': options.prop,
                'data-show-prefix': !options.hiddenPrefix
              }
            },
            options.formModelItemTsxData as FormModelItemTsxDataType
          )}
        >
          {options.render(options.model)}
        </FormModel.Item.Tsx>
      )
    }
    /**
     * 基础下拉框
     */
    const baseSelect: <T>(
      options: BaseSelect<T>
    ) => VueTsxSupport.JSX.Element = (options) => {
      options = merge(
        {
          hiddenPrefix: true
        },
        options
      )
      const dropdownClassName = [
        style['base-select-dropdown'],
        ...(options.selectTsxData?.props?.dropdownClassName || '')?.split(' ')
      ]

      return custom({
        ...options,
        formModelItemTsxData: merge<
          FormModelItemTsxDataType,
          FormModelItemTsxDataType
        >(
          {
            class: style['base-select']
          },
          options.formModelItemTsxData as FormModelItemTsxDataType
        ),
        render: () => {
          return [
            options.render
              ? (options.render(options.model) as BaseRenderContent)
              : null,
            <Select.Tsx
              class={[
                style['select'],
                options.showPrefix ? style['has-select-prefix'] : ''
              ]}
              value={options.current}
              onChange={options.onSelectChange}
              {...merge(options.selectTsxData as unknown, {
                props: {
                  dropdownClassName: dropdownClassName.join(' ')
                }
              })}
            >
              {options.listData.map((item) => (
                <Select.Option.Tsx value={item.value} key={String(item.label)}>
                  {item.label}
                </Select.Option.Tsx>
              ))}
            </Select.Tsx>,
            options.showPrefix ? (
              <div
                class={[
                  style['custom-select-icon'],
                  style['custom-select-email-icon']
                ]}
              >
                <Icon.Tsx
                  slot="suffixIcon"
                  component={{
                    functional: true,
                    render() {
                      return (
                        <icon-sprite sprite={options.showPrefix as SvgSprite} />
                      )
                    }
                  }}
                />
              </div>
            ) : null
          ]
        }
      })
    }

    const username: <T>(
      options: UsernameOptions<T>
    ) => VueTsxSupport.JSX.Element = (options) => {
      const getRules =
        options.getRules || ((defaultRules) => Object.values(defaultRules))
      return custom({
        ...options,
        formModelItemTsxData: merge<
          FormModelItemTsxDataType,
          FormModelItemTsxDataType
        >(
          {
            props: {
              rules: getRules({
                required: GlobalManager.Rules.username(undefined, {
                  required: true
                }),
                pattern: GlobalManager.Rules.username()
              })
            }
          },
          options.formModelItemTsxData as FormModelItemTsxDataType
        ),
        render: () => {
          return [
            options.showLabel ? (
              <template slot="label">
                <div>
                  <Icon.Tsx
                    v-show={!options.hiddenIcon}
                    component={{
                      functional: true,
                      render() {
                        return (
                          <icon-sprite
                            sprite={createSvgSprite('input_icon_zh')}
                          />
                        )
                      }
                    }}
                  />
                  <AutoShrinkText
                    minFontSize={'0.14rem'}
                    text={
                      (options.label as string) ||
                      (t('lobby.modal.login.form.username.label') as string)
                    }
                  />
                </div>
              </template>
            ) : null,
            <Input.Tsx
              value={get(options.model, options.prop)}
              onChange={FuncRender.createOnInputChange(
                options.model as Record<string, unknown>,
                options.prop,
                options.beforeChange
              )}
              disabled={options.disabled}
              allowClear
              placeholder={t('lobby.modal.login.form.username.label') as string}
              {...merge(
                {
                  attrs: {
                    autocomplete: 'off',
                    maxLength: 16
                  }
                },
                options.inputTsxData as unknown
              )}
            >
              {options.editText && (
                <span
                  slot="suffix"
                  data-click-suffix
                  class={style['input-edit-disabled']}
                  onClick={options.onEditText}
                >
                  {t('lobby.modal.login.operate.modify')}
                </span>
              )}
              {!options.hiddenPrefix &&
                (options?.prefix || (
                  <div slot="prefix">
                    <Icon.Tsx
                      component={{
                        functional: true,
                        render() {
                          return (
                            <icon-sprite
                              sprite={createSvgSprite('input_icon_zh')}
                            />
                          )
                        }
                      }}
                    />
                    {options.isRequired && !options.showLabel && (
                      <span class={style.formRequired}>*</span>
                    )}
                  </div>
                ))}

              {options.suffix}
            </Input.Tsx>
          ]
        }
      })
    }

    const userRealName: <T>(
      options: UserRealNameOptions<T>
    ) => VueTsxSupport.JSX.Element = (options) => {
      const getRules =
        options.getRules || ((defaultRules) => Object.values(defaultRules))
      const { systemInfos } = useMainStore()
      const { realNameMustUppercase } = systemInfos || {}
      return custom({
        ...options,
        formModelItemTsxData: merge<
          FormModelItemTsxDataType,
          FormModelItemTsxDataType
        >(
          {
            props: {
              rules: getRules({
                required: GlobalManager.Rules.required(
                  {},
                  {
                    message:
                      realNameMustUppercase === RegisterValidateRequired.YES
                        ? () =>
                            t(
                              'lobby.modal.login.form.realName.realNameTips3'
                            ) as string
                        : () =>
                            t(
                              'lobby.modal.login.form.realName.realNameTips2'
                            ) as string
                  }
                ),
                pattern: GlobalManager.Rules.required()
              })
            }
          },
          options.formModelItemTsxData as FormModelItemTsxDataType
        ),
        render: () => {
          return [
            options.showLabel ? (
              <template slot="label">
                <div>
                  <Icon.Tsx
                    v-show={!options.hiddenIcon}
                    component={{
                      functional: true,
                      render() {
                        return (
                          <icon-sprite
                            sprite={createSvgSprite('input_icon_zsxm')}
                          />
                        )
                      }
                    }}
                  />
                  <span>
                    {options.label ||
                      (t('lobby.modal.login.form.realName.label') as string)}
                  </span>
                </div>
              </template>
            ) : null,
            <Input.Tsx
              disabled={options.disabled}
              value={get(options.model, options.prop)}
              onChange={
                options?.onChange ||
                FuncRender.createOnInputChange(
                  options.model as Record<string, unknown>,
                  options.prop
                )
              }
              maxlength={100}
              allowClear
              placeholder={
                options.placeholder ??
                (t('lobby.modal.login.form.realName.label') as string)
              }
              {...merge(
                {
                  attrs: {
                    autocomplete: 'off',
                    maxLength: 75
                  }
                },
                options.inputTsxData as unknown
              )}
            >
              {!options.hiddenPrefix && (
                <div slot="prefix">
                  <Icon.Tsx
                    v-show={!options.hiddenIcon}
                    component={{
                      functional: true,
                      render() {
                        return (
                          <icon-sprite
                            sprite={createSvgSprite('input_icon_zsxm')}
                          />
                        )
                      }
                    }}
                  />
                  {options.isRequired && !options.showLabel && (
                    <span class={style.formRequired}>*</span>
                  )}
                </div>
              )}
            </Input.Tsx>
          ]
        }
      })
    }

    const userpass: <T>(
      options: UserpassOptions<T>
    ) => VueTsxSupport.JSX.Element = (options) => {
      const getRules =
        options.getRules || ((defaultRules) => Object.values(defaultRules))
      const rules = {
        required: options.isReadConfig
          ? GlobalManager.Rules.dynamicUserpass(
              { isReadConfig: options.isReadConfig ?? false },
              { required: true }
            )
          : GlobalManager.Rules.userpass(undefined, { required: true }),
        pattern: options.isReadConfig
          ? GlobalManager.Rules.dynamicUserpass()
          : GlobalManager.Rules.userpass()
      }
      return custom({
        ...options,
        formModelItemTsxData: merge<
          FormModelItemTsxDataType,
          FormModelItemTsxDataType
        >(
          {
            props: {
              rules: getRules(rules)
            }
          },
          options.formModelItemTsxData as FormModelItemTsxDataType
        ),
        render: () => {
          return [
            options.showLabel ? (
              <template slot="label">
                <div>
                  <Icon.Tsx
                    v-show={!options.hiddenIcon}
                    component={{
                      functional: true,
                      render() {
                        return (
                          <icon-sprite
                            sprite={createSvgSprite('input_icon_mm')}
                          />
                        )
                      }
                    }}
                  />
                  <AutoShrinkText
                    minFontSize={'0.14rem'}
                    text={
                      (options.label as string) ||
                      (t('lobby.modal.login.form.userpass.label') as string)
                    }
                  />
                </div>
              </template>
            ) : null,
            <Input.Password.Tsx
              prefix={() => {
                return (
                  <div slot="prefix">
                    <icon-sprite
                      sprite={createSvgSprite('input_icon_mm')}
                    ></icon-sprite>
                    {options.isRequired && !options.showLabel && (
                      <span class={style.formRequired}>*</span>
                    )}
                  </div>
                )
              }}
              placeholder={t('lobby.modal.login.form.userpass.label') as string}
              allowClear
              value={get(options.model, options.prop)}
              onChange={FuncRender.createOnInputChange(
                options.model as Record<string, unknown>,
                options.prop,
                options.beforeChange
              )}
              {...merge(
                {
                  attrs: {
                    autocomplete: 'new-password',
                    // autocomplete: 'off',
                    maxLength: 16
                  }
                },
                options.inputTsxData as unknown
              )}
            >
              {!options.hiddenPrefix && (
                <div slot="prefix">
                  <Icon.Tsx
                    v-show={!options.hiddenIcon}
                    component={{
                      functional: true,
                      render() {
                        return (
                          <icon-sprite
                            sprite={createSvgSprite('input_icon_mm')}
                          />
                        )
                      }
                    }}
                  />
                  {options.isRequired && !options.showLabel && (
                    <span class={style.formRequired}>*</span>
                  )}
                </div>
              )}
            </Input.Password.Tsx>
          ]
        }
      })
    }

    const userpassCustomSuffix: <T>(
      options: UserpassOptions<T>
    ) => VueTsxSupport.JSX.Element = (options) => {
      options.visible = options.visible === undefined ? false : options.visible
      const getRules =
        options.getRules || ((defaultRules) => Object.values(defaultRules))
      const rules = {
        required: options.isReadConfig
          ? GlobalManager.Rules.dynamicUserpass(
              { isReadConfig: options.isReadConfig ?? false },
              { required: true }
            )
          : GlobalManager.Rules.userpass(undefined, { required: true }),
        pattern: options.isReadConfig
          ? GlobalManager.Rules.dynamicUserpass()
          : GlobalManager.Rules.userpass()
      }
      return custom({
        ...options,
        formModelItemTsxData: merge<
          FormModelItemTsxDataType,
          FormModelItemTsxDataType
        >(
          {
            props: {
              rules: getRules(rules)
            }
          },
          options.formModelItemTsxData as FormModelItemTsxDataType
        ),
        render: () => {
          return [
            options.showLabel ? (
              <template slot="label">
                <div>
                  <Icon.Tsx
                    v-show={!options.hiddenIcon}
                    component={{
                      functional: true,
                      render() {
                        return (
                          <icon-sprite
                            sprite={createSvgSprite('input_icon_mm')}
                          />
                        )
                      }
                    }}
                  />
                  <span>
                    {options.label ||
                      (t('lobby.modal.login.form.userpass.label') as string)}
                  </span>
                </div>
              </template>
            ) : null,
            <Input.Password.Tsx
              class="custom-suffix-input"
              hack_customSuffix={true}
              disabled={options.showEditBtn}
              hack_defaultVisible={options.visible}
              type={options.visible ? 'text' : 'password'}
              prefix={() => {
                return (
                  <div slot="prefix">
                    <icon-sprite
                      sprite={createSvgSprite('input_icon_mm')}
                    ></icon-sprite>
                    {options.isRequired && !options.showLabel && (
                      <span class={style.formRequired}>*</span>
                    )}
                  </div>
                )
              }}
              suffix={() => {
                return (
                  <span
                    class="custom-suffix-span"
                    slot="suffix"
                    data-show-edit={options.showEditBtn}
                  >
                    <span
                      class={`anticon ant-input-password-icon ${
                        options.visible ? '' : 'anticon-eye-invisible'
                      }`}
                      style={{ cursor: 'pointer' }}
                      onClick={options.onOpenEye}
                    >
                      <icon-sprite
                        sprite={
                          options.visible
                            ? createSvgSprite('comm_icon_show')
                            : createSvgSprite('comm_icon_hide')
                        }
                      />
                    </span>
                    {options.showEditBtn && (
                      <span
                        class="custom-suffix-edit-btn"
                        data-click-suffix
                        onClick={options.onEditText}
                      >
                        {t('lobby.modal.login.operate.modify')}
                      </span>
                    )}
                  </span>
                )
              }}
              placeholder={t('lobby.modal.login.form.userpass.label') as string}
              allowClear
              value={get(options.model, options.prop)}
              onChange={(event: Event) => {
                let v: string = get(event, 'target.value') ?? ''
                if (options.beforeChange) {
                  v = options.beforeChange(v)
                }
                /** 密码不支持中文空格 */
                v = v.replace(/[\u4E00-\u9FA5\s]/g, '')
                set(options.model as Record<string, unknown>, options.prop, v)
              }}
              {...merge(
                {
                  attrs: {
                    autocomplete: 'new-password',
                    // autocomplete: 'off',
                    maxLength: 16
                  }
                },
                options.inputTsxData as unknown
              )}
            >
              {!options.hiddenPrefix && (
                <div slot="prefix">
                  <Icon.Tsx
                    v-show={!options.hiddenIcon}
                    component={{
                      functional: true,
                      render() {
                        return (
                          <icon-sprite
                            sprite={createSvgSprite('input_icon_mm')}
                          />
                        )
                      }
                    }}
                  />
                  {options.isRequired && !options.showLabel && (
                    <span class={style.formRequired}>*</span>
                  )}
                </div>
              )}
            </Input.Password.Tsx>
          ]
        }
      })
    }

    const useremail: <T>(
      options: UseremailOptions<T>
    ) => VueTsxSupport.JSX.Element = (options) => {
      options.atuoLowerCase = options.atuoLowerCase ?? true
      options.showDropdown = options.showDropdown ?? true
      const getRules =
        options.getRules || ((defaultRules) => Object.values(defaultRules))

      const value = get(options.model, options.prop) as string

      const dropdownClassName = [
        style['base-select-dropdown'],
        'base-form-item-email-select',
        ...(options.selectTsxData?.props?.dropdownClassName || '')?.split(' ')
      ]

      const { emailSuffix } = useMainStore()
      const guessSelectOptions =
        !options.showDropdown || !value
          ? []
          : emailSuffix.map((item) => {
              const emailLocalArea = value.split('@')?.[0]
              return {
                label: emailLocalArea + item,
                value: emailLocalArea + item
              }
            })

      return custom({
        ...options,
        formModelItemTsxData: merge<
          FormModelItemTsxDataType,
          FormModelItemTsxDataType
        >(
          {
            props: {
              rules: getRules({
                required: GlobalManager.Rules.useremail(undefined, {
                  required: true,
                  message: () => {
                    if (!value)
                      return t(
                        'lobby.modal.pay.personalEmailInfoRequired'
                      ) as string
                    return t(
                      'lobby.common.components.auth.email.formatError'
                    ) as string
                  }
                }),
                pattern: GlobalManager.Rules.useremail()
              })
            },
            class: [style['email-select-input']]
          },

          options.formModelItemTsxData as FormModelItemTsxDataType
        ),
        render: () => {
          return [
            <Select.Tsx
              class={!options.hiddenIcon ? style['has-select-prefix'] : ''}
              value={value}
              onChange={(val: string) => {
                val = val.replace(/\s/g, '')
                if (val?.length <= 50) {
                  if (options.atuoLowerCase) {
                    val = val.toLocaleLowerCase()
                  }

                  set(
                    options.model as Record<string, unknown>,
                    options.prop,
                    val
                  )
                  options?.onSelectChange?.(val as string)
                }
              }}
              // allowClear
              showArrow={false}
              showSearch={true}
              notFoundContent={null}
              optionFilterProp="children"
              mode={'combobox'}
              placeholder={
                options?.selectTsxData?.placeholder ??
                (t('lobby.common.components.auth.email.inputTips') as string)
              }
              {...merge(options.selectTsxData as unknown, {
                props: {
                  dropdownClassName: dropdownClassName.join(' ')
                }
              })}
            >
              {guessSelectOptions.map((item) => (
                <Select.Option.Tsx key={item.value}>
                  {item.label}
                </Select.Option.Tsx>
              ))}
            </Select.Tsx>,
            options.showLabel ? (
              <template slot="label">
                <div>
                  <Icon.Tsx
                    v-show={!options.hiddenIcon}
                    component={{
                      functional: true,
                      render() {
                        return (
                          <icon-sprite
                            sprite={createSvgSprite(
                              options.customIcon ?? 'input_icon_yj2'
                            )}
                          />
                        )
                      }
                    }}
                  />
                  <span>
                    {options.label ||
                      (t('lobby.common.components.auth.email.label') as string)}
                  </span>
                </div>
              </template>
            ) : (
              <div
                class={[
                  style['custom-select-icon'],
                  style['custom-select-email-icon']
                ]}
              >
                <Icon.Tsx
                  component={{
                    functional: true,
                    render() {
                      return (
                        <icon-sprite
                          sprite={createSvgSprite(
                            options.customIcon ?? 'input_icon_yj2'
                          )}
                        />
                      )
                    }
                  }}
                />
                {options.isRequired && !options.showLabel && (
                  <span class={style.formRequired}>*</span>
                )}
              </div>
            ),
            options.showLabel && !options.hiddenPrefix ? (
              <div
                class={[
                  style['custom-select-icon'],
                  style['custom-select-email-icon']
                ]}
              >
                <Icon.Tsx
                  component={{
                    functional: true,
                    render() {
                      return (
                        <icon-sprite
                          sprite={createSvgSprite(
                            options.customIcon ?? 'input_icon_yj2'
                          )}
                        />
                      )
                    }
                  }}
                />
                {!options.showLabel && (
                  <span class={style.formRequired}>*</span>
                )}
              </div>
            ) : (
              <template></template>
            )
          ]
        }
      })
    }

    const usercpf: <T>(
      options: UsercpfOptions<T>
    ) => VueTsxSupport.JSX.Element = (options) => {
      const getRules =
        options.getRules || ((defaultRules) => Object.values(defaultRules))

      return custom({
        ...options,
        formModelItemTsxData: merge<
          FormModelItemTsxDataType,
          FormModelItemTsxDataType
        >(
          {
            props: {
              rules: getRules({
                required: GlobalManager.Rules.usercpf(undefined, {
                  required: true
                }),
                pattern: GlobalManager.Rules.usercpf()
              })
            }
          },
          options.formModelItemTsxData as FormModelItemTsxDataType
        ),
        render: () => {
          return [
            options.showLabel ? (
              <template slot="label">
                <div>
                  <Icon.Tsx
                    v-show={!options.hiddenIcon}
                    component={{
                      functional: true,
                      render() {
                        return (
                          <icon-sprite
                            sprite={createSvgSprite('icon_wd_cpf')}
                          />
                        )
                      }
                    }}
                  />
                  <span>
                    {options.label ||
                      (t('lobby.center.security.cpf.label') as string)}
                  </span>
                </div>
              </template>
            ) : null,
            <Input.Tsx
              value={get(options.model, options.prop)}
              onChange={
                options?.onChange ||
                FuncRender.createOnInputChange(
                  options.model as Record<string, unknown>,
                  options.prop,
                  options.beforeChange
                )
              }
              allowClear
              placeholder={
                options.isRequired
                  ? (t('lobby.common.formRules.usercpf') as string)
                  : 'CPF'
              }
              {...merge(
                {
                  attrs: {
                    autocomplete: 'off',
                    maxLength: 11
                  }
                },
                options.inputTsxData as unknown
              )}
            >
              {!options.hiddenPrefix && (
                <div slot="prefix">
                  <Icon.Tsx
                    v-show={!options.hiddenIcon}
                    component={{
                      functional: true,
                      render() {
                        return (
                          <icon-sprite
                            sprite={createSvgSprite('icon_wd_cpf')}
                          />
                        )
                      }
                    }}
                  />
                  {options.isRequired && !options.showLabel && (
                    <span class={style.formRequired}>*</span>
                  )}
                </div>
              )}
            </Input.Tsx>
          ]
        }
      })
    }

    const userPhoneSelect: <T>(
      options: UserPhoneSelectOptions<T>
    ) => VueTsxSupport.JSX.Element = (options) => {
      const { smsCountry } = useMainStore()
      const getRules =
        options.getRules || ((defaultRules) => Object.values(defaultRules))

      const maxLength = smsCountry?.phoneLengthScope
        ? Math.max(...smsCountry.phoneLengthScope.split(',').map(Number))
        : null
      return custom({
        prop: options.prop,
        model: options.model,
        formModelItemTsxData: merge<
          FormModelItemTsxDataType,
          FormModelItemTsxDataType
        >(
          {
            props: {
              rules: getRules({
                required: GlobalManager.Rules.required({
                  requiredItem: () => {
                    return t('lobby.modal.login.form.phone.label')
                  }
                }),
                pattern: GlobalManager.Rules.create({
                  validator: (_rule, value, callback) => {
                    set(
                      options.model as unknown as object,
                      options.prop,
                      numberOnlyFormatter(value, {
                        maxLength: maxLength || value.length
                      })
                    )
                    callback()
                    return true
                  }
                })
              })
            },
            class: [style['phone-select-input']]
          },
          options.formModelItemTsxData as FormModelItemTsxDataType
        ),
        render: () => {
          return [
            options.showLabel ? (
              <template slot="label">
                <div>
                  <Icon.Tsx
                    v-show={!options.hiddenIcon}
                    component={{
                      functional: true,
                      render() {
                        return (
                          <icon-sprite
                            sprite={createSvgSprite('input_icon_sj')}
                          />
                        )
                      }
                    }}
                  />
                  <AutoShrinkText
                    minFontSize={'0.14rem'}
                    text={
                      (options.label as string) ||
                      (t('lobby.modal.login.form.phone.label') as string)
                    }
                  />
                </div>
              </template>
            ) : null,
            <Input.Tsx
              class={[style['input-select'], 'func-input__select']}
              value={get(options.model, options.prop)}
              onChange={(event: Event) => {
                /**
                 * 手机区号首位0特殊处理
                 */
                if (get(event, 'target.value')[0] === '0') {
                  GlobalManager.Modal.message({
                    type: 'warning',
                    content: t('lobby.modal.login.form.phone.viError')
                  })
                  return
                }
                set(
                  options.model as Record<string, unknown>,
                  options.prop,
                  get(event, 'target.value')
                )
              }}
              allowClear
              placeholder={
                t('lobby.modal.login.form.phone.placeholder') as string
              }
              {...merge(
                {
                  attrs: {
                    autocomplete: 'off'
                  }
                },
                options.inputTsxData as unknown
              )}
            >
              <div slot="prefix" class={style['select-width-icon']}>
                <PhoneSegment
                  inline
                  onChange={options.onSelectChange}
                  selectTsxData={options.selectTsxData}
                />

                {options.isRequired && !options.showLabel && (
                  <span class={[style.formRequired, style.phoneFormRequired]}>
                    *
                  </span>
                )}
              </div>
            </Input.Tsx>
          ]
        }
      })
    }

    const userCurrencySelect: <T>(
      options: UserCurrencySelectOptions<T>
    ) => VueTsxSupport.JSX.Element = (options) => {
      const getRules =
        options.getRules || ((defaultRules) => Object.values(defaultRules))

      const dropdownClassName = [
        style['base-select-dropdown'],
        'base-form-item-currency-select',
        ...(options.selectTsxData?.props?.dropdownClassName || '')?.split(' ')
      ]
      const targetList = options.currencyInfos
      const currencyIconMap = Currency.registerIconMap()
      return custom({
        ...options,
        formModelItemTsxData: merge<
          FormModelItemTsxDataType,
          FormModelItemTsxDataType
        >(
          {
            props: {
              rules: getRules({
                required: GlobalManager.Rules.create({
                  required: true,
                  message: () =>
                    t('lobby.modal.register.selectCountry') as string
                }),
                pattern: GlobalManager.Rules.required()
              })
            }
          },
          options.formModelItemTsxData as FormModelItemTsxDataType
        ),

        render: () => {
          return [
            options.showLabel ? (
              <template slot="label">
                <div class={style.currencyText}>
                  <Icon.Tsx
                    v-show={!options.hiddenIcon && !options.hiddenLabelIcon}
                    component={{
                      functional: true,
                      render() {
                        return (
                          <icon-sprite
                            sprite={createSvgSprite('input_icon_sj')}
                          />
                        )
                      }
                    }}
                  />
                  <AutoShrinkText
                    minFontSize={'0.14rem'}
                    text={
                      (options.label as string) ||
                      (t('lobby.modal.register.accountCoinType') as string)
                    }
                  />
                </div>
              </template>
            ) : null,
            <Select.Tsx
              class={[
                !options.current
                  ? 'currency-select-required'
                  : 'currency-select'
              ]}
              value={options?.current}
              // onSearch={options.onSelectChange}
              onChange={options.onSelectChange}
              // showSearch
              notFoundContent={t('lobby.modal.noData')}
              {...merge(options.selectTsxData as unknown, {
                props: {
                  dropdownClassName: dropdownClassName.join(' ')
                }
              })}
              disabled={options.disabled}
              placeholder={t('lobby.modal.register.selectCountry') as string}
            >
              {targetList.map((currency) => {
                const currencyItem =
                  currencyIconMap[
                    (currency.currencyType === CurrencyEnum.CRYPTO
                      ? currency.currencyDisplay
                      : currency.currencyCode) as unknown as keyof typeof currencyIconMap
                  ]

                return (
                  <Select.Option.Tsx
                    value={currency?.currencyCode}
                    key={currency?.currencyCode}
                  >
                    <div class={[style.selectOption]}>
                      <div>
                        <Icon.Tsx
                          class={[style.optionIcon]}
                          component={{
                            functional: true,
                            render() {
                              const icon = currencyItem?.icon
                              const scale = (isWeb ? 28 : 36) / icon?.width
                              return <icon-sprite scale={scale} sprite={icon} />
                            }
                          }}
                        />
                        {currency.currencyType === CurrencyEnum.CRYPTO ? (
                          <span>
                            {language === 'zh_CN' && [
                              currency.marketCurrencyCode.includes('BTC') &&
                                t('lobby.modal.currency.BTC'),
                              currency.marketCurrencyCode.includes('ETH') &&
                                t('lobby.modal.currency.ETH'),
                              currency.marketCurrencyCode.includes('TRX') &&
                                t('lobby.modal.currency.TRX')
                            ]}

                            {currency.currencyDisplay}
                            {`（${currencyItem?.title}）`}
                          </span>
                        ) : (
                          <span>
                            {currencyItem?.title}
                            {`（${currency?.currencyCode}）`}
                          </span>
                        )}
                      </div>
                    </div>
                  </Select.Option.Tsx>
                )
              })}
            </Select.Tsx>
          ]
        }
      })
    }

    return {
      /**
       * 自定义通过vnode传递表单元素的场景
       */
      custom,

      /**
       * 基本的下拉框渲染
       */
      baseSelect,

      //=================================================== 带业务

      /**
       * 会员账号
       */
      username,
      /**
       * 会员真实姓名
       */
      userRealName,
      /**
       * 会员cpf
       */
      usercpf,
      /**
       * 登录密码
       */
      userpass,
      /** 登录密码，带编辑按钮 */
      userpassCustomSuffix,
      /**
       * 邮件格式
       */
      useremail,
      /**
       * 下拉选择区号输入手机号
       */
      userPhoneSelect,
      /**
       * 下拉选择货币
       */
      userCurrencySelect
    }
  }
}
